import Modal from 'react-modal';

import styles from './style.module.scss';
import closeModalIcon from '../../../assets/closeModal.svg';
import WhiteButton from '../../buttons/whiteButton';

export interface ConfirmModalProps {
  open: boolean,
  message?: string,
  onConfirm?: () => void,
  onClose?: () => void,
  button?: string,
}

const ConfirmModal = ({ open, onClose, message, button, onConfirm }: ConfirmModalProps) => {

  const handleClick = () => {
    onClose!();
    onConfirm!();
  }

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={open}
      onRequestClose={onClose!}
    >
      <div className={styles.container}>
        <span onClick={onClose} className={styles.closeModalContainer}>
          <img src={closeModalIcon} alt={'close modal'} className={styles.closeModal}/>
        </span>
        <div className={styles.content}>
          <p className={styles.message}>{message}</p>
          <WhiteButton text={button!} onClick={handleClick}/>
        </div>
      </div>
    </Modal>
  )
};

export default ConfirmModal;
