import styles from './style.module.scss';
import CenteredContainer from '../../../components/containers/centeredContainer';
import IconedTitle from '../../../components/utils/iconedTitle';
import icon from '../../../assets/icon.svg';
import ContactLine from '../../../components/othrs/contactLine';
import List from '../../../components/list';
import { useEffect, useMemo, useState } from 'react';
import { CityGroup, Medium, Recommendation, RecommendationStatus, Sphere, User } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchMyContacts } from '../../../store/slices/users';
import Sort from '../../../components/options/sort';
import Search from '../../../components/options/search';
import Filter from '../../../components/options/filter';
import api from '../../../services/api';
import moment from 'moment/moment';

const sortOptions = [
  { label: 'A à Z', value: 'name' },
  { label: 'Date', value: 'date' },
  { label: 'Business échangé', value: 'business' },
  { label: 'Recos envoyées', value: 'givenRecos' },
  { label: 'Recos reçues', value: 'receivedRecos' },

];

const Contacts = () => {
  const me = useAppSelector(state => state.account.user);
  const [ users, setUsers ] = useState<User[] | null>(null);
  const [ filteredUsers, setFilteredUsers ] = useState<User[] | null>(null);
  const [ cityGroupIds, setCityGroupIds ] = useState<string[]>([]);
  const [ sphereIds, setSphereIds ] = useState<string[]>([]);

  const [ cityGroups, setCityGroups ] = useState<CityGroup[] | null>(null);
  const [ spheres, setSpheres ] = useState<Sphere[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ sort, setSort ] = useState<string>('start_date');
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      const result = await dispatch(fetchMyContacts()).unwrap();
      setUsers(result);
      const { data: cityGroups } = await api.misc.getCityGroups();
      setCityGroups(cityGroups);
      const { data: spheres } = await api.misc.getSpheres();
      setSpheres(spheres);
    } catch (err) {
      console.error('Error fetching events home', err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const getBusiness = (user: User) => {
    if (me && user.givenRecommendations && user.receivedRecommendations) {
      return [ ...user.givenRecommendations, ...user.receivedRecommendations ].reduce<number>((acc, reco: Recommendation) => {
        if (reco.status === RecommendationStatus.CONTRACT_SIGNED && (reco.user_author_id === me.id || reco.recommended_user_id === me.id)) {
          return acc + (reco.contract_amount || 0);
        } else {
          return acc;
        }
      }, 0);
    }
    return 0;
  };

  const contacts = useMemo(() => {
    if (!users) return null;
    let sortFunction = (a: User, b: User) => a.lastname.localeCompare(b.lastname);
    if (sort === 'date') {
      sortFunction = (a: User, b: User) => moment(b.metOn).diff(a.metOn);
    } else if (sort === 'business') {
      sortFunction = (a: User, b: User) => (getBusiness(b) - getBusiness(a));
    } else if (sort === 'receivedRecos') {
      sortFunction = (a: User, b: User) => (b.receivedRecommendations?.length || 0) - (a.receivedRecommendations?.length || 0);
    } else if (sort === 'givenRecos') {
      sortFunction = (a: User, b: User) => (b.givenRecommendations?.length || 0) - (a.givenRecommendations?.length || 0);
    }
    return users
      .filter(u => (
        u.firstname.toLowerCase().includes(search.toLowerCase()) ||
        u.lastname.toLowerCase().includes(search.toLowerCase()) ||
        u.job.toLowerCase().includes(search.toLowerCase()))
      )
      .filter(u => (!sphereIds.length || sphereIds.includes(u.sphere_id.toString())))
      .filter(u => (!cityGroupIds.length || cityGroupIds.includes(u.city_group_id.toString())))
      .sort(sortFunction);
  }, [ users, sort, sphereIds, cityGroupIds, search ]);

  return (
    <CenteredContainer>
      <div className={styles.header}>
        <IconedTitle title={`${users?.length || 0} contacts`} icon={icon}/>
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.leftFilters}>
          <Sort options={sortOptions} onChange={setSort}/>
          {!!spheres && <Filter name={'Sphère'} options={spheres.map(sphere => ({ label: sphere.name, value: sphere.id.toString() }))} onChange={setSphereIds}/>}
        </div>
        <Search onChange={setSearch} />
      </div>
      {!!cityGroups && <div className={styles.localization}>
        <Filter name={'Localisation'} options={cityGroups.map(cg => ({ label: cg.name, value: cg.id.toString() }))} onChange={setCityGroupIds}/>
      </div>}
      <List>
        {!!contacts && contacts.map((u) => (
          <ContactLine key={u.id} user={u}/>
        ))}
      </List>
    </CenteredContainer>
  );
};

export default Contacts;
