import styles from './style.module.scss';
import React, { useMemo } from 'react';
import { dataType, Recommendation, RecommendationStatus, User } from '../../../types';
import moment from '../../../utils/moment';
import recommendationSquareIcon from '../../../assets/recommendationsSquareIcon.svg';
import arrowRightBlack from '../../../assets/arrowRightBlack.svg';
import { Link } from 'react-router-dom';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import Line from '../../list/line';
import WhiteIconButton from '../../buttons/whiteIconButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { prettyAmount } from '../../../utils/misc';

type Props = {
  user: User;
}
const ContactLine = ({ user }: Props) => {
  const me = useAppSelector(state => state.account.user);
  const { setData, setType } = useDetailsModalContext();

  const showUser = () => {
    setType(dataType.Othr);
    setData(user);
  };

  const businessAmount = useMemo<number>(() => {
    if (me && user.givenRecommendations && user.receivedRecommendations) {
      return [ ...user.givenRecommendations, ...user.receivedRecommendations ].reduce<number>((acc, reco: Recommendation) => {
        if (reco.status === RecommendationStatus.CONTRACT_SIGNED && (reco.user_author_id === me.id || reco.recommended_user_id === me.id)) {
          return acc + (reco.contract_amount || 0);
        } else {
          return acc;
        }
      }, 0);
    }
    return 0;
  }, [ user.id ]);

  return (
    <Line>
      <div className={styles.container}>
        <img src={user.picture_url} className={styles.image} onClick={showUser}/>
        <div className={styles.infosContainer} onClick={showUser}>
          <span className={styles.name}>{user.firstname} {user.lastname}</span>
          <span className={styles.job}>{user.job}</span>
          <span className={styles.metOn}>En contact depuis {moment.duration(moment().diff(user.metOn)).humanize()}</span>
          <img alt={'show user'} src={arrowRightBlack} className={styles.arrowRight}/>
        </div>
        <div className={styles.recosContainer}>
          <div className={styles.recoColumn}>
            <span className={styles.recosTitle}>Recos données</span>
            <span className={styles.recosNumber}>{user.givenRecommendations?.length || 0}</span>
          </div>
          <div className={styles.recoColumn}>
            <span className={styles.recosTitle}>Recos reçues</span>
            <span className={styles.recosNumber}>{user.receivedRecommendations?.length || 0}</span>
          </div>
          <div className={styles.recoColumn}>
            <span className={styles.recosTitle}>Business HT échangé</span>
            <span className={styles.recosNumber}>{prettyAmount(businessAmount)}</span>
          </div>
        </div>

        <Link to={`/recommendations/create?user=${user.id}`} className={styles.recommendationIconContainer}>
          <WhiteIconButton icon={recommendationSquareIcon} iconHeight={'2.8rem'}/>
        </Link>
      </div>
    </Line>
  );
}

export default ContactLine;
