import styles from './style.module.scss';

interface Props {
  value: number;
  name: string;
};

function NetworkStat({ value, name }: Props) {

  return (
    <div className={styles.container}>
      <span className={styles.value}>{value}</span>
      <span className={styles.text}>{name}</span>
    </div>
  )
};

export default NetworkStat;
