import styles from './style.module.scss';
import Carousel from '../../components/home/carousel';
import { dataType } from '../../types';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { getHome } from '../../store/slices/misc';
import EventBanner from '../../components/events/eventBanner';
import moment from 'moment';
import { parseMedium } from '../../utils/misc';

const Home = () => {
  const dispatch = useAppDispatch();
  const [ homeData, setHomeData ] = useState<any[] | null>(null);

  const fetchData = async () => {
    try {
      const result = await dispatch(getHome()).unwrap();
      setHomeData(result);
    } catch (err) {
      console.error('Error fetching home data', err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const groups = useMemo(() => {
    if (!homeData) return null;
    return homeData.filter((r) => r.type === dataType.Network);
  }, [ homeData ]);

  const events = useMemo(() => {
    if (!homeData) return null;
    return homeData.filter((r) => r.type === dataType.Event);
  }, [ homeData ]);

  const users = useMemo(() => {
    if (!homeData) return null;
    return homeData.filter((r) => r.type === dataType.Othr);
  }, [ homeData ]);

  const media = useMemo(() => {
    if (!homeData) return null;
    return homeData.filter((r) => [ dataType.Article, dataType.Video, dataType.Podcast ].includes(r.type)).map(parseMedium);
  }, [ homeData ]);

  const firstEvent = useMemo(() => {
    if (!events || events.length === 0) return null;
    return events.sort((a, b) => moment(a.start_date).diff(b.start_date))[0];
  }, [ events ]);

  return (
    <div className={styles.container}>
      {firstEvent && <div className={styles.centeredWrapper}>
        <EventBanner event={firstEvent} />
      </div>}
      {!!groups && <Carousel cta={'Voir tous les Groupements partenaires'} path={'/groups'} title={'Groupements partenaires'} type={dataType.Network} data={groups}/>}
      {!!events && <Carousel cta={'Voir tous les Events'} path={'/events'} title={'Events'} type={dataType.Event} data={events}/>}
      {!!users && <Carousel cta={'Voir tous mes contacts potentiels'} path={'/othrs'} title={'Je peux les rencontrer'} type={dataType.Othr} data={users}/>}
      {!!media && <Carousel cta={'Voir tous les Médias'} path={'/media'} title={'Médias'} type={dataType.Medium} data={media}/>}
    </div>
  );
};

export default Home;
