import styles from './style.module.scss';
import { RegisterOptions, FieldErrors, Controller } from 'react-hook-form';
import ErrorMessage from '../errorMessage';
import Select, { SingleValue, createFilter } from 'react-select';
import { User } from '../../../types';
import LogoSquare from '../../utils/logoSquare';
import classNames from 'classnames';

interface Option {
  value: User,
  label: string,
}

type Props = {
  options: RegisterOptions;
  name: string;
  className?: string;
  multiline?: boolean;
  errors?: FieldErrors | undefined;
  control: any;
  placeholder?: string;
  type?: string;
  usersOptions: User[];
}

const OthrLine = ({ user }: { user: User }) => (
  <div className={styles.othrLine}>
    <LogoSquare image={user.picture_url} />
    <span className={styles.othrName}>{user.firstname} <span className={styles.bold}>{user.lastname}</span></span>
  </div>
);

const OthrInput = ({ control, usersOptions, options, name, className, errors, ...rest }: Props & React.HTMLAttributes<HTMLInputElement>) => {
  const selectOptions: Option[] = usersOptions.map((user) => ({
    label: user.id.toString(),
    value: user,
  }))

  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          console.log('field', field);
          return (
            <Select
              isClearable={true}
              isSearchable={false}
              onChange={(newValue: SingleValue<Option>) => {
                field.onChange(newValue?.value || null);
              }}
              onBlur={field.onBlur}
              value={field.value ? {
                value: field.value as User,
                label: (field.value as User).id.toString(),
              } as Option : null}
              formatOptionLabel={(data) => <OthrLine user={data.value}/>}
              options={selectOptions}
              unstyled={true}
              placeholder={rest.placeholder}
              classNames={{
                control: () => styles.selectControl,
                valueContainer: () => classNames(styles.selectValueContainer, field.value && styles.selectValueContainerFilled),
                indicatorSeparator: () => styles.selectIndicatorSeparator,
                indicatorsContainer: () => styles.selectIndicatorsContainer,
                placeholder: () => styles.selectPlaceholder,
                menu: () => styles.selectMenu,
                option: () => styles.selectOption,
              }}
            />)
        }}
      />
      {options.required && <div className={styles.required} />}
      <ErrorMessage errors={errors} name={name}/>
    </div>
  );
};

export default OthrInput;
