import styles from './style.module.scss';
import classNames from 'classnames';
import { RegisterOptions, FieldErrors } from 'react-hook-form';
import ErrorMessage from '../errorMessage';

type Props = {
  register: Function;
  options: RegisterOptions;
  name: string;
  placeholder?: string;
  className?: string;
  multiline?: boolean;
  errors?: FieldErrors | undefined;
  type?: string;
}

const TextInput = ({ register, options, name, className, errors, multiline = false, ...rest }: Props & React.HTMLAttributes<HTMLInputElement>) => {
  if (multiline) {
    return (
      <div className={styles.container}>
        <textarea className={classNames(styles.input, styles.multiline, className)} {...register(name, options)} {...rest}/>
        {options.required && <div className={styles.required} />}
        <ErrorMessage errors={errors} name={name}/>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <input className={classNames(styles.input, className)} {...register(name, options)} {...rest} />
      {options.required && <div className={styles.required} />}
      <ErrorMessage errors={errors} name={name}/>
    </div>
  );
};

export default TextInput;
