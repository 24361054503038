import styles from './style.module.scss';
import LogoSquare from '../../utils/logoSquare';
import { User } from '../../../types';

const UserHeader = ({ user }: { user: User }) => (
  user ? <div className={styles.header}>
    <LogoSquare image={user.picture_url}/>
    <div className={styles.infosContainer}>
      <span className={styles.name}>{user.firstname} {user.lastname}</span>
      <span className={styles.job}>{user.job}</span>
    </div>
  </div> : null

)

export default UserHeader;
