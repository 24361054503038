import styles from './style.module.scss';
import React, { MouseEventHandler, useState } from 'react';
import classNames from 'classnames';

type Props = {
  tabs: string[];
  currentTab: string;
  onTabChange: (tab: string) => void;
}
const TabBar = ({ tabs, currentTab, onTabChange }: Props) => {
  return (
    <div className={styles.container}>
      {tabs.map((tab) => (
        <div key={tab} className={classNames(styles.tab, { [styles.active]: currentTab === tab })} onClick={() => onTabChange(tab)}>
          <span className={classNames(styles.tabName, { [styles.active]: currentTab === tab })}>{tab}</span>
        </div>
      ))}
    </div>
  );
}

export default TabBar;
