import styles from '../../../routes/events/create/style.module.scss';
import IconedTitle from '../iconedTitle';
import editEvent from '../../../assets/editEvent.svg';
import OthrSquare from '../othrSquare';
import LogoSquare from '../logoSquare';
import { User } from '../../../types';
import { useState } from 'react';

interface Props {
  user: User,
  options: string[],
  setCreateAs: (createAs: string) => void,
}
const CreateAs = ({ user, options, setCreateAs }: Props) => {

  return (
    <div className={styles.createAsContainer}>
      <IconedTitle title={'Créer en tant que :'} icon={editEvent}/>
      <div className={styles.spacer}/>
      {options && options.map((option) => (
        <div className={styles.optionContainer} onClick={() => setCreateAs(option)}>
          {(option.includes('city_group') || option.includes('zone')) ? <OthrSquare/> : <LogoSquare image={option === 'me' ? user.picture_url : user.administratedNetworks!.find(n => n.id.toString() === option.split('-')[1])?.logo_url || ''}/>}
          <div className={styles.optionInfosContainer}>
            <span className={styles.optionName}>{(option.includes('city_group') || option.includes('zone')) ? `Othr ${user.administratedCityGroups?.find(cg => cg.id.toString() === option.split('-')[1])?.name || ''}` : (option === 'me' ? `${user.firstname} ${user.lastname}` : user.administratedNetworks!.find(n => n.id.toString() === option.split('-')[1])?.name)}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CreateAs;
