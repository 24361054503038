import styles from './style.module.scss';
import CenteredContainer from '../../../components/containers/centeredContainer';
import IconedTitle from '../../../components/utils/iconedTitle';
import editEvent from '../../../assets/editEvent.svg';
import { Event } from '../../../types';
import EventLine from '../../../components/events/eventLine';
import List from '../../../components/list';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { fetchMyEvents } from '../../../store/slices/events';
import Search from '../../../components/options/search';
import Sort from '../../../components/options/sort';
import moment from 'moment';

const sortOptions = [
  { label: 'Les plus récents', value: 'start_date' },
  { label: 'Les plus de participants', value: 'user_tickets' },
  { label: 'Les plus remplis', value: 'proportion' },
];

const MyEvents = () => {
  const [ events, setEvents ] = useState<Event[] | null>(null);
  const [ filteredEvents, setFilteredEvents ] = useState<Event[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ sort, setSort ] = useState<string>('start_date');
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      const result = await dispatch(fetchMyEvents()).unwrap();
      setEvents(result);
    } catch (err) {
      console.error('Error fetching events home', err);
    }
  }

  useEffect(() => {
    if (!events) return;
    let newEvents = [ ...events ].filter(e => e.title.toLowerCase().includes(search.toLowerCase()));
    let sortFunction = (a: Event, b: Event) => moment(b.start_date).diff(a.start_date);
    if (sort === 'user_tickets') {
      sortFunction = (a: Event, b: Event) => (b.user_tickets?.length || 0) - (a.user_tickets?.length || 0);
    } else if (sort === 'proportion') {
      sortFunction = (a: Event, b: Event) => ((b.user_tickets?.length || 0) / b.total_places) - ((a.user_tickets?.length || 0) / a.total_places);
    }
    console.log('newEvents', newEvents);
    newEvents = newEvents.sort(sortFunction);
    setFilteredEvents(newEvents);
  }, [ events, search, sort ]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CenteredContainer>
      <div className={styles.header}>
        <IconedTitle title={'Mes events'} icon={editEvent} />
      </div>
      {!!filteredEvents && <List>
        <div className={styles.filtersContainer}>
          <Sort options={sortOptions} onChange={setSort}/>
          <Search onChange={setSearch} />
        </div>
        {filteredEvents.map((event: Event) => (
          <EventLine key={event.id} event={event} />
        ))}
      </List>}
    </CenteredContainer>
  );
};

export default MyEvents;
