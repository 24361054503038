import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import { SwapRequest } from '../../types';
import { OthrClassesOptions } from '../../services/api/components/users';

export interface UsersState {
  swapRequestUpdating: boolean,
  swapRequests: SwapRequest[],
  classesLoading: boolean,
};

const initialState: UsersState = {
  swapRequestUpdating: false,
  swapRequests: [],
  classesLoading: false,
};

export const fetchUser = createAsyncThunk('users/fetchUser',
  async (id: string) => {
    const data = await api.users.get(id);
    return (data.data);
  },
);

export const fetchUserPublic = createAsyncThunk('users/fetchUserPublic',
  async (id: string) => {
    const data = await api.users.getPublic(id);
    return (data.data);
  },
);

export const fetchUserClasses = createAsyncThunk('users/fetchUserClasses',
  async (options: OthrClassesOptions) => {
    const data = await api.users.getClasses(options);
    return (data.data);
  },
);

export const fetchMyContacts = createAsyncThunk('users/fetchContacts',
  async () => {
    const data = await api.users.getContacts();
    return (data.data);
  },
);

export const fetchSwapRequests = createAsyncThunk('users/fetchSwapRequests',
  async () => {
    const data = await api.users.getSwapRequests();
    return (data.data);
  },
);

export const acceptSwapRequest = createAsyncThunk('users/acceptSwapRequest',
  async (swapRequestId: string) => {
    const data = await api.users.acceptSwapRequest(swapRequestId);
    return (data.data);
  },
);

export const refuseSwapRequest = createAsyncThunk('users/refuseSwapRequest',
  async (swapRequestId: string) => {
    const data = await api.users.acceptSwapRequest(swapRequestId);
    return (data.data);
  },
);

export const sendSwapRequest = createAsyncThunk('users/sendSwapRequest',
  async (data: { description: string, requestedUserId: string } ) => {
    const result = await api.users.swapRequest(data.description, data.requestedUserId);
    return (result.data);
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSwapRequests.fulfilled, (state, action) => {
      state.swapRequests = action.payload;
    })
    builder.addCase(acceptSwapRequest.pending, (state) => {
      state.swapRequestUpdating = true;
    })
    builder.addCase(refuseSwapRequest.pending, (state) => {
      state.swapRequestUpdating = true;
    })
    builder.addCase(sendSwapRequest.pending, (state) => {
      state.swapRequestUpdating = true;
    })
    builder.addCase(acceptSwapRequest.fulfilled, (state) => {
      state.swapRequestUpdating = false;
    })
    builder.addCase(refuseSwapRequest.fulfilled, (state) => {
      state.swapRequestUpdating = false;
    })
    builder.addCase(sendSwapRequest.fulfilled, (state) => {
      state.swapRequestUpdating = false;
    })
    builder.addCase(acceptSwapRequest.rejected, (state) => {
      state.swapRequestUpdating = false;
    })
    builder.addCase(refuseSwapRequest.rejected, (state) => {
      state.swapRequestUpdating = false;
    })
    builder.addCase(sendSwapRequest.rejected, (state) => {
      state.swapRequestUpdating = false;
    })
    builder.addCase(fetchUserClasses.pending, (state) => {
      state.classesLoading = true;
    })
    builder.addCase(fetchUserClasses.fulfilled, (state) => {
      state.classesLoading = false;
    })
    builder.addCase(fetchUserClasses.rejected, (state) => {
      state.classesLoading = false;
    })
  }
})

export const {
} = usersSlice.actions;

export default usersSlice.reducer;
