import styles from './style.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import ColoredButton from '../../../components/buttons/coloredButton';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import TextInput from '../../../components/form/textInput';
import UnscrollableCenteredContainer from '../../../components/containers/unscrollableCenterModal';
import icon from '../../../assets/icon.svg';
import IconedTitle from '../../../components/utils/iconedTitle';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { inviteNetwork } from '../../../store/slices/networks';

type Inputs = {
  name: string;
  email: string;
  message: string;
}

const InviteNetwork = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.networks.invitePending)
  const { setText } = useValidationModalContext();
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log('name', data.name);
    console.log('email', data.email);
    console.log('message', data.message);
    try {
      const result = await dispatch(inviteNetwork(data));
      console.log('result', result);
      setText('Votre message a bien été envoyé');
    } catch (err) {
      console.error('Error inviting network', err);
    }
  }

  return (
    <UnscrollableCenteredContainer>
      <div className={styles.header}>
        <IconedTitle title={'Inviter un groupement à rejoindre Othr'} icon={icon}/>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TextInput errors={errors} placeholder={'Nom du groupement'} register={register} name={'name'} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins trois caractères' }}} />
        <TextInput errors={errors} placeholder={'Email du groupement'} register={register} name={'email'} options={{ required: true, pattern: { value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: 'Adresse e-mail invalide' }}} />
        <TextInput errors={errors} className={classNames(styles.input, styles.multiline)} register={register} placeholder={'Message'} name={'message'} options={{ required: true, minLength: { value: 30, message: 'Au moins 30 caractères' } }} multiline={true} />
        <div className={styles.spacer}/>
        <ColoredButton loading={loading} text={'Envoyer mon invitation'} onClick={console.log} type={'submit'}/>
      </form>
    </UnscrollableCenteredContainer>
  );
};

export default InviteNetwork;
