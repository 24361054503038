import { Component } from './index';

export class Auth extends Component {
  setToken(token: string) {
    console.log('set token', token);
    this.requester.defaults.headers.common['authorization'] = token;
  }

  getAuthCode() {
    return this.requester.get('/auth/auth-code');
  }

  async refreshToken() {
    return this.requester.get('/auth/refresh-token');
  }

  async signup(data: any) {
    const response = await this.requester.post('/auth/signup', data);
    if (response?.data?.token) {
      this.requester.defaults.headers.common['authorization'] = response.data.token;
    }
    return response;
  }

  async signin(email: string, password: string) {
    try {
      const response = await this.requester.post('/auth/signin', { email, password });
      if (response?.data?.token) {
        this.requester.defaults.headers.common['authorization'] = response.data.token;
      }
      return { data: response.data };
    } catch (err: any) {
      console.error('Error in API, signin', err);
      return { error: err.response.data };
    }
  }

  forgotPassword(email: string) {
    return this.requester.post('/auth/forgot-password', { email });
  }

  resetPasswordToken(token: string, password: string) {
    return this.requester.patch(`/auth/reset-password/${token}`, { password });
  }

  async googleSignin(data: any) {
    const response = await this.requester.post('/auth/google-signin', data);
    console.log('rawResponse', response);
    if (response?.data?.token) {
      this.requester.defaults.headers.common['authorization'] = response.data.token;
    }
    return response;
  }

  async googleSignup(data: any) {
    try {
      const response = await this.requester.post('/auth/google-signup', data);
      if (response?.data?.token) {
        this.requester.defaults.headers.common['authorization'] = response.data.token;
      }
      return response;
    } catch (err) {
      return err;
    }
  }

  async appleSignin(data: any) {
    try {
      const response = await this.requester.post('/auth/apple-signin', data);
      if (response?.data?.token) {
        this.requester.defaults.headers.common['authorization'] = response.data.token;
      }
      return response;
    } catch (err) {
      return err;
    }
  }

  async appleSignup(data: any) {
    try {
      const response = await this.requester.post('/auth/apple-signup', data);
      if (response?.data?.token) {
        this.requester.defaults.headers.common['authorization'] = response.data.token;
      }
      return response;
    } catch (err) {
      return err;
    }
  }

  async externSignup(data: any) {
    const response = await this.requester.post('/auth/extern-signup', data);
    if (response?.data?.token) {
      this.requester.defaults.headers.common['authorization'] = response.data.token;
    }
    return response;
  }
}
