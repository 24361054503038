import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './style.module.scss';
import icon from '../../assets/icon.svg';
import textIcon from '../../assets/textIcon.svg';
import eventsIcon from '../../assets/eventsIcon.svg';
import recommendationsIcon from '../../assets/recommendationsIcon.svg';
import othrsIcon from '../../assets/othrsIcon.svg';
import mediaIcon from '../../assets/mediaIcon.svg';
import networksIcon from '../../assets/networksIcon.svg';
import eventsIconColor from '../../assets/eventsIconColor.svg';
import recommendationsIconColor from '../../assets/recommendationsIconColor.svg';
import othrsIconColor from '../../assets/othrsIconColor.svg';
import mediaIconColor from '../../assets/mediaIconColor.svg';
import networksIconColor from '../../assets/networkIconColor.svg';
import notificationsIcon from '../../assets/notificationsIcon.svg';
import contactIcon from '../../assets/emailIcon.svg';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchProfile } from '../../store/slices/account';
import { useDetailsModalContext } from '../../contexts/DetailsModalContext';
import { dataType, ParentNetwork, User } from '../../types';
import AccountLine from './accountLine';
import accountIcon from '../../assets/accountWhiteIcon.svg';
import settingsIcon from '../../assets/settingsWhiteIcon.svg';
import subscriptionIcon from '../../assets/subscriptionIcon.svg';
import logoutIcon from '../../assets/logoutIcon.svg';
import api from '../../services/api';
import { adminUrl, appUrl, dashboardUrl } from '../../utils/config';
import { useErrorModalContext } from '../../contexts/ErrorModalContext';
import { logout } from '../../store/slices/auth';
import OthrSquare from '../utils/othrSquare';
import LogoSquare from '../utils/logoSquare';
import ContactUsModal from '../modals/contactUsModal';
import { usePodcastContext } from '../../contexts/PodcastContext';
import podcastIconWhite from '../../assets/podcastWhiteIcon.svg';

const routes = [
  { icon: eventsIcon, colorIcon: eventsIconColor, path: '/events', name: 'Events', children: [
      { name: 'Consulter', path: '/events', },
      { name: 'Créer', path: '/events/create' },
      { name: 'Mes billets', path: '/events/tickets' },
    ],
  },
  { icon: othrsIcon, colorIcon: othrsIconColor, path: '/othrs', name: 'Othrs', children: [
      { name: 'Consulter', path: '/othrs', },
      { name: 'Mes relations', path: '/othrs/contacts' },
      { name: 'Mes swaps', path: '/othrs/swaps' },
    ],
  },
  { icon: recommendationsIcon, colorIcon: recommendationsIconColor, path: '/recommendations', name: 'Recos', children: [
      { name: 'Consulter', path: '/recommendations' },
      { name: 'Nouvelle reco', path: '/recommendations/create' },
    ],
  },
  { icon: networksIcon, colorIcon: networksIconColor, path: '/groups', name: 'Groupements', children: [
      { name: 'Consulter', path: '/groups', },
      { name: 'Inviter', path: '/groups/invite' },
      { name: 'Créer', path: '/groups/create' },
    ],
  },
  { icon: mediaIcon, colorIcon: mediaIconColor, path: '/media', name: 'Medias', children: [
    { name: 'Consulter', path: '/media' },
    { name: 'Publier', path: '/media/create' },
    { name: 'Mes médias', path: '/media/mine' },
    ],
  },
];

const worldAdmins = [ 'clement@othr.pro', 'luc@othr.pro', 'rizki@othr.pro', 'sabrina.sanjulian@othr.pro' ];

const Menu = () => {
  const location = useLocation();
  const { setError } = useErrorModalContext();
  const { setData, setType } = useDetailsModalContext();
  const [ open, setOpen ] = useState<boolean>(false);
  const [ menu, setMenu ] = useState<string | null>(null);
  const user: User | undefined = useAppSelector((state) => state.account.user);
  const [ showAccountModal, setShowAccountModal ] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ contactUs, setContactUs ] = useState(false);
  const { url, name: podcastName, id: podcastId } = usePodcastContext();

  const showProfile = () => {
    if (!user) return;
    setType(dataType.Othr);
    setData(user);
  }

  const showPodcast = () => {
    if (!podcastId) return;
    setType(dataType.Medium);
    setData({ id: podcastId });
  }

  const _logout = () => {
    dispatch(logout());
  };

  const handleLocation = () => {
    const matchingRoute = routes.find(r => location.pathname.startsWith(r.path));
    if (matchingRoute) {
      setMenu(matchingRoute.name);
    } else {
      setMenu(null);
    }
  };

  const showCgu = () => {
    window.open('https://api.othr.pro/static/cgu.pdf', '_blank', 'noreferrer');
  }

  useEffect(handleLocation, [ location ]);

  const navigateToSubscription = async () => {
    try {
      const result = await api.payments.manageBilling(`${appUrl}`);
      window.open(result.data, '_blank', 'noreferrer');
    } catch (err) {
      setError('Impossible d\'accéder à la page demandée');
    }
  }

  const openDashboard = async (id: number) => {
    const { data: { code } } = await api.auth.getAuthCode();
    window.open(`${dashboardUrl}?auth_code=${code}&id=${id}`, '_blank', 'noopener,noreferrer');
  }

  const openAdmin = async (id: number) => {
    try {
      const { data: { code } } = await api.auth.getAuthCode();
      console.log('code, id', code, id);
      window.open(`${adminUrl}?auth_code=${code}&network=${id}`, '_blank', 'noopener,noreferrer');
    } catch (err) {
      console.error('Error opening network manager', err);
    }
  };

  const administratedNetworks = useMemo(() => {
    if (!user) return [];
    else {
      const data: ParentNetwork[] = [];
      [ ...(user.administratedParentNetworks || []), ...(user.ownedParentNetworks || []) ].forEach((parentNetwork) => {
        if (!data.some(({ id }) => (id === parentNetwork.id))) {
          data.push(parentNetwork);
        }
      });
      return data;
    }
  }, [ user?.ownedParentNetworks, user?.administratedParentNetworks ]);

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  return (
    <div className={classNames(styles.container, { [styles.openContainer]: open })}>
      <nav className={styles.nav} onMouseEnter={() => setOpen(true)} onMouseLeave={() => {
        setOpen(false);
        setShowAccountModal(false);
        handleLocation();
      }}>
        <div className={styles.topContainer}>
          <Link to={'/'} className={styles.iconMenu} onClick={() => setMenu(null)}>
            <img src={icon} alt={'icon'} className={styles.homeIcon} />
            {open && <img src={textIcon} alt={'text-icon'} className={styles.textIcon}/>}
          </Link>
          <div className={styles.menuContainer}>
            {routes.map(({ icon, colorIcon, path, name, children }) => (
              <div key={name} className={styles.menu} onClick={() => {
                if (menu !== name) {
                  setMenu(name);
                } else {
                  setMenu(null);
                }
                setShowAccountModal(false);
              }}>
                <div className={styles.menuLine}>
                  <img src={menu === name ? colorIcon : icon} alt={path.slice(1)} className={classNames(styles.icon, styles[`${path.slice(1)}Icon`])} />
                  {open && <span className={classNames(styles.menuName, { [styles.menuNameWhite]: menu === name })}>{name}</span>}
                </div>
                {menu === name && open && <ul className={styles.subMenuList}>
                  {children.filter(({ path }) => (path !== '/events/create' || user?.administratedCityGroups?.length || user?.admin_zone_id || user?.administratedNetworks?.length)).map(({ name, path }) => (
                    <Link key={name} to={path} className={classNames(styles.subMenu, location.pathname === path && styles.subMenuActive)}>{name}</Link>
                  ))}
                </ul>}
              </div>
            ))}
          </div>
        </div>
        {!!user && <div className={styles.bottomContainer} onMouseLeave={() => setShowAccountModal(false)}>
          {!!url && <div className={styles.podcastContainer}>
            <div className={classNames(styles.podcast, open && styles.podcastOpen)} onClick={showPodcast}>
              <img src={podcastIconWhite} className={styles.podcastIcon} alt={'podcast'}/>
              {open && <span className={styles.podcastName}>{podcastName}</span>}
            </div>
          </div>}
          <div className={styles.separator}/>
          <Link to={'/notifications'} className={styles.notificationsIconContainer}>
            <img src={notificationsIcon} className={classNames(styles.icon, styles.notificationsIcon)} alt={'notifications'} />
            {open && <span className={styles.menuName}>Notifications</span>}
          </Link>
          <div className={styles.separator}/>
          {administratedNetworks.length > 0 && administratedNetworks.map((pn: ParentNetwork) => (
            <div key={pn.id} className={styles.profileContainer} onClick={() => openAdmin(pn.id)}>
              <LogoSquare image={pn.logo_url} className={styles.profile} />
              {open && <span className={classNames(styles.menuName, styles.profileText)}>Dashboard</span>}
            </div>
          ))}
          {((user.administratedCityGroups && user.administratedCityGroups.length > 0) || user.admin_zone_id || worldAdmins.includes(user.email)) && <div className={styles.profileContainer} onClick={() => openDashboard(user?.administratedCityGroups?.[0]?.id || user.admin_zone_id || 0)}>
            <OthrSquare className={styles.profile} />
            {open && <span className={classNames(styles.menuName, styles.profileText)}>Admin</span>}
          </div>}
          {!!user.picture_url ? <div className={styles.profileContainer} onMouseEnter={() => setShowAccountModal(true)}>
            <img src={user.picture_url} className={classNames(styles.profile)} alt={'profile'} />
            {open && <span className={classNames(styles.menuName, styles.profileText)}>Bonjour<br/>{user.firstname}</span>}
            {showAccountModal && <div className={styles.accountModal}>
              <AccountLine icon={accountIcon} text={'Mon profil'} iconHeight={'1rem'} onClick={showProfile}/>
              <AccountLine icon={settingsIcon} text={'Paramètres'} iconHeight={'1rem'} onClick={() => navigate('/settings')}/>
              <AccountLine icon={subscriptionIcon} text={'Souscription'} iconHeight={'.9rem'} onClick={navigateToSubscription}/>
              <AccountLine icon={contactIcon} text={'Contact'} iconHeight={'.7rem'} onClick={() => setContactUs(true)}/>
              <AccountLine icon={logoutIcon} text={'Déconnexion'} iconHeight={'.8rem'} onClick={_logout}/>
            </div>}
          </div> : <span className={styles.logout} onClick={_logout}>
            Déconnexion
          </span>}
          <div className={styles.footer}>
            <span className={styles.copyright}>© Othr 2023</span>
            {open && <span className={styles.cgu} onClick={showCgu}>CGU/CGV</span>}
          </div>
        </div>}
      </nav>
      <ContactUsModal open={contactUs} onClose={() => setContactUs(false)}/>
      {open && <div className={styles.shadow}/>}
    </div>
  )
};

export default Menu;
