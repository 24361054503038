import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import { User } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { sendRecommendation } from '../../../store/slices/recommendations';
import recommendationIcon from '../../../assets/recommendationsIconColor.svg';
import recommendationIconWhite from '../../../assets/recommendationsWhiteIcon.svg';
import TextInput from '../../../components/form/textInput';
import SelectInput from '../../../components/form/selectInput';
import IconedTitle from '../../../components/utils/iconedTitle';
import ColoredButton from '../../../components/buttons/coloredButton';
import ValidationModal from '../../../components/modals/validationModal';
import { isValidEmail, isValidPhone } from '../../../utils/misc';
import CenteredContainer from '../../../components/containers/centeredContainer';
import OthrInput from '../../../components/form/othrInput';
import { fetchMyContacts, fetchUser } from '../../../store/slices/users';

type Inputs = {
  recommendedUser: User;
  title: string;
  description: string,
  client_name: string,
  client_phone: string,
  client_email: string,
  expected_action: string,
}

const CreateRecommendation = () => {
  const [ searchParams ] = useSearchParams();
  const loading = useAppSelector(state => state.recommendations.recommendationLoading);
  const dispatch = useAppDispatch();
  const { text, setText, setRedirect } = useValidationModalContext();
  const { setError } = useErrorModalContext();
  const navigate = useNavigate();
  const [ contacts, setContacts ] = useState<User[] | null>(null);
  const { register, control, handleSubmit, formState: { errors }, setValue } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    },
  });

  const setUser = async (userId: string) => {
    const user = await dispatch(fetchUser(userId)).unwrap();
    setValue('recommendedUser', user);
  }

  useEffect(() => {
    if (searchParams.get('user')) {
      setUser(searchParams.get('user')!);
    }
  }, [ searchParams ]);

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      console.log(data);
      await dispatch(sendRecommendation({
        recommended_user_id: data.recommendedUser.id.toString(),
        ...data,
      })).unwrap();
      setRedirect('/recommendations');
      setText('Votre recommandation a bien été envoyée !');
    } catch (err) {
      console.error('Error sending recommendation', err);
      setError(`Erreur d'envoi de votre recommandation ${err}`);
    }
  }

  const _fetchContacts = async () => {
    try {
      const data = await dispatch(fetchMyContacts()).unwrap();
      setContacts(data);
    } catch (error) {
      console.error('Error fetching contacts', error);
    }
  };

  useEffect(() => {
    _fetchContacts();
  }, []);

  if (!contacts) return null;

  return (
    <CenteredContainer>
      <div className={styles.centeredWrapper}>
        <div className={styles.header}>
          <IconedTitle title={'Nouvelle recommandation'} icon={recommendationIcon}/>
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <OthrInput placeholder={'Qui souhaites-tu recommander ?'} errors={errors} options={{ required: true }} name={'recommendedUser'} control={control} usersOptions={contacts}/>
          <TextInput errors={errors} className={classNames(styles.input)} register={register} placeholder={`Titre de la reco`} name={'title'} options={{ required: true, minLength: { value: 5, message: 'Au moins 5 caractères' } }} />
          <TextInput errors={errors} className={classNames(styles.input)} register={register} placeholder={`Nom du client`} name={'client_name'} options={{ required: true, minLength: { value: 5, message: 'Au moins 5 caractères' } }} />
          <SelectInput
            stringOptions={[
              'Vous devez l\'appeler',
              'Vous devez le ou la contacter par e-mail',
              'Il ou Elle va vous appeler',
              'Il ou Elle va vous envoyer un e-mail',
            ]}
            errors={errors}
            className={classNames(styles.input)}
            control={control}
            placeholder={`Action attendue`}
            name={'expected_action'}
            options={{ required: true, minLength: { value: 5, message: 'Au moins 5 caractères' } }}
          />
          <TextInput errors={errors} className={classNames(styles.input)} register={register} placeholder={`Téléphone`} name={'client_phone'} options={{}} />
          <TextInput errors={errors} className={classNames(styles.input)} register={register} placeholder={`Email`} name={'client_email'} options={{}} />
          <TextInput errors={errors} className={classNames(styles.input, styles.multiline)} register={register} placeholder={`Décrire la recommandation`} name={'description'} options={{ required: true, minLength: { value: 5, message: 'Au moins 5 caractères' } }} multiline={true} />
          <ColoredButton loading={loading} icon={recommendationIconWhite} className={styles.button} text={'Envoyer ma recommandation'} onClick={console.log} type={'submit'}/>
        </form>
      </div>
      <ValidationModal/>
    </CenteredContainer>
  )
};

export default CreateRecommendation;
