import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../store/hooks';
import styles from './style.module.scss';
import Background from '../components/background';
import Menu from '../components/menu';
import ValidationModal from '../components/modals/validationModal';
import ErrorModal from '../components/modals/errorModal';
import OthrModal from '../components/modals/othrModal';
import EventModal from '../components/modals/eventModal';
import NetworkModal from '../components/modals/networkModal';
import RecommendationModal from '../components/modals/recommendationModal';
import MediumModal from '../components/modals/mediumModal';

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const jwt = useAppSelector(state => state.auth.jwt);
  const user = useAppSelector(state => state.account.user);

  useEffect(() => {
    if (!!user && !user.picture_url && user.email !== 'sabrina.sanjulian@othr.pro') {
      navigate('/profile', { replace: true });
    }
  }, [ user?.picture_url, location.pathname ]);

  if (!jwt) {
    return <Navigate to={'/login'} replace />;
  } else {
    return <NavbarWrapper />;
  }
};

const NavbarWrapper = () => {
  return (
    <div className={styles.container}>
      <Background>
        <Menu />
        <div className={styles.outletContainer}>
          <Outlet/>
          <ValidationModal />
          <OthrModal />
          <EventModal />
          <NetworkModal />
          <RecommendationModal />
          <MediumModal />
          <ErrorModal />
        </div>
      </Background>
    </div>
  )
};

export default ProtectedRoutes;
