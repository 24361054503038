import styles from './style.module.scss';
import React, { useMemo } from 'react';
import { dataType, Event } from '../../../types';
import arrowRightBlack from '../../../assets/arrowRightBlack.svg';
import editIcon from '../../../assets/editIcon.svg';
import DateSquare from '../../utils/dateSquare';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import Line from '../../list/line';
import LogoSquare from '../../utils/logoSquare';

type Props = {
  event: Event;
}
const EventLine = ({ event }: Props) => {
  const { setData, setType } = useDetailsModalContext();

  const showEvent = () => {
    setData(event);
    setType(dataType.Event)
  }

  const takenPlaces = useMemo(() => {
    if (!event) return 0;
    return event.user_tickets?.length || 0;
  }, [ event ]);
  const { address } = event;
  return (
    <Line>
      <div className={styles.container}>
        <div className={styles.squaresContainer}>
          <DateSquare date={event.start_date} className={styles.dateSquare} />
          <LogoSquare image={event.picture_url} />
        </div>
        <div className={styles.infosContainer}>
          <div className={styles.eventInfos}>
            <span className={styles.name}>{event.title}</span>
            <span className={styles.address}>{address.number} {address.street}, {address.city}</span>
          </div>
          <div className={styles.participantsContainer}>
            <span className={styles.participantsTitle}>Participants</span>
            <span className={styles.participants}>{takenPlaces} / {event.total_places}</span>
          </div>
          <img src={arrowRightBlack} className={styles.arrowRight} onClick={showEvent}/>
        </div>
        <div className={styles.editIconContainer}>
          <div className={styles.editContainer}>
            <img src={editIcon} className={styles.editIcon}/>
          </div>
        </div>
      </div>
    </Line>
  );
}

export default EventLine;
