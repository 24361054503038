import Resizer from 'react-image-file-resizer';
import moment from 'moment';

import { googleApiKey } from './config';
import { Address, Article, Item, Medium } from '../types';

export const capitalize = (text: string) => (text.slice(0, 1).toUpperCase() + text.slice(1));
export const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  ) || 'Adresse mail invalide';
export const isValidPhone = (phone: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(
    phone
  );
export const geocodeAddress = async (address: Address) => {
  try {
    const addressString = `${address.number} ${address.street} ${address.zip_code} ${address.city} ${address.country}`.replace(/\s/g, '+');
    const result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${googleApiKey}`);
    const data = await result.json();
    return data?.results[0]?.geometry.location;
  } catch (err) {
    console.error('Error geocoding address', err);
  }
};
export const hours = (() => {
  const current = moment({ hour: 0, minute: 0 });
  const hours: string[] = [ current.format('HH:mm') ];
  current.add(15, 'minute');
  while (current.hours() > 0 || current.minutes() > 0) {
    hours.push(current.format('HH:mm'));
    current.add(15, 'minute');
  }
  return hours;
})();

export const isValidAddress = (address: any) => {
  const components = address?.address_components;
  if (!components) return true;
  if (!components.some((c: any) => c.types.includes('route'))
    || !components.some((c: any) => c.types.includes('locality'))
    || !components.some((c: any) => c.types.includes('postal_code'))
  ) {
    return 'Adresse invalide. Merci de sélectionner une adresse précise';
  } else {
    return true;
  }
}

export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export const parseMedium = (rawArticle: any) => {
  return {
    ...rawArticle,
    title: rawArticle.items.find((item: Item) => item.type === 'title')?.content,
    introduction: rawArticle.items.find((item: Item) => item.type === 'introduction')?.content,
  } as Medium;
}

export const resizeFile = (file: File, aspectRatio: number) => (
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64",
    );
  })
);

export const fileSize = (size: number) => {
  if (size < 1000) return `${size}B`;
  else if (size >= 1000 && size < 1000000) return `${Math.round(size / 100) / 10}KB`;
  else return `${Math.round(size / 100000) / 10}MB`;
}

export const prettyAmount = (amount: number) => {
  if (amount < 1000) return `${amount}€`;
  else if (amount >= 1000 && amount < 1000000) return `${Math.round(amount / 100) / 10}K€`;
  else return `${Math.round(amount / 100000) / 10}M€`;
}
