import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import { User } from '../../types';

export interface AccountState {
  user?: User,
  editEmailPending: boolean,
  editPasswordPending: boolean,
  contactUsPending: boolean,
  updateProfilePending: boolean,
};

const initialState: AccountState = {
  editEmailPending: false,
  editPasswordPending: false,
  contactUsPending: false,
  updateProfilePending: false,
};

export const fetchProfile = createAsyncThunk('account/fetchProfile',
  async () => {
    const data = await api.profile.get();
    return (data.data);
  },
);

export const updateProfile = createAsyncThunk('account/updateProfile',
  async (data: any) => {
    const result = await api.profile.update(data);
    return result.data;
  },
);

export const editEmail = createAsyncThunk('account/editEmail',
  async (newEmail: string) => {
    const data = await api.profile.editEmail(newEmail);
    return (data.data);
  },
);

export const editPassword = createAsyncThunk('account/editPassword',
  async (newPassword: string) => {
    const data = await api.profile.editPassword(newPassword);
    return (data.data);
  },
);

export const contactUs = createAsyncThunk('account/contactUs',
  async ({ object, message }: { object: string, message: string }) => {
    const data = await api.profile.contactUs(object, message);
    return (data.data);
  },
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchProfile.fulfilled, (state: AccountState, action) => {
      // Add user to the state array
      console.log('payload', action.payload);
      state.user = action.payload;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.updateProfilePending = true;
    });
    builder.addCase(updateProfile.fulfilled, (state) => {
      state.updateProfilePending = false;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.updateProfilePending = false;
    });
    builder.addCase(editEmail.pending, (state) => {
      state.editEmailPending = true;
    });
    builder.addCase(editEmail.fulfilled, (state) => {
      state.editEmailPending = false;
    });
    builder.addCase(editEmail.rejected, (state) => {
      state.editEmailPending = false;
    });
    builder.addCase(editPassword.pending, (state) => {
      state.editPasswordPending = true;
    });
    builder.addCase(editPassword.fulfilled, (state) => {
      state.editPasswordPending = false;
    });
    builder.addCase(editPassword.rejected, (state) => {
      state.editPasswordPending = false;
    });
    builder.addCase(contactUs.pending, (state) => {
      state.contactUsPending = true;
    });
    builder.addCase(contactUs.fulfilled, (state) => {
      state.contactUsPending = false;
    });
    builder.addCase(contactUs.rejected, (state) => {
      state.contactUsPending = false;
    });
  },

})

export const {
} = accountSlice.actions;

export default accountSlice.reducer;
