import { Component } from './index';

export interface OthrClassesOptions {
  cityGroupIds?: string[],
  sphereIds?: string[],
  search?: string,
  classBy?: string,
  sort?: string,
}

export class Users extends Component {
  getAll() {
    return this.requester.get('/users');
  }

  getContacts() {
    return this.requester.get('/users/contacts');
  }

  get(id: string) {
    return this.requester.get(`/users/${id}`);
  }

  getPublic(id: string) {
    return this.requester.get(`/users/${id}/public`);
  }

  getHome() {
    return this.requester.get(`/users/home`);
  }

  getClasses(options: OthrClassesOptions) {
    const params = new URLSearchParams();
    if (options.cityGroupIds?.length) {
      params.append('cityGroupIds', options.cityGroupIds.join(','));
    }
    if (options.sphereIds?.length) {
      params.append('sphereIds', options.sphereIds.join(','));
    }
    if (options.search?.length) {
      params.append('search', options.search);
    }
    params.append('classBy', options.classBy || 'sphere');
    params.append('sort', options.sort || 'createdAt');
    return this.requester.get(`/users/classes?${params.toString()}`);
  }

  connect(id: string, token: string) {
    return this.requester.post(`/users/${id}/connect`, {
      token,
    });
  }

  getSwapRequests() {
    return this.requester.get(`/users/swap-requests`);
  }

  swapRequest(description: string, requestedUserId: string) {
    return this.requester.post(`/users/swap-requests`, {
      description,
      requestedUserId,
    });
  }

  acceptSwapRequest(swapRequestId: string) {
    return this.requester.put(`/users/swap-requests/${swapRequestId}/accept`);
  }

  rejectSwapRequest(swapRequestId: string) {
    return this.requester.put(`/users/swap-requests/${swapRequestId}/reject`);
  }

  removeRelation(userId: string) {
    return this.requester.delete(`/users/relations/${userId}`);
  }

  networkAdhesion(message: string, networkId: string, type: string) {
    return this.requester.post(`/users/network-adhesion`, {
      type,
      message,
      networkId,
    });
  }

  block(userId: string) {
    return this.requester.post(`/users/block/${userId}`);
  }

}
