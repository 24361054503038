import styles from './style.module.scss';
import React, { useMemo } from 'react';
import { Notification } from '../../../types';
import arrowRightBlack from '../../../assets/arrowRightBlack.svg';
import editIcon from '../../../assets/editIconBlack.svg';
import shareIcon from '../../../assets/shareIcon.svg';
import Line from '../../list/line';
import LogoSquare from '../../utils/logoSquare';
import WhiteIconButton from '../../buttons/whiteIconButton';
import { useNavigate } from 'react-router-dom';
import notifications from '../../../routes/notifications';
import OthrSquare from '../../utils/othrSquare';
import othrsIcon from '../../../assets/othrsIconColor.svg';
import networksIcon from '../../../assets/networkIconColor.svg';
import eventsIcon from '../../../assets/eventsIconColor.svg';
import mediaIcon from '../../../assets/mediaIconColor.svg';
import recommendationsIcon from '../../../assets/recommendationsIconColor.svg';
import { parseMedium } from '../../../utils/misc';
import moment from 'moment';

type Props = {
  notification: Notification;
}
type NotificationData = {
  body: string,
  date: Date,
  link: string,
  seen: boolean,
  picture_url: string | null,
  icon: string | null,
  subtitle: string | null,
}
const NotificationLine = ({ notification }: Props) => {
  const navigate = useNavigate();

  const data = useMemo(() => {
    let initialData: NotificationData = {
      body: notification.body,
      date: notification.createdAt,
      link: notification.link,
      seen: notification.seen,
      picture_url: null,
      icon: null,
      subtitle: null,
    };
    if (notification.recommendation_id && !!notification.recommendation && !!notification.othr) {
      initialData.picture_url = notification.othr.picture_url;
      initialData.icon = recommendationsIcon;
      initialData.subtitle = `${notification.othr.firstname} ${notification.othr.lastname}`;
      initialData.link = `/recommendations?id=${notification.link.split('/').slice(-1)[0]}`
    } else if (notification.othr_id && !!notification.othr) {
      initialData.picture_url = notification.othr.picture_url;
      initialData.icon = othrsIcon;
      initialData.subtitle = `${notification.othr.firstname} ${notification.othr.lastname}`;
      const othrId = notification.link.split('/').slice(-1)[0];
      console.log('othrId', othrId, parseInt(othrId), isNaN(parseInt(othrId)));
      if (!isNaN(parseInt(othrId))) {
        initialData.link = `/othrs?id=${othrId}`;
      } else {
        initialData.link = `/othrs/swap-requests`;
      }
    } else if (notification.event_id && !!notification.event) {
      if (!!notification.event.network) {
        initialData.picture_url = notification.event.network.logo_url;
      }
      initialData.icon = eventsIcon;
      initialData.subtitle = notification.event.title;
      initialData.link = `/events?id=${notification.link.split('/').slice(-1)[0]}`
    } else if (notification.network_id && !!notification.network) {
      initialData.picture_url = notification.network.logo_url;
      initialData.icon = networksIcon;
      initialData.subtitle = notification.network.name;
      initialData.link = `/groups?id=${notification.link.split('/').slice(-1)[0]}`
    } else if (notification.medium_id && !!notification.medium) {
      initialData.picture_url = notification.medium.picture_url;
      initialData.icon = mediaIcon;
      initialData.subtitle = parseMedium(notification.medium).title;
      initialData.link = `/media?id=${notification.link.split('/').slice(-1)[0]}`
    }
    return initialData;
  }, [ notification ]);

  const goToTarget = () => {
    navigate(data.link);
  }

  return (
    <Line>
      <div className={styles.container} onClick={goToTarget}>
        {data.picture_url ? <LogoSquare image={data.picture_url} /> : <OthrSquare/>}
        {!!data.icon && <img src={data.icon} className={styles.icon} alt={'notification type'}/>}
        <div className={styles.infosContainer}>
          <span className={styles.name}>{data.body}</span>
          {!!data.subtitle && <span className={styles.subtitle}>{data.subtitle}</span>}
        </div>
        <img src={arrowRightBlack} className={styles.arrowRight}/>
        <span className={styles.date}>{moment(data.date).fromNow()}</span>
      </div>
    </Line>
  );
}

export default NotificationLine;
