import styles from './style.module.scss';
import { RegisterOptions, FieldErrors, Controller, Control } from 'react-hook-form';
import ErrorMessage from '../errorMessage';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  options: RegisterOptions;
  name: string;
  placeholder?: string;
  className?: string;
  multiline?: boolean;
  errors?: FieldErrors | undefined;
  control: any;
  type?: string;
  mode?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DateInput = ({ control, options, name, className, errors, minDate, maxDate, mode = 'date',placeholder = '', ...rest }: Props & React.HTMLAttributes<HTMLInputElement>) => {
  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            value={field.value}
            onBlur={field.onBlur}
            selected={field.value}
            onChange={field.onChange}
            className={styles.datePicker}
            wrapperClassName={styles.wrapper}
            dateFormat={'dd/MM/yyyy'}
            placeholderText={placeholder}
            showTimeSelect={mode === 'time'}
            minDate={minDate || new Date()}
            maxDate={maxDate}
          />
        )}
      />
      {options.required && <div className={styles.required} />}
      <ErrorMessage errors={errors} name={name}/>
    </div>
  );
};

export default DateInput;
