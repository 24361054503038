import React from 'react';
import Spinner from 'react-spinners/PulseLoader';
import styles from './style.module.scss';

const Loader = () => (
  <div className={styles.container}>
    <Spinner
      size={5}
      color={'#ffffff'}
    />
  </div>
)
export default Loader;
