import styles from './style.module.scss';
import React from 'react';
import classNames from 'classnames';

interface Props {
  image: string;
  className?: string;
};

function LogoSquare({ image, className }: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <img src={image} alt={'logo'} className={styles.logo}/>
    </div>
  )
};

export default LogoSquare;
