import ColoredButton from '../../../components/buttons/coloredButton';
import { useAppSelector } from '../../../store/hooks';
import styles from './style.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import TextInput from '../../../components/form/textInput';
import { isValidAddress, isValidEmail, isValidPhone } from '../../../utils/misc';
import fullIcon from '../../../assets/fullIcon.svg';
import googleLogo from '../../../assets/googleLogo.svg';
import { useSearchParams } from 'react-router-dom';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import OutlineButton from '../../../components/buttons/outlineButton';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useEffect, useState } from 'react';
import SelectInput from '../../../components/form/selectInput';
import api from '../../../services/api';
import { Address, Zone } from '../../../types';
import PlaceInput from '../../../components/form/placeInput';
import { adminUrl, appUrl } from '../../../utils/config';

type Inputs = {
  email: string;
  confirm_email: string;
  password: string;
  confirm_password: string;
  firstname: string;
  lastname: string;
  phone: string;
  address: any;
  zone: string;
  coupon: string;
  googleAccessToken: string;
}
enum RegisterStep {
  Email,
  Name,
  Zone,
}
interface Coupon {
  percent_off: number,
  amount_off: number,
  duration: string,
  metadata: {
    groupId?: string,
  },
}

const Signup = () => {
  const loading = useAppSelector((state) => state.auth.loginPending);
  const [ step, setStep ] = useState<RegisterStep>(RegisterStep.Email);
  const [ zones, setZones ] = useState<Zone[] | null>(null);
  const { setError } = useErrorModalContext();
  const [ coupon, setCoupon ] = useState<Coupon | null>(null);
  const [ token, setToken ] = useState<string | null>(null);
  const [ searchParams ] = useSearchParams();
  const [ extern, setExtern ] = useState(false);

  useEffect(() => {
    if (searchParams.get('groups')) {
      setExtern(true);
    }
  }, [ searchParams ]);

  const _fetchZones = async () => {
    try {
      const { data: zones } = await api.misc.getZones();
      setZones(zones);
    } catch (err) {
      console.error('Error fetching zones', err);
    }
  }

  useEffect(() => {
    _fetchZones();
  }, []);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      setToken(token);
      setStep(RegisterStep.Zone);
    }
  }, [ searchParams ]);

  const { register, control, handleSubmit, formState: { errors }, getValues, setValue } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    },
    defaultValues: {
      email: searchParams.get('email') || undefined,
      confirm_email: searchParams.get('email') || undefined,
    },
  });

  const checkCoupon = async (value: string) => {
    try {
      if (!value) return true;
      const result = await api.payments.checkCoupon(value);
      if (result.data?.coupon) {
        setCoupon(result.data.coupon as Coupon);
        return true;
      } else {
        setCoupon(null);
        return false;
      }
    } catch (err) {
      setCoupon(null);
      return 'Coupon invalide';
    }
  }

  const onSubmit: SubmitHandler<Inputs> = async (inputs: Inputs) => {
    try {
      if (step === RegisterStep.Email) {
        setStep(RegisterStep.Name);
      } else if (step === RegisterStep.Name) {
        if (!extern) {
          const address: Address & { placeId: string } = inputs.address.address_components ? {
            id: 0,
            number: inputs.address.address_components.find((c: any) => c.types.includes('street_number'))?.long_name,
            street: inputs.address.address_components.find((c: any) => c.types.includes('route'))?.long_name,
            city: inputs.address.address_components.find((c: any) => c.types.includes('locality'))?.long_name,
            country: inputs.address.address_components.find((c: any) => c.types.includes('country'))?.long_name,
            zip_code: inputs.address.address_components.find((c: any) => c.types.includes('postal_code'))?.long_name,
            placeId: inputs.address.place_id,
          } : inputs.address;
          if (!inputs.googleAccessToken) {
            const result = await api.auth.signup({
              ...inputs,
              address,
            });
            setToken(result.data.token);
          } else {
            const result: any = await api.auth.googleSignup({
              ...inputs,
              address,
              accessToken: inputs.googleAccessToken,
            });
            setToken(result.data.token);
          }
          setStep(RegisterStep.Zone);
        } else {
          const response = await api.auth.externSignup({
            ...inputs,
            groups: searchParams.get('groups')!.split(','),
          });
          if (response.data?.auth_code && response.data?.parentNetworkId) {
            window.location.replace(`${adminUrl}?auth_code=${response.data.auth_code}&network=${response.data.parentNetworkId}`);
          }
        }
      } else {
        if (!token || !zones || !inputs.zone) return;
        const zoneId = zones.find(z => inputs.zone.includes(z.code))!.id;
        const checkout = await api.payments.subscribe(
          token,
          `${appUrl}/login?subscribed=true`,
          `${appUrl}/login?subscribed=false`,
          inputs.coupon,
          zoneId.toString(),
        );
        window.location.replace(checkout.data.url);
      }
    } catch (error: any) {
      console.error(error);
      if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError('Une erreur est survenue');
      }

    }
  };

  const onGoogleSignupSuccess = (tokenResponse: TokenResponse) => {
    setValue('googleAccessToken', tokenResponse.access_token);
    setTimeout(() => setStep(RegisterStep.Name), 500);
  }

  const handleGoogleSignup = useGoogleLogin({
    onSuccess: onGoogleSignupSuccess,
  });


  const EmailForm = () => (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <TextInput errors={errors} placeholder={'Email'} register={register} name={'email'} options={{ required: true, validate: isValidEmail }} />
      <TextInput errors={errors} placeholder={'Confirme ton email'} register={register} name={'confirm_email'} options={{ required: true, validate: (value: string) => (value === getValues('email')) || 'Les emails ne correspondent pas' }} />
      <TextInput errors={errors} type={'password'} placeholder={'Mot de passe'} register={register} name={'password'} options={{ required: { value: true, message: 'Au moins six caractères' }}} />
      <TextInput errors={errors} type={'password'} placeholder={'Confirme ton mot de passe'} register={register} name={'confirm_password'} options={{ required: true, validate: (value: string) => (value === getValues('password')) || 'Les mots de passe ne correspondent pas'}} />
      <ColoredButton loading={loading} text={!extern ? 'Étape suivante (1/3)' : 'Étape suivante (1/2)'} className={styles.button} type={'submit'}/>
      {!extern && <OutlineButton leftIcon={googleLogo} text={'S\'inscrire avec Google'} className={styles.button} onClick={() => handleGoogleSignup()}/>}
    </form>
  );
  const NameForm = () => (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <TextInput errors={errors} placeholder={'Prénom'} register={register} name={'firstname'} options={{ required: true }} />
      <TextInput errors={errors} placeholder={'Nom'} register={register} name={'lastname'} options={{ required: true }} />
      {!extern && <TextInput errors={errors} placeholder={'Numéro de téléphone'} register={register} name={'phone'} options={{ required: true, validate: isValidPhone }} />}
      {!extern && <PlaceInput errors={errors} placeholder={'Siège social'} control={control} name={'address'} options={{ required: true, validate: isValidAddress }} />}
      <ColoredButton loading={loading} text={!extern ? 'Étape suivante (2/3)' : 'Inscription'} className={styles.button} type={'submit'}/>
    </form>
  );
  const ZoneForm = () => (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {!!zones && <SelectInput stringOptions={zones.map(zone => `${zone.code} - ${zone.name}`)} errors={errors} placeholder={'Département'} control={control} name={'zone'} options={{ required: true }} />}
      <TextInput options={{ required: false, validate: checkCoupon }} errors={errors} placeholder={'Coupon de réduction'} register={register} name={'coupon'} />
      <div className={styles.description}>
        <div className={styles.leftDescription}>
          <span className={styles.leftTitle}>Abonnement Othr</span>
          {getValues('zone') && !!zones && <span className={styles.zone}>Départemental (1 an)</span>}
        </div>
        <div className={styles.rightDescription}>
          <span className={styles.price}>{3.6 * (100 - (coupon?.percent_off || 0)) - ((coupon?.amount_off || 0) / 100)}€<span className={styles.ht}>HT</span></span>
          {coupon?.duration === 'once' && <span className={styles.specialOffer}>(la première année, puis {coupon.metadata.groupId ? 180 : 360}€ HT)</span>}
          <span className={styles.renewal}>Renouvellement annuel</span>
        </div>
      </div>
      <p className={styles.paragraph}>Grâce à votre abonnement Othr, vous pourrez rencontrez les entrepreneurs de votre secteur, participer à des évènements, consulter du contenu professionnel, rejoindre un réseau d'affaire, et recommander vos nouveaux contacts.</p>
      <ColoredButton loading={loading} text={'Passer au paiement'} className={styles.button} type={'submit'}/>
      <span className={styles.cgu}>En souscrivant à Othr, vous confirmez avoir lu et accepté les <span className={styles.cguLink}>Conditions Générales de Vente et d'Utilisation</span></span>
    </form>
  )

  return (
    <div className={styles.container}>
      <div className={styles.icons}>
        <img src={fullIcon} alt={'textIcon'} className={styles.textIcon}/>
      </div>
      <h2 className={styles.title}>Crée ton compte</h2>
      {step === RegisterStep.Email ? <EmailForm /> : (step === RegisterStep.Name ? <NameForm /> : <ZoneForm />)}
      {step === RegisterStep.Name && <span className={styles.back} onClick={() => setStep(RegisterStep.Email)}>{'< Étape précédente'}</span>}
      {/*{step === RegisterStep.Email && <span className={styles.login}>Connecte-toi</span>}*/}
    </div>
  );
};

export default Signup;
