import React, { useEffect, useMemo, useState } from 'react';

import DetailsPageModal from '../detailsPageModal';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, Medium, MediumType } from '../../../types';
import { useAppDispatch } from '../../../store/hooks';
import LogoSquare from '../../utils/logoSquare';
import styles from './style.module.scss';
import { fetchMedium } from '../../../store/slices/media';
import Stats from './stats';
import moment from 'moment';
import { parseMedium } from '../../../utils/misc';
import ArticleItem from './articleItem';
import ReactPlayer from 'react-player';
import AudioPlayer from '../../media/audioPlayer';
import WhiteButton from '../../buttons/whiteButton';
import CopyToClipboardButton from '../../buttons/whiteButton/copyToClipboardButton';
import { apiUrl } from '../../../utils/config';
import api from '../../../services/api';
import likeIcon from '../../../assets/likeIcon.svg';
import likeIconOutline from '../../../assets/likeIconOutline.svg';
import LoadingModal from '../loadingModal';
import { usePublicPage } from '../../../utils/hooks';

function MediumModal() {
  const publicPage = usePublicPage();
  const { data, type } = useDetailsModalContext();
  const dispatch = useAppDispatch();
  const [ medium, setMedium ] = useState<Medium | null>(null);
  const [ likeLoading, setLikeLoading ] = useState(false);
  const [ loading, setLoading ] = useState(true);

  const fetchData = async () => {
    console.log('fetchData');
    if (!data || type !== dataType.Medium) return;
    try {
      console.log('publicPage', publicPage);
      const result = publicPage ?
        (await api.media.getMedium(data.id.toString(), true)).data :
        await dispatch(fetchMedium(data.id.toString())).unwrap();
      console.log('medium', result);
      setMedium(parseMedium(result));
      setLoading(false);
    } catch (err) {
      console.error('Error fetching medium', err);
    }
  }

  const likeMedium = async () => {
    setLikeLoading(true);
    await api.media.likeMedium(medium!.id.toString());
    await fetchData();
    setLikeLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [ data?.id ]);

  const author = useMemo(() => {
    if (!medium) return null;
    if (medium.user) {
      return {
        name: `${medium.user.firstname} ${medium.user.lastname}`,
        picture: medium.user.picture_url,
        type: medium.user.job,
      }
    } else if (medium.network) {
      return {
        name: medium.network.name,
        picture: medium.network.picture_url,
        type: medium.network.type?.name,
      }
    } else {
      return null;
    }
  }, [ medium ]);

  if (!data || type !== dataType.Medium) return null;
  if (loading) return <LoadingModal/>;
  if (!medium) return null;

  return (
    <DetailsPageModal>
      {!!author && <div className={styles.header}>
        <LogoSquare image={author.picture} className={styles.headerImage} />
        <div className={styles.headerInfos}>
          <span className={styles.headerName}>{author.name}</span>
          <span className={styles.headerJob}>{author.type}</span>
          <Stats value={medium.views} text={'Audience'} proportion={0.5}/>
          <Stats value={medium.likes} text={'Pertinence'} proportion={0.5}/>
        </div>
      </div>}
      <div className={styles.content}>
        {!!medium.sphere && <span className={styles.sphere}>Sphère {medium.sphere?.name}</span>}
        <h2 className={styles.title}>{medium.title}</h2>
        <span className={styles.dates}>Mis en ligne le {moment(medium.createdAt).format('DD/MM/YYYY')}{medium.updatedAt !== medium.createdAt && <span> | Modifié le {moment(medium.updatedAt).format('DD/MM/YYYY')}</span>}</span>
      </div>
      {!!medium.video_url && (
        <ReactPlayer
          style={{ marginTop: '4rem' }}
          url={medium.video_url}
          controls={true}
          playing={true}
        />
      )}
      {!!medium.audio_url && (
        <AudioPlayer
          id={medium.id}
          name={medium.title}
          url={medium.audio_url}
        />
      )}
      <div className={styles.content}>
        <p className={styles.introduction}>{medium.introduction}</p>
      </div>
      {medium.type.toString() === dataType.Article.toString() && <div>
        {medium.items!.map((item) => (
          <ArticleItem item={item} />
        ))}
      </div>}
      {medium.type === MediumType.podcast && <img src={medium.picture_url} className={styles.image}/>}
      {!publicPage ? <div className={styles.bottomContainer}>
        <WhiteButton icon={medium.liked ? likeIcon : likeIconOutline} iconHeight={'.9rem'} loading={likeLoading} text={'Contenu pertinent'} onClick={likeMedium}/>
        <div className={styles.spacer}/>
        <CopyToClipboardButton text={'Partager'} value={`${apiUrl}/application/public/media?id=${medium.id}`} legendText={'Lien copié !'}/>
      </div> : <div className={styles.publicFooter}/> }
    </DetailsPageModal>
  )
}

export default MediumModal;
