import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

import { Iap } from './components/iap';
import { Auth } from './components/auth';
import { Misc } from './components/misc';
import { Media } from "./components/media";
import { Users } from './components/users';
import { Events } from './components/events';
import { Address } from './components/address';
import { Tickets } from "./components/tickets";
import { Profile } from './components/profile';
import { Networks } from './components/networks';
import { Payments } from './components/payments';
import { ParentNetworks } from './components/parent_networks';
import { Recommendations } from "./components/recommendations";

class Api {
  private defaultConfig: AxiosRequestConfig = {
    baseURL: 'http://localhost:8000',
  };

  requester: AxiosInstance

  iap: Iap;
  auth: Auth;
  misc: Misc;
  users: Users;
  media: Media;
  events: Events;
  tickets: Tickets;
  profile: Profile;
  address: Address;
  payments: Payments;
  networks: Networks;
  parent_networks: ParentNetworks;
  recommendations: Recommendations;

  constructor(config?: AxiosRequestConfig) {
    this.requester = axios.create(config || this.defaultConfig);
    this.iap = new Iap(this.requester);
    this.auth = new Auth(this.requester);
    this.misc = new Misc(this.requester);
    this.users = new Users(this.requester);
    this.media = new Media(this.requester);
    this.events = new Events(this.requester);
    this.tickets = new Tickets(this.requester);
    this.address = new Address(this.requester);
    this.profile = new Profile(this.requester);
    this.payments = new Payments(this.requester);
    this.networks = new Networks(this.requester);
    this.parent_networks = new ParentNetworks(this.requester);
    this.recommendations = new Recommendations(this.requester);
  }
}

export {
  Api,
};
export default new Api({
  baseURL: process.env.REACT_APP_API_URL,
});
