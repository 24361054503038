import styles from './style.module.scss';
import swapIcon from '../../../assets/swapIcon.svg';
import CenteredContainer from '../../../components/containers/centeredContainer';
import IconedTitle from '../../../components/utils/iconedTitle';
import TabBar from '../../../components/utils/tabBar';
import { useEffect, useMemo, useState } from 'react';
import { SwapRequest, SwapRequestStatus } from '../../../types';
import { fetchSwapRequests } from '../../../store/slices/users';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import SwapRequestLine, { SwapRequestMode } from '../../../components/othrs/swapRequestLine';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import Sort from '../../../components/options/sort';
import Search from '../../../components/options/search';
import Filter from '../../../components/options/filter';
import moment from 'moment';

const tabs = [
  'Demandes reçues',
  'Demandes envoyées',
];

const sortOptions = [
  { label: 'Plus récentes', value: 'desc' },
  { label: 'Plus anciennes', value: 'asc' },
];

const SwapRequests = () => {
  const { setError } = useErrorModalContext();
  const [ currentTab, setCurrentTab ] = useState<string>(tabs[0]);
  const isSent = useMemo(() => (currentTab === tabs[1]), [ currentTab ]);
  const [ givenSwapRequests, setGivenSwapRequests ] = useState<SwapRequest[] | null>(null);
  const [ receivedSwapRequests, setReceivedSwapRequests ] = useState<SwapRequest[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ sort, setSort ] = useState<string>('desc');
  const [ statuses, setStatuses ] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const { me, swapRequests } = useAppSelector(state => ({
    me: state.account.user,
    swapRequests: state.users.swapRequests,
  }));

  const fetchData = async () => {
    try {
      await dispatch(fetchSwapRequests()).unwrap();
    } catch (err) {
      console.error('Error fetching swapRequests', err);
      setError('Impossible de récupérer les demandes de Swap');
    }
  }

  useEffect(() => {
    if (!swapRequests?.length) return;
    setGivenSwapRequests(swapRequests.filter((swapRequest) => swapRequest.author?.id === me?.id));
    setReceivedSwapRequests(swapRequests.filter((swapRequest) => swapRequest.requested?.id === me?.id && swapRequest.status === SwapRequestStatus.Pending));
  }, [ swapRequests ]);

  useEffect(() => {
    fetchData();
  }, []);

  const orderedSwapRequests = useMemo(() => {
    if (!givenSwapRequests || !receivedSwapRequests) return null;
    let [ newGivenSwapRequests, newReceivedSwapRequests ] = [ [...givenSwapRequests ], [...receivedSwapRequests] ];
    newGivenSwapRequests = newGivenSwapRequests
      .filter(sr => statuses.length === 0 || statuses.includes(sr.status))
      .filter(sr => sr.requested.firstname.toLowerCase().includes(search.toLowerCase()) ||
        sr.requested.lastname.toLowerCase().includes(search.toLowerCase()) ||
        sr.requested.job.toLowerCase().includes(search.toLowerCase())
      )
      .sort((a, b) => moment(b.createdAt).diff(a.createdAt) * (sort === 'desc' ? 1 : -1));
    newReceivedSwapRequests = newReceivedSwapRequests
      .filter(sr => statuses.length === 0 || statuses.includes(sr.status))
      .filter(sr => sr.author.firstname.toLowerCase().includes(search.toLowerCase()) ||
        sr.author.lastname.toLowerCase().includes(search.toLowerCase()) ||
        sr.author.job.toLowerCase().includes(search.toLowerCase())
      )
      .sort((a, b) => moment(b.createdAt).diff(a.createdAt) * (sort === 'desc' ? 1 : -1));
    return {
      givenSwapRequests: newGivenSwapRequests,
      receivedSwapRequests: newReceivedSwapRequests,
    }
  }, [ givenSwapRequests, receivedSwapRequests, sort, search, statuses ]);

  return (
    <CenteredContainer>
      <div className={styles.header}>
        <IconedTitle title={'Demandes de swap'} icon={swapIcon}/>
      </div>
      <TabBar tabs={tabs} currentTab={currentTab} onTabChange={setCurrentTab} />
      <div className={styles.filtersContainer}>
        <div className={styles.leftFilters}>
          <Filter
            name={'Statut'}
            options={[
              { label: 'En attente', value: SwapRequestStatus.Pending },
              { label: 'Acceptée', value: SwapRequestStatus.Accepted },
            ]}
           onChange={setStatuses}/>
          <Sort options={sortOptions} onChange={setSort}/>
        </div>
        <Search onChange={setSearch} />
      </div>
      {isSent ? (
        !!orderedSwapRequests?.givenSwapRequests && <ul className={styles.recommendationsList}>
        {orderedSwapRequests.givenSwapRequests.map((swapRequest) => (
          <SwapRequestLine mode={SwapRequestMode.GIVEN} key={swapRequest.id} swapRequest={swapRequest} />
        ))}
      </ul>) : (
        !!orderedSwapRequests?.receivedSwapRequests && <ul className={styles.recommendationsList}>
          {orderedSwapRequests.receivedSwapRequests.map((swapRequest) => (
            <SwapRequestLine mode={SwapRequestMode.RECEIVED} key={swapRequest.id} swapRequest={swapRequest} />
          ))}
        </ul>
      )}
    </CenteredContainer>
  );
};

export default SwapRequests;
