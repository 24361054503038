import styles from './style.module.scss';
import { PropsWithChildren } from 'react';

const CenteredContainer = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {children}
      </div>
      <div className={styles.shadow}/>
    </div>
  )
};

export default CenteredContainer;
