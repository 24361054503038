import styles from './style.module.scss';
import searchIcon from '../../../assets/searchIcon.svg';
import { useRef, useState } from 'react';

interface Props {
  onChange: (value: string) => void,
}

const Search = ({ onChange }: Props) => {
  const [ value, setValue ] = useState<string>('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (newValue: string) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current!);
    setValue(newValue);
    timeoutRef.current = setTimeout(() => onChange(newValue), 500) as NodeJS.Timeout;
  }

  return (
    <div className={styles.searchContainer}>
      <img src={searchIcon} className={styles.searchIcon} alt={'search'}/>
      <input type={'text'} className={styles.searchInput} placeholder={'Rechercher'} value={value} onChange={(e) => handleChange(e.target.value)}/>
    </div>
  )
};

export default Search;
