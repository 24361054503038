export const googleApiKey: string = (process.env.REACT_APP_GOOGLE_API_KEY as string);
export const appUrl: string = (process.env.REACT_APP_APP_URL as string);
export const apiUrl: string = (process.env.REACT_APP_API_URL as string);
export const dashboardUrl: string = (process.env.REACT_APP_DASHBOARD_URL as string);
export const adminUrl: string = (process.env.REACT_APP_ADMIN_URL as string);

const config = {
  googleApiKey,
  appUrl,
  apiUrl,
  dashboardUrl,
  adminUrl,
};

export default config;
