import styles from './style.module.scss';

interface Props {
  value: number;
  text: string;
  proportion: number;
};

function Stats({ value, text, proportion }: Props) {

  return (
    <div className={styles.container}>
      <div className={styles.valueContainer} style={{ flex: proportion }}>
        <span className={styles.value}>{value}</span>
      </div>
      <div className={styles.background}>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  )
};

export default Stats;
