import {
  createContext, createRef,
  MutableRefObject,
  PropsWithChildren,
  ReactEventHandler,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { Medium } from '../types';
import styles from '../components/media/audioPlayer/style.module.scss';

interface PodcastContextValue {
  url: string | null,
  setUrl: (url: string | null) => void,
  name: string,
  setName: (newName: string) => void,
  id: number | null,
  setId: (newId: number | null) => void,
  ref: MutableRefObject<HTMLAudioElement | null>,
  onLoadedMetadata: ReactEventHandler<HTMLAudioElement>,
  setOnLoadedMetadata: (f: ReactEventHandler<HTMLAudioElement>) => void,
  onEnd: ReactEventHandler<HTMLAudioElement>,
  setOnEnd: (f: ReactEventHandler<HTMLAudioElement>) => void,
}

const PodcastContext = createContext<PodcastContextValue>({
  url: null,
  setUrl: (newUrl: string | null) => {},
  name: '',
  setName: (newName: string) => {},
  id: null,
  setId: (newId: number | null) => {},
  ref: createRef(),
  onLoadedMetadata: () => {},
  setOnLoadedMetadata: (f: ReactEventHandler<HTMLAudioElement>) => {},
  onEnd: () => {},
  setOnEnd: (f: ReactEventHandler<HTMLAudioElement>) => {},
});

const PodcastContextProvider = ({ children }: PropsWithChildren) => {
  const ref = useRef<HTMLAudioElement | null>(null);
  const [ url, setUrl ] = useState<string | null>(null);
  const [ name, setName ] = useState<string>('');
  const [ id, setId ] = useState<number | null>(null);
  const [ onLoadedMetadata, setOnLoadedMetadata ] = useState<ReactEventHandler<HTMLAudioElement>>(() => {});
  const [ onEnd, setOnEnd ] = useState<ReactEventHandler<HTMLAudioElement>>(() => {});

  const value = useMemo(() => ({
    url,
    setUrl,
    ref,
    onLoadedMetadata,
    setOnLoadedMetadata,
    onEnd,
    setOnEnd,
    name,
    setName,
    id,
    setId,
  }), [ url, ref, name, onEnd, onLoadedMetadata ]);
  return (
    <PodcastContext.Provider value={value}>
      {children}
      {!!url && <audio
        className={styles.audio}
        onLoadedMetadata={onLoadedMetadata}
        ref={ref}
        src={url}
        autoPlay={false}
        onEnded={onEnd}
      />}
    </PodcastContext.Provider>
  );
};

const usePodcastContext = () => {
  return useContext(PodcastContext);
}

export {
  PodcastContextProvider,
  usePodcastContext,
}
