import { Component } from './index';

export class Payments extends Component {
  subscribe(token: string, successUrl: string, cancelUrl: string, coupon: string | undefined, zoneId: string) {
    return this.requester.post('/payments/subscribe', {
      successUrl,
      cancelUrl,
      coupon,
      zoneId,
   });
  }

  manageBilling(returnUrl: string) {
    return this.requester.post('/payments/manage-billing', {
      returnUrl,
    });
  }

  paymentIntentEvent(amount: number, eventId: string, ticketId: string, places: number, owners: string[]) {
    return this.requester.post('/payments/payment-sheet-event', {
      amount,
      eventId,
      ticketId,
      places,
      owners,
    });
  }

  paymentIntentNetwork(networkId: string) {
    return this.requester.post('/payments/payment-sheet-network', {
      networkId,
    });
  }

  buyTicket(owners: any, quantity: number, eventId: string, ticketId: string, successUrl: string, cancelUrl: string) {
    return this.requester.post('/payments/buy-tickets', {
      quantity,
      owners,
      ticketId,
      eventId,
      successUrl,
      cancelUrl,
    });
  }

  guestBuyTicket(owners: any, quantity: number, eventId: string, ticketId: string, successUrl: string, cancelUrl: string) {
    return this.requester.post('/payments/guest-buy-tickets', {
      quantity,
      owners,
      ticketId,
      eventId,
      successUrl,
      cancelUrl,
    });
  }

  checkCoupon(coupon: string) {
    return this.requester.get(`/payments/check-coupon/${coupon}`);
  }
}
