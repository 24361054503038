import styles from './style.module.scss';
import CenteredContainer from '../../../components/containers/centeredContainer';
import IconedTitle from '../../../components/utils/iconedTitle';
import ticketIcon from '../../../assets/ticketIcon.svg';
import TicketLine from '../../../components/events/ticketLine';
import { UserTicket, Event } from '../../../types';
import List from '../../../components/list';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { fetchMyTickets } from '../../../store/slices/events';
import { useSearchParams } from 'react-router-dom';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import Sort from '../../../components/options/sort';
import Search from '../../../components/options/search';
import moment from 'moment/moment';

const sortOptions = [
  { label: 'Date de l\'Event', value: 'event' },
  { label: 'Date d\'achat', value: 'buy' },
];

const Tickets = () => {
  const [ searchParams ] = useSearchParams();
  const { setText } = useValidationModalContext();
  const [ tickets, setTickets ] = useState<UserTicket[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ sort, setSort ] = useState<string>('event');
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      const result = await dispatch(fetchMyTickets()).unwrap();
      setTickets(result);
    } catch (err) {
      console.error('Error fetching tickets home', err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchParams.get('status') === 'success') {
      setText('Vos billets ont bien été réservés !');
      fetchData();
    }
  }, [ searchParams ]);

  const events = useMemo(() => {
    if (!tickets) return null;
    let data = tickets.reduce((acc: Event[], cur) => {
      if (!acc.some((e: Event) => cur.event.id === e.id)) {
        return [ ...acc, cur.event ];
      } else {
        return acc;
      }
    }, []);
    data = data.filter(e => e.title.toLowerCase().includes(search.toLowerCase()));
    let sortFunction = (a: Event, b: Event) => moment(b.start_date).diff(a.start_date);
    // if (sort === 'buy') {
    //   sortFunction = (a: Event, b: Event) => (b.user_tickets?.length || 0) - (a.user_tickets?.length || 0);
    // }
    return data;
  }, [ tickets, search, sort ]);

  return (
    <CenteredContainer>
      <div className={styles.header}>
        <IconedTitle title={'Mes billets'} icon={ticketIcon} />
      </div>
      <List>
        <div className={styles.filtersContainer}>
          <Sort options={sortOptions} onChange={setSort}/>
          <Search onChange={setSearch} />
        </div>
        {!!events?.length && events.map((event: Event) => (
          <TicketLine key={event.id} event={event} tickets={tickets!.filter((t) => t.event.id === event.id)} />
        ))}
      </List>
    </CenteredContainer>
  );
};

export default Tickets;
