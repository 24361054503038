import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import mediaReducer from './slices/media';
import authReducer from './slices/auth';
import miscReducer from './slices/misc';
import networksReducer from './slices/networks';
import eventsReducer from './slices/events';
import usersReducer from './slices/users';
import accountReducer from './slices/account';
import recommendationsReducer from './slices/recommendations';

const reducers = combineReducers({
  auth: authReducer,
  media: mediaReducer,
  misc: miscReducer,
  networks: networksReducer,
  events: eventsReducer,
  users: usersReducer,
  account: accountReducer,
  recommendations: recommendationsReducer,
})
const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
