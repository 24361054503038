import styles from './style.module.scss';
import { useEffect, useRef, useState } from 'react';
import dropdownIcon from '../../../assets/dropdownIcon.svg';
import classNames from 'classnames';

interface Option {
  label: string,
  value: string,
}
interface Props {
  options: Option[],
  onChange: (selectedOption: string) => void,
}
const Sort = ({ options, onChange }: Props) => {
  const [ selectedOption, setSelectedOption ] = useState<string>(options[0].value);
  const [ modal, setModal ] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [ modal ]);

  const handleOutsideClicks = (event: TouchEvent | MouseEvent)=>{
    if(modal&& modalRef.current && !modalRef.current!.contains(event.target as Node)){
      setTimeout(() => setModal(false), 100);
    };
  };

  const toggleOption = (option: string) => {
    setSelectedOption(option);
    setModal(false);
    onChange(option);
  }

  return (
    <div className={styles.container}>
      <span className={styles.name}>Trier par :</span>
      <div className={styles.control} onClick={toggleModal}>
        <span>{options.find(o => o.value === selectedOption)!.label}</span>
        <img src={dropdownIcon} alt={'dropdown'} className={styles.dropdownIcon}/>
      </div>
      {modal && (
        <div ref={modalRef} className={styles.modal}>
          {options.map((option) => (
            <div key={option.value} className={styles.optionContainer} onClick={() => toggleOption(option.value)}>
              <div className={classNames(styles.optionSelector, selectedOption === option.value && styles.optionSelectorSelected)}/>
              <span className={styles.optionText}>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Sort;
