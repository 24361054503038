import styles from './style.module.scss';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import ColoredButton from '../../../buttons/coloredButton';
import { Ticket, Event } from '../../../../types';
import classNames from 'classnames';
import UnderlineTitle from '../../../utils/underlineTitle';
import { useValidationModalContext } from '../../../../contexts/ValidationModalContext';
import { useDetailsModalContext } from '../../../../contexts/DetailsModalContext';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useErrorModalContext } from '../../../../contexts/ErrorModalContext';
import TextInput from '../../../form/textInput';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { registerTicket } from '../../../../store/slices/events';
import api from '../../../../services/api';
import { appUrl } from '../../../../utils/config';
import CityText from '../../../utils/cityText';
import DateText from '../../../utils/dateText';
import { usePublicPage } from '../../../../utils/hooks';
import { useSearchParams } from 'react-router-dom';

enum TicketStep {
  SELECT,
  NAMES,
  FINALIZE,
}
export interface TicketBooking {
  name: string,
  job: string,
}
interface Inputs {
  tickets: TicketBooking[];
}
type TicketProps = {
  data: Ticket,
  selected: boolean,
  onClick: MouseEventHandler<HTMLDivElement>,
  mode: TicketStep,
  number?: number,
};

const TicketLine = ({ data, selected, onClick, mode, number }: TicketProps) => {
  return (
    <div className={styles.ticketContainer}>
      <div className={styles.textContainer}>
        <p className={styles.ticketName}>{data.title}</p>
        <p className={styles.ticketDescription}>{data.description}</p>
      </div>
      {mode === TicketStep.SELECT ? <div className={classNames(styles.priceContainer, {[styles.selected]: selected})} onClick={onClick}>
        <span className={classNames(styles.price, {[styles.priceSelected]: selected})}>{data.price}€</span>
      </div> : <div className={classNames(styles.priceContainer, {[styles.selected]: selected})} onClick={onClick}>
        <span className={classNames(styles.price, styles.priceShown)}>{number}</span>
        <span className={styles.ticketsSpan}>billet{number! > 1 ? 's' : ''}</span>
      </div>}
    </div>
  )
}

const Tickets = ({ event, tickets }: { event: Event, tickets: Ticket[] }) => {
  const [ searchParams ] = useSearchParams();
  const user = useAppSelector(state => state.account.user);
  const publicPage = usePublicPage();
  const { setError } = useErrorModalContext();
  const { setData, setType } = useDetailsModalContext();
  const { setText, setRedirect } = useValidationModalContext();
  const [ selected, setSelected ] = useState<Ticket | null>(null);
  const [ step, setStep ] = useState<TicketStep>(TicketStep.SELECT);
  const [ ticketsBooking, setTicketsBooking ] = useState<TicketBooking[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (searchParams.get('validation') === 'payment_successful'){
      setText('Votre paiement a bien été effectué. Vous allez recevoir votre billet par e-mail');
    }
  }, [ searchParams ]);

  useEffect(() => {
    if (tickets.length >= 1) {
      setSelected(tickets[0]);
    }
  }, [ tickets ]);

  const buyTickets = async () => {
    if (!selected) return;
    try {
      if (selected.price === 0) {
        await dispatch(registerTicket({
          ticketId: selected.id.toString(),
          owners: ticketsBooking,
        }))
      } else {
        let checkout = publicPage ?
          await api.payments.guestBuyTicket(
            ticketsBooking,
            ticketsBooking.length,
            event.id.toString(),
            selected.id.toString(),
            `${appUrl}/public/events?id=${event.id.toString()}&validation=payment_successful`,
            `${appUrl}/events/${event.id.toString()}?status=error`,
          ) : (
            await api.payments.buyTicket(
              ticketsBooking,
              ticketsBooking.length,
              event.id.toString(),
              selected.id.toString(),
              `${appUrl}/events/tickets`,
              `${appUrl}/events/${event.id.toString()}?status=error`,
            )
          );
        window.location.replace(checkout.data.url);
      }
    } catch (err) {
      setError(`Erreur lors de l\'achat de vos billets ${err}`);
    }
  };

  const SelectStep = () => {
    useEffect(() => {
      console.log('init select step');
    }, []);
    return (
      <>
        <div>
          {tickets.map((t) => <TicketLine mode={TicketStep.SELECT} key={t.id} data={t} selected={t.id === selected?.id} onClick={() => setSelected(t)}/>)}
        </div>
        <div className={styles.buttonContainer}>
          <ColoredButton text={'Réserver'} onClick={() => setStep(TicketStep.NAMES)} enabled={selected !== null}/>
        </div>
      </>
    );
  }

  const NamesStep = () => {
    const { register, control, handleSubmit, formState: { errors } } = useForm<Inputs>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resetOptions: {
        keepErrors: true,
        keepDirty: true,
      },
      defaultValues: {
        tickets: [{ name: user ? `${user.firstname} ${user.lastname}` : '', job: user?.job || '' }],
      },
    });
    const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
      setTicketsBooking(data.tickets);
      setStep(TicketStep.FINALIZE);
    };
    const { fields, append } = useFieldArray({
      control,
      name: 'tickets',
    });

    return (
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.formRow}>
            <TextInput errors={errors} register={register} options={{ required: true, minLength: { value: 3, message: 'Au moins 3 caractères' } }} name={`tickets.${index}.name`} placeholder={'Nom et prénom'}/>
            <div className={styles.rowSpacer}/>
            <TextInput errors={errors} register={register} options={{ required: true, minLength: { value: 2, message: 'Au moins 2 caractères' } }} name={`tickets.${index}.job`} placeholder={'Métier'}/>
          </div>
        ))}
        <span className={styles.addTicketButton} onClick={() => append({ name: '', job: ''})}>+ Ajouter une place</span>
        <ColoredButton className={styles.submitButton} text={'Valider'} type={'submit'} enabled={selected !== null}/>
      </form>
    );
  }

  const FinalizeStep = () => {
    return (<>
      <div>
        <TicketLine data={selected!} selected={false} onClick={console.log} mode={TicketStep.FINALIZE} number={ticketsBooking.length} />
        <CityText darkMode={true} city={`${event.address.number} ${event.address.street}, ${event.address.zip_code} ${event.address.city}`} />
        <DateText date={event.start_date} darkMode={true}/>
      </div>
      <div className={styles.buttonContainer}>
        <ColoredButton text={`Payer ${selected!.price * ticketsBooking.length}€ TTC`} onClick={buyTickets} enabled={selected !== null}/>
      </div>
    </>);
  };

  const renderStep = () => {
    return step === TicketStep.SELECT ? <SelectStep /> : (
      step === TicketStep.NAMES ? <NamesStep /> :
        <FinalizeStep/>
    );
  };

  const goBack = () => {
    setStep(step - 1);
  }

  return (
    <div className={styles.container}>
      {step > TicketStep.SELECT && <span className={styles.backButton} onClick={goBack}>{'<'} Retour</span>}
      <UnderlineTitle title={step === TicketStep.SELECT ? 'Choisissez votre billet' : (step === TicketStep.NAMES ? 'Nombre de places' : 'Finalisation')} />
      {renderStep()}
    </div>
  )
}

export default Tickets;
