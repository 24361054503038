import styles from './style.module.scss';
import { Item } from '../../../../types';
import classNames from 'classnames';

interface Props {
  item: Item,
};

function ArticleItem({ item }: Props) {

  return (
    <div className={classNames(styles.container, item.type === 'picture' && styles.imageContainer)}>
      {item.type === 'subtitle' && <h4 className={styles.subtitle}>{item.content}</h4>}
      {item.type === 'paragraph' && <p className={styles.paragraph}>{item.content}</p>}
      {item.type === 'picture' && <img src={item.content} className={styles.image}/>}
    </div>
  )
};

export default ArticleItem;
