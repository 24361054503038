import bg from '../../assets/Othr-fond-web.jpg';
import styles from './style.module.scss';

interface Props {
  icon: string,
  text: string,
  iconHeight: string,
  onClick: () => void,
}
const AccountLine = ({ icon, text, iconHeight, onClick }: Props) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.iconContainer}>
        <img src={icon} className={styles.icon} style={{ height: iconHeight }} alt={'account'} />
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default AccountLine;
