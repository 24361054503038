import * as React from 'react';
import {
  Outlet,
  RouterProvider,
  createBrowserRouter, useLocation, Navigate, useNavigate, useParams,
} from 'react-router-dom';

import Home from '../routes/home';
import Contacts from '../routes/othrs/contacts';
import styles from './style.module.scss';
import Recommendations from '../routes/recommendations';
import Networks from '../routes/networks';
import InviteNetwork from '../routes/networks/invite';
import Tickets from '../routes/events/tickets';
import MyEvents from '../routes/events/mine';
import Events from '../routes/events';
import Othrs from '../routes/othrs';
import Login from '../routes/auth/login';
import ValidationModal from '../components/modals/validationModal';
import ProtectedRoutes from './ProtectedRoutes';
import AuthBackground from '../components/authBackground';
import { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { useAppSelector } from '../store/hooks';
import api from '../services/api';
import ErrorModal from '../components/modals/errorModal';
import SwapRequests from '../routes/othrs/swapRequests';
import CreateEvent from '../routes/events/create';
import Settings from '../routes/settings';
import OthrModal from '../components/modals/othrModal';
import EventModal from '../components/modals/eventModal';
import NetworkModal from '../components/modals/networkModal';
import CreateRecommendation from '../routes/recommendations/create';
import Media from '../routes/media';
import CreateGroup from '../routes/networks/create';
import CreateParentGroup from '../routes/networks/createParent';
import CreateMedium from '../routes/media/create';
import Signup from '../routes/auth/signup';
import Profile from '../routes/othrs/profile';
import MediumModal from '../components/modals/mediumModal';
import MyMedia from '../routes/media/mine';
import Notifications from '../routes/notifications';
import ForgotPassword from '../routes/auth/forgotPassword';
import ResetPassword from '../routes/auth/resetPassword';
import { usePublicPage } from '../utils/hooks';

const Router = () => {
  const [ ready, setReady ] = useState(false);
  const jwt = useAppSelector((state) => state.auth.jwt);

  useEffect(() => {
    if (jwt) api.auth.setToken(jwt);
    setReady(true);
  }, [ jwt ]);

  const RedirectToOthr = () => {
    const params = useParams();
    return (<Navigate replace={true} to={`/public/othrs?id=${params.id}`}/>)
  }

  const router = createBrowserRouter([
    {
      element: <AuthWrapper/>,
      children: [
        { path: '/login', element: <Login /> },
        { path: '/signup', element: <Signup /> },
        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: '/reset-password', element: <ResetPassword /> },
        {
          path: '/public',
          children: [
            { path: '/public/events', element: <Events /> },
            { path: '/public/othrs', element: <Othrs /> },
            { path: '/public/media', element: <Media /> },
            { path: '/public/groups', element: <Networks /> },
          ],
        },
        { path: '/users/:id/swap', element: <RedirectToOthr />}
      ],
    },
    {
      path: '/',
      element: <ProtectedRoutes/>,
      children: [
        { path: '/', element: <Home />, },
        { path: '/events', element: <Events /> },
        { path: '/events/create', element: <CreateEvent /> },
        { path: '/events/edit', element: <CreateEvent /> },
        { path: '/events/tickets', element: <Tickets/> },
        { path: '/events/mine', element: <MyEvents/> },
        { path: '/users/:id', element: <RedirectToOthr/>},
        { path: '/othrs/:id', element: <RedirectToOthr/>},
        { path: '/othrs', element: <Othrs /> },
        { path: '/othrs/contacts', element: <Contacts /> },
        { path: '/othrs/swap-requests', element: <SwapRequests /> },
        { path: '/recommendations', element: <Recommendations /> },
        { path: '/recommendations/create', element: <CreateRecommendation /> },
        { path: '/groups', element: <Networks />, },
        { path: '/groups/create', element: <CreateGroup />, },
        { path: '/groups/edit', element: <CreateGroup />, },
        { path: '/groups/createParent', element: <CreateParentGroup />, },
        { path: '/groups/invite', element: <InviteNetwork />, },
        { path: '/media', element: <Media /> },
        { path: '/media/create', element: <CreateMedium /> },
        { path: '/media/edit', element: <CreateMedium /> },
        { path: '/media/mine', element: <MyMedia /> },
        { path: '/settings', element: <Settings /> },
        { path: '/profile', element: <Profile /> },
        { path: '/notifications', element: <Notifications /> },
      ]
    }
  ]);

  return ready ? <RouterProvider router={router} /> : null;
}

const AuthWrapper = () => {
  const jwt = useAppSelector(state => state.auth.jwt);
  const publicPage = usePublicPage();

  if (jwt && !publicPage) {
    return <Navigate to={'/'} replace />;
  }

  return (
    <div className={styles.authContainer}>
      <AuthBackground>
        <div className={styles.authOutletContainer}>
          <Outlet/>
          <ValidationModal />
          <ErrorModal/>
          <OthrModal />
          <EventModal />
          <NetworkModal />
          <MediumModal />
        </div>
      </AuthBackground>
    </div>
  )
};
export default Router;
