import styles from './style.module.scss';
import React from 'react';
import classNames from 'classnames';

type Props = {
  title: string;
  icon: string;
  titleClassname?: string;
}
const IconedTitle = ({ title, icon, titleClassname }: Props) => {
  return (
    <div className={styles.container}>
      <img src={icon} className={styles.icon}/>
      <h2 className={classNames(styles.title, titleClassname)}>{title}</h2>
    </div>
  );
}

export default IconedTitle;
