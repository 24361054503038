import Modal from 'react-modal';

import styles from './style.module.scss';
import LogoSquare from '../../../utils/logoSquare';
import { Recommendation, User } from '../../../../types';
import UserHeader from '../../../utils/userHeader';
import ColoredButton from '../../../buttons/coloredButton';
import WhiteButton from '../../../buttons/whiteButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useValidationModalContext } from '../../../../contexts/ValidationModalContext';
import { useErrorModalContext } from '../../../../contexts/ErrorModalContext';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  acceptRecommendation,
  refuseRecommendation,
  signedRecommendation
} from '../../../../store/slices/recommendations';
import TextInput from '../../../form/textInput';
import { useMemo } from 'react';

export enum ResponseModalMode {
  ACCEPT,
  REFUSE,
  PROP_SIGNED,
  PROP_REFUSED,
  DROPPED,
}
interface Props {
  open: boolean,
  onClose: () => void,
  mode: ResponseModalMode,
  recommendation: Recommendation,
}
type Inputs = {
  message: string;
  amount: number;
}

const ResponseModal = ({ onClose, open, mode, recommendation }: Props) => {
  const { text, setText } = useValidationModalContext();
  const { setError } = useErrorModalContext();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.recommendations.recommendationLoading);

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      if (mode === ResponseModalMode.ACCEPT) {
        await dispatch(acceptRecommendation({
          recommendationId: recommendation.id.toString(),
          message: data.message,
        })).unwrap();
        setText('La recommandation a bien été acceptée');
      } else if (mode === ResponseModalMode.PROP_SIGNED) {
          await dispatch(signedRecommendation({
            recommendationId: recommendation.id.toString(),
            amount: data.amount,
          })).unwrap();
        setText('La recommandation a bien été sauvegardée');
      } else {
        await dispatch(refuseRecommendation({
          recommendationId: recommendation.id.toString(),
          message: data.message,
        })).unwrap();
        if (mode === ResponseModalMode.REFUSE) {
          setText('La recommandation a bien été refusée');
        } else {
          setText('La recommandation a bien été sauvegardée');
        }
      }
      onClose();
    } catch (err) {
      setError('Ton message n\'a pas pu être envoyé');
    }
  };

  const formData = useMemo(() => {
    switch(mode) {
      case ResponseModalMode.REFUSE:
        return {
          placeholder: `Explique à ${recommendation.user.firstname} pourquoi tu refuses sa recommandation`,
          Button: WhiteButton,
          buttonText: 'Refuser la reco',
        }
      case ResponseModalMode.ACCEPT:
        return {
          placeholder: `Remercie ${recommendation.user.firstname} pour sa recommandation`,
          Button: ColoredButton,
          buttonText: 'Accepter la reco',
        }
      case ResponseModalMode.PROP_SIGNED:
        return {
          placeholder: `Chiffre d'affaire en euros HT`,
          Button: ColoredButton,
          buttonText: 'Envoyer',
        }
      case ResponseModalMode.PROP_REFUSED:
        return {
          placeholder: `Dis à ${recommendation.user.firstname} pourquoi le devis n'a pas été accepté`,
          Button: WhiteButton,
          buttonText: 'Envoyer',
        }
      case ResponseModalMode.DROPPED:
        return {
          placeholder: `Dis à ${recommendation.user.firstname} pourquoi la reco n'a pas pu poursuivre`,
          Button: WhiteButton,
          buttonText: 'Envoyer',
        }
      default:
        return {
          placeholder: `Explique à ${recommendation.user.firstname} pourquoi tu refuses sa recommandation`,
          Button: WhiteButton,
          buttonText: 'Refuser la reco',
        }
    }

  }, [ mode ]);

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={open}
      onRequestClose={onClose}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <UserHeader user={recommendation.user}/>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            {mode !== ResponseModalMode.PROP_SIGNED ? <TextInput
              placeholder={formData.placeholder}
              register={register}
              options={{ required: true }}
              errors={errors}
              name={'message'}
              multiline={true}
            /> : <TextInput
              placeholder={formData.placeholder}
              register={register}
              options={{ required: true, validate: (value) => ((!!value && !isNaN(parseInt(value))) || 'Montant invalide') }}
              errors={errors}
              name={'amount'}
            />}
            <formData.Button text={formData.buttonText} type={'submit'} loading={loading}/>
          </form>
        </div>
      </div>
    </Modal>
  )
};

export default ResponseModal;
