import styles from './style.module.scss';
import dateIcon from '../../../assets/eventsIcon.svg';
import dateIconBlack from '../../../assets/dateIconBlack.svg';
import classNames from 'classnames';
import moment from 'moment';

interface Props {
  date: Date;
  darkMode?: boolean;
};

function dateText({ date, darkMode }: Props) {
  return (
    <div className={styles.container}>
      <img src={darkMode ? dateIconBlack : dateIconBlack} alt={'location icon'} className={styles.locationIcon}/>
      <span className={classNames(styles.text, darkMode && styles.darkText)}>{moment(date).format('dddd DD MMMM YYYY à hh[h]mm')}</span>
    </div>
  )
};

export default dateText;
