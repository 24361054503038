import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { CityGroup, dataType, Network, NetworkType } from '../../types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchNetworkClasses } from '../../store/slices/networks';
import Carousel from '../../components/home/carousel';
import NetworkBanner from '../../components/networks/networkBanner';
import TypeHeader from '../../components/networks/typeHeader';
import Categorize from '../../components/options/categorize';
import Filter from '../../components/options/filter';
import Sort from '../../components/options/sort';
import Loader from '../../components/options/loader';
import Search from '../../components/options/search';
import networkIcon from '../../assets/networkIconColor.svg';
import api from '../../services/api';
import { usePublicPage } from '../../utils/hooks';
import { useSearchParams } from 'react-router-dom';
import { useDetailsModalContext } from '../../contexts/DetailsModalContext';
import { useValidationModalContext } from '../../contexts/ValidationModalContext';
import { useErrorModalContext } from '../../contexts/ErrorModalContext';

const sortOptions = [
  { label: 'Les derniers inscrits', value: 'createdAt' },
  { label: 'Nombre de membres', value: 'members' },
  { label: 'Nombre de Othrs', value: 'othrs' },
  { label: 'Nombre de Médias', value: 'media' },
  { label: 'Nombre d\'Events', value: 'events' },
];

const Networks = () => {
  const publicPage = usePublicPage();
  const dispatch = useAppDispatch();
  let [ searchParams ] = useSearchParams();

  const { setData, setType } = useDetailsModalContext();
  const { setText } = useValidationModalContext();
  const { setError } = useErrorModalContext();
  const [ classes, setClasses ] = useState<{ name: string, networks: Network[] }[] | null>(null);
  const [ banner, setBanner ] = useState<Network | null>(null);
  const classesLoading = useAppSelector(state => state.networks.classesLoading);
  const [ types, setTypes ] = useState<NetworkType[] | null>(null);

  const [ typeIds, setTypeIds ] = useState<string[]>([]);
  const [ search, setSearch ] = useState<string>('');
  const [ classBy, setClassBy ] = useState<string>('tops');
  const [ sort, setSort ] = useState<string>('createdAt');

  const fetchData = async () => {
    try {
      const data = await dispatch(fetchNetworkClasses({ typeIds, search, classBy, sort })).unwrap();
      setClasses(data.classes);
      setBanner(data.banner);
      const { data: types } = await api.misc.getNetworkTypes();
      setTypes(types);
    } catch (err) {
      console.error('Error fetching networks home', err);
    }
  };

  const showNetwork = (networkId: number) => {
    setType(dataType.Network);
    setData({ id: networkId });
  };

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      showNetwork(parseInt(id));
    }
    const status = searchParams.get('status');
    if (status === 'success') {
      setText('Le paiement de votre cotisation a bien été accepté !');
    } else if (status === 'error') {
      setError('Le paiement de votre cotisation a échoué. Veuillez contacter votre groupement');
    }
  }, [ searchParams ]);

  useEffect(() => {
    fetchData();
  }, [ typeIds, search, classBy, sort ]);

  return (
    <div className={styles.container}>
      {!!banner && <div className={styles.centeredWrapper}>
        <NetworkBanner network={banner}/>
      </div>}
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <img src={networkIcon} alt={'othr'} className={styles.eventIcon}/>
          <h2 className={styles.title}>Groupements partenaires</h2>
        </div>
        <div className={styles.filtersContainer}>
          <Categorize options={[{ label: 'Type', value: 'type' }, { label: 'Tops', value: 'tops' }]} onChange={setClassBy} />
          {!!types && <Filter name={'Type'} options={types.map(type => ({ label: type.name, value: type.id.toString() }))} onChange={setTypeIds}/>}
          <Sort options={sortOptions} onChange={setSort} />
        </div>
        <div className={styles.searchContainer}>
          {classesLoading && <Loader />}
          <Search onChange={setSearch}/>
        </div>
      </div>
      {!!classes && classes.map((c) => (
        <div key={c.name} className={styles.monthContainer}>
          <TypeHeader type={c.name}/>
          <Carousel type={dataType.Network} data={c.networks}/>
        </div>
      ))}
      {!classes || !classes.length && <div className={styles.placeholder}/>}
    </div>
  );
};

export default Networks;
