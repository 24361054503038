import styles from './style.module.scss';
import CenteredContainer from '../../../components/containers/centeredContainer';
import IconedTitle from '../../../components/utils/iconedTitle';
import mediaIcon from '../../../assets/mediaIconColor.svg';
import { Medium } from '../../../types';
import MediumLine from '../../../components/media/mediumLine';
import List from '../../../components/list';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { fetchMyMedia } from '../../../store/slices/media';
import Search from '../../../components/options/search';
import Sort from '../../../components/options/sort';
import moment from 'moment';
import { parseMedium } from '../../../utils/misc';

const sortOptions = [
  { label: 'Date', value: 'date' },
  { label: 'Pertinence', value: 'likes' },
  { label: 'Audience', value: 'views' },
];

const MyMedia = () => {
  const [ media, setMedia ] = useState<Medium[] | null>(null);
  const [ filteredMedia, setFilteredMedia ] = useState<Medium[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ sort, setSort ] = useState<string>('start_date');
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      const result = await dispatch(fetchMyMedia()).unwrap();
      setMedia(result.map(parseMedium));
    } catch (err) {
      console.error('Error fetching media home', err);
    }
  }

  useEffect(() => {
    if (!media) return;
    let newMedia = [ ...media ].filter(e => e.title.toLowerCase().includes(search.toLowerCase()));
    let sortFunction = (a: Medium, b: Medium) => moment(b.createdAt).diff(a.createdAt);
    if (sort === 'likes') {
      sortFunction = (a: Medium, b: Medium) => (b.likes - a.likes);
    } else if (sort === 'views') {
      sortFunction = (a: Medium, b: Medium) => (b.views - a.views);
    }
    newMedia = newMedia.sort(sortFunction);
    setFilteredMedia(newMedia);
  }, [ media, search, sort ]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CenteredContainer>
      <div className={styles.header}>
        <IconedTitle title={'Mes médias'} icon={mediaIcon} />
      </div>
      {!!filteredMedia && <List>
        <div className={styles.filtersContainer}>
          <Sort options={sortOptions} onChange={setSort}/>
          <Search onChange={setSearch} />
        </div>
        {filteredMedia.map((medium: Medium) => (
          <MediumLine key={medium.id} medium={medium} />
        ))}
      </List>}
    </CenteredContainer>
  );
};

export default MyMedia;
