import { Component } from './index';

export class Recommendations extends Component {
  getAll() {
    return this.requester.get('/recommendations');
  }

  getGiven() {
    return this.requester.get('/recommendations/given');
  }

  getReceived() {
    return this.requester.get('/recommendations/received');
  }

  get(id: string) {
    return this.requester.get(`/recommendations/${id}`);
  }

  create(data: any) {
    return this.requester.post('/recommendations', data);
  }

  delete(id: string) {
    return this.requester.delete(`/recommendations/${id}`);
  }

  accept(id: string, message: string) {
    return this.requester.put(`/recommendations/${id}/accept`, { message });
  }

  refuse(id: string, message: string) {
    return this.requester.put(`/recommendations/${id}/refuse`, { message });
  }

  contractSigned(id: string, amount: number) {
    return this.requester.post(`/recommendations/${id}/contract-signed`, { amount });
  }
}
