import styles from './style.module.scss';
import { FieldErrors, useFieldArray } from 'react-hook-form';
import TextInput from '../../../../components/form/textInput';
import OutlineButton from '../../../../components/buttons/outlineButton';
import { useEffect } from 'react';
import ImageInput from '../../../../components/form/imageInput';
import SectionTitle from '../../../../components/form/sectionTitle';

const Tickets = ({ control, register, errors }: { control: any, register: Function, errors?: FieldErrors | undefined; }) => {
  const { fields, append } = useFieldArray({
    control,
    name: 'encarts',
  });

  return (
    <div className={styles.ticketsContainer}>
      {fields.map((field, index) => (
        <div key={field.id} className={styles.ticketForm}>
          <SectionTitle title={`Encart #${index + 1}`} />
          <TextInput errors={errors} register={register} options={{ required: true }} name={`encarts.${index}.subtitle`} placeholder={`Sous-titre`}/>
          <TextInput multiline={true} errors={errors} register={register} options={{ required: true, minLength: { value: 30, message: 'Au moins 30 caractères' } }} name={`encarts.${index}.paragraph`} placeholder={'Contenu'}/>
          <ImageInput errors={errors} options={{ required: true }} name={`encarts.${index}.picture_url`} control={control} placeholder={'Illustration'}/>
        </div>
        ))
      }
      <OutlineButton text={'Ajouter un encart'} onClick={() => append({})}/>
    </div>
  );
}
export default Tickets;
