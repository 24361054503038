import styles from './style.module.scss';
import React from 'react';
import classNames from 'classnames';
import Spinner from 'react-spinners/BeatLoader';

interface Props {
  text: string;
  onClick?: (e: React.MouseEvent) => void;
  enabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  icon?: string;
  className?: string;
  loading?: boolean;
  legend?: string;
};

function ColoredButton({ text, onClick, enabled, type, className, icon, loading = false, legend }: Props) {
  return (
    <button
      className={classNames(styles.container, className, { [styles.enabled]: enabled !== false })}
      onClick={(e: React.MouseEvent) => {
        if (onClick) onClick(e);
      }}
      type={type}
    >
      {loading ? (
          <Spinner
            size={7}
            color={'#ffffff'}
          />
        ) : (
          <>
            {icon && <img src={icon} alt={'left'} className={styles.icon}/>}
            <p className={styles.text}>{text}</p>
          </>
        )
      }
      {!!legend && <span className={styles.legend}>{legend}</span> }
    </button>
  )
};

export default ColoredButton;
