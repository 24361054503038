import styles from './style.module.scss';

type Props = {
  number: number,
  text: string,
  align: string,
};
function Stat({ number, text, align }: Props) {
  return (
    <div className={styles.container} style={{ justifyContent: align }}>
      <span className={styles.number}>{number} <span className={styles.text}>{text}</span></span>
    </div>
  )
}

export default Stat;
