import styles from './style.module.scss';
import React from 'react';
import CityText from '../../utils/cityText';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, User } from '../../../types';

interface Props {
  data: User;
  onClick?: (e: React.MouseEvent) => void;
};

function OthrCard({ data, onClick }: Props) {
  const { setData, setType } = useDetailsModalContext();

  const showOthr = () => {
    setType(dataType.Othr);
    setData(data);
  };

  return (
    <div className={styles.container} onClick={onClick || showOthr}>
      <img src={data.picture_url} alt={'othr'} className={styles.imageBg}/>
      <div className={styles.shadow}/>
      <div className={styles.infosContainer}>
        <span className={styles.surname}>{data.surname}</span>
        <h2 className={styles.name}>{data.firstname} {data.lastname}</h2>
        <span className={styles.job}>{data.job}</span>
        <CityText city={data.address?.city} size={'small'} />
      </div>
    </div>
  )
};

export default OthrCard;
