import styles from './style.module.scss';
import React from 'react';
import classNames from 'classnames';

interface Props {
  onClick?: () => void;
  icon: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  legend?: string,
  iconHeight?: string,
};

function WhiteIconButton({ onClick, icon, className, type, legend, iconHeight }: Props) {
  return (
      <button
        className={classNames(styles.button, className)}
        onClick={() => {
          if (onClick) onClick();
        }}
        type={type}
      >
        <img src={icon} alt={'buttonIcon'} className={styles.icon} style={{ height: iconHeight }}/>
        {!!legend && <span className={styles.legend}>{legend}</span>}
      </button>
  )
};

export default WhiteIconButton;
