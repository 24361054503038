import styles from './style.module.scss';
import React, { PropsWithChildren } from 'react';

const Line = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
}

export default Line;
