import styles from './style.module.scss';
import { FieldErrors, FieldError } from 'react-hook-form';
import { useMemo } from 'react';

const ErrorMessage = ({ errors, name }: { errors: FieldErrors | undefined, name: string }) => {
  console.log('errors', name, errors);
  const getMessageFromError = (error: FieldError) => {
    if (error.message && error.message.length > 0) return error.message;
    return 'Champ invalide';
  }

  const error = errors?.[name] as FieldError;

  if (!errors || !errors[name] || !error) return null;

  const message = getMessageFromError(error);
  return <span className={styles.error}>{message}</span>;
};

export default ErrorMessage;
