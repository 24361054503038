import styles from './style.module.scss';
import locationIcon from '../../../assets/locationIcon.svg';
import locationIconBlack from '../../../assets/locationIconOutline.svg';
import classNames from 'classnames';

interface Props {
  city: string;
  darkMode?: boolean;
  size?: string;
};

function CityText({ city, darkMode, size }: Props) {
  return (
    <div className={styles.container}>
      <img src={darkMode ? locationIconBlack : locationIcon} alt={'location icon'} className={styles.locationIcon}/>
      <span className={classNames(styles.text, darkMode && styles.darkText, size === 'small' && styles.smallText)}>{city}</span>
    </div>
  )
};

export default CityText;
