import { Component } from './index';

export interface NetworkClassesOptions {
  cityGroupIds?: string[],
  typeIds?: string[],
  search?: string,
  classBy?: string,
  sort?: string,
}

export class Networks extends Component {
  getAll() {
    return this.requester.get('/networks');
  }

  get(id: string) {
    return this.requester.get(`/networks/${id}`);
  }

  getPublic(id: string) {
    return this.requester.get(`/networks/${id}/public`);
  }

  getClasses(options: NetworkClassesOptions) {
    const params = new URLSearchParams();
    if (options.cityGroupIds?.length) {
      params.append('cityGroupIds', options.cityGroupIds.join(','));
    }
    if (options.typeIds?.length) {
      params.append('typeIds', options.typeIds.join(','));
    }
    if (options.search?.length) {
      params.append('search', options.search);
    }
    params.append('classBy', options.classBy || 'sphere');
    params.append('sort', options.sort || 'createdAt');
    return this.requester.get(`/networks/classes?${params.toString()}`);
  }

  getHome() {
    return this.requester.get(`/networks/home`);
  }

  search(q: string) {
    return this.requester.get(`/networks/search/${q}`);
  }

  create(data: any) {
    return this.requester.post(`/networks`, data);
  }

  join(id: string) {
    return this.requester.post(`/networks/${id}/join_request`);
  }

  edit(id: string, data: any) {
    return this.requester.patch(`/networks/${id}`, data);
  }

  contact(networkId: string, title: string, message: string) {
    return this.requester.post(`/networks/${networkId}/contact`, {
      title,
      message,
    });
  }

  invite(name: string, email: string, message: string) {
    return this.requester.post(`/networks/invite`, {
      name,
      email,
      message,
    });
  }
}
