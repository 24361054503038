import styles from './style.module.scss';

import LogoSquare from '../../utils/logoSquare';
import networkLogo from '../../../assets/mocked/mockedLogo.png';
import WhiteButton from '../../buttons/whiteButton';
import DateSquare from '../../utils/dateSquare';
import { dataType, Event } from '../../../types';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import React, { useMemo } from 'react';
import OthrSquare from '../../utils/othrSquare';

const EventBanner = ({ event }: { event: Event }) => {
  const { setType, setData } = useDetailsModalContext();

  const showEvent = () => {
    setData(event);
    setType(dataType.Event);
  };

  const organizerLogo = useMemo(() => {
    if (event.user) return event.user.logo_url || event.user.picture_url;
    else if (event.network) return event.network.logo_url || event.network.picture_url;
    else return null;
  }, [ event ]);

  return (
    <div className={styles.container}>
      <img src={event.banner_url || event.picture_url} className={styles.image} alt={'bg-event'} />
      <div className={styles.shadow} />
      <div className={styles.infosContainer}>
        <div className={styles.squaresContainer}>
          {organizerLogo ? <LogoSquare className={styles.logo} image={organizerLogo}/> : <OthrSquare className={styles.logo} />}
          <DateSquare date={event.start_date} className={styles.logo}/>
        </div>
        <h2 className={styles.title}>{event.title}</h2>
        <div className={styles.buttonContainer}>
          <WhiteButton text={'Participer'} onClick={showEvent} />
        </div>
      </div>
    </div>
  )
};

export default EventBanner;
