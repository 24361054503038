import styles from './style.module.scss';
import React, { useEffect, useMemo, useState } from 'react';

import DetailsPageModal from '../detailsPageModal';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, User, Event } from '../../../types';
import CityText from '../../utils/cityText';
import Stat from '../networkModal/stat';
import { useAppDispatch } from '../../../store/hooks';
import { fetchUser, fetchUserPublic } from '../../../store/slices/users';
import ColoredButton from '../../buttons/coloredButton';
import LogoSquare from '../../utils/logoSquare';
import UserAttributes from './userAttributes';
import Carousel from '../../home/carousel';
import UnderlineTitle from '../../utils/underlineTitle';
import Map from '../../map';
import swapIconWhite from '../../../assets/swapIconWhite.svg';
import swapIcon from '../../../assets/swapIcon.svg';
import recommendationsIcon from '../../../assets/recommendationsIconColor.svg';
import recommendationsWhiteIcon from '../../../assets/recommendationsWhiteIcon.svg';
import editIconBlack from '../../../assets/editIconBlack.svg';
import SwapRequestModal from './swapRequestModal';
import { usePublicPage } from '../../../utils/hooks';
import { useNavigate } from 'react-router-dom';
import LoadingModal from '../loadingModal';
import { parseMedium } from '../../../utils/misc';
import CopyToClipboardButton from '../../buttons/whiteButton/copyToClipboardButton';

function OthrModal() {
  const { setData, setType } = useDetailsModalContext();
  const navigate = useNavigate();
  const publicPage = usePublicPage();
  const { type, data } = useDetailsModalContext();

  const dispatch = useAppDispatch();
  const [ loading, setLoading ] = useState(true);
  const [ user, setUser ] = useState<User | null>(null);
  const [ swapModal, setSwapModal ] = useState<boolean>(false);

  const fetchData = async () => {
    if (!data || type !== dataType.Othr) return;
    try {
      const result = publicPage ?
        await dispatch(fetchUserPublic(data.id.toString())).unwrap():
        await dispatch(fetchUser(data.id.toString())).unwrap()
      ;
      if (result.media) result.media = result.media.map(parseMedium);
      setUser(result);
      setLoading(false);
      console.log('result', result);
    } catch (err) {
      console.error('Error fetching network', err);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [ data?.id, type ]);

  const buttonSpecs = useMemo(() => {
    if (!user || !user.relationship) return null;
    if (user.relationship === 'A') {
      // It's me
      return {
        text: 'Modifier',
        longText: 'Modifier mon profil',
        onClick: () => {
          setData(null);
          setType(null);
          navigate('/profile');
        },
        colorIcon: editIconBlack,
      }
    } else if (user.relationship === 'B') {
      // It's one of my contacts
      return {
        text: 'Recommander',
        longText: 'Recommander',
        icon: recommendationsWhiteIcon,
        colorIcon: recommendationsIcon,
        onClick: () => {
          setData(null);
          setType(null);
          navigate(`/recommendations/create?user=${user.id}`)
        },
      }
    } else {
      // I can meet him
      return {
        text: 'Rencontrer',
        longText: 'Rencontrer',
        icon: swapIconWhite,
        colorIcon: swapIcon,
        disabled: !!user.swapRequest,
        legend: user.swapRequest ? (user.swapRequest?.user_b_id === user.id ? 'Demande envoyée' : 'Demande reçue') : undefined,
        onClick: () => setSwapModal(true),
      }
    }
  }, [ user ]);

  const urlParams = user?.video_url ? new URLSearchParams(user.video_url.split('?')[1]) : null;
  const formattedVideoUrl = `https://youtube.com/embed/${urlParams?.get('v')}?autoplay=0`;

  const participatedEvents = useMemo(() => {
    if (!user?.user_tickets) return null;
    console.log('tickets', user.user_tickets);
    return user.user_tickets.filter(ticket => new Date(ticket.event.start_date) > new Date()).map(t => t.event).reduce<Event[]>((acc, cur, i) => {
      console.log('acc, cur', acc, cur, i)
      if (!acc.some((event) => (cur.id === event.id))) {
        return [ ...acc, cur ];
      } else {
        return acc;
      }
    }, []);
  }, [ user?.user_tickets ]);

  if (!data || type !== dataType.Othr) return null;
  if (loading) return <LoadingModal/>;
  if (!user) return null;

  return (
    <DetailsPageModal>
      <div className={styles.header}>
        <img src={user.picture_url} className={styles.headerImage} alt={'user'} />
        <div className={styles.shadow} />
        <div className={styles.headerInfosContainer}>
          <span className={styles.surname}>{user.surname}</span>
          <h2 className={styles.headerTitle}>{user.firstname}<br/>{user.lastname}</h2>
          <span className={styles.subtitle}>{user.job}</span>
          {!!user.address && <CityText city={user.address.city} />}
        </div>
        <div className={styles.buttonContainer}>
          {!!buttonSpecs && <ColoredButton className={styles.headerRoundButton} text={buttonSpecs.text} onClick={buttonSpecs.onClick} icon={buttonSpecs.icon} enabled={!buttonSpecs.disabled} legend={buttonSpecs.legend}/>}
        </div>
      </div>
      <div className={styles.stats}>
        <Stat number={user.nbRecos || 0} text={`Reco${(user.nbRecos || 0) > 1 ? 's' : ''}`} align={'flex-start'}/>
        <div className={styles.separator}/>
        <Stat number={user.ticketsCount || 0} text={`Billet${(user.ticketsCount || 0) > 1 ? 's' : ''}`} align={'center'}/>
        <div className={styles.separator}/>
        <Stat number={user.nbSwap || 0} text={`Contact${(user.nbSwap|| 0) > 1 ? 's' : ''}`} align={'flex-end'}/>
      </div>
      <div className={styles.content}>
        {user.relationship === 'B' && <h2 className={styles.title}>Coordonnées</h2>}
        {user.relationship === 'B' && <div className={styles.contact}>
          {!!(user?.phone) && <CopyToClipboardButton text={user.phone} />}
        <div className={styles.spacer}/>
          {!!(user?.email_contact) && <CopyToClipboardButton text={'Addresse mail'} value={user.email_contact} />}
        </div>}
        {(user.relationship === 'C' || user.relationship === 'B') && user.commonRelationships && <div className={styles.commonContainer}>
          <h2 className={styles.title}>Relations en commun</h2>
          <p className={styles.commonSubtitle}>{user.relationship === 'C' ? `Je peux rencontrer ${user.firstname} grâce à ${user.commonRelationships.length} de mes contacts` : `Je partage ${user.commonRelationships.length} contact${user.commonRelationships.length > 1 ? 's' : ''} avec ${user.firstname}`}</p>
          <div className={styles.commonLine}>
            {user.commonRelationships.map(u => (
              <div key={u.id} className={styles.commonUser}>
                <LogoSquare image={u.picture_url} className={styles.commonUserImage}/>
                <span className={styles.commonUserName}>{u.firstname}</span>
                <span className={styles.commonUserName}>{u.lastname}</span>
              </div>
              ))}
           </div>
        </div>}
        {!!user.description && <h2 className={styles.title}>Qui est {user.firstname} ?</h2>}
        {!!user.description && <p className={styles.description}>{user.description}</p>}
        {(!!user.logo_url && !!user.company) && <div className={styles.companyContainer}>
          <LogoSquare image={user.logo_url}/>
          <div className={styles.companyInfosContainer}>
            <span className={styles.company}>{user.company}</span>
            {!!user.website && <span className={styles.website} onClick={() => window.open(user!.website.includes('http') ? user!.website : `https://${user!.website}`, "_blank", "noreferrer")}>{user.website}</span>}
          </div>
        </div>}
      </div>
      {user.video_url && <iframe
        className={styles.video}
        src={formattedVideoUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        frameBorder={0}
      />}
      <UserAttributes _arguments={user.arguments} anecdotes={user.anecdotes} services={user.services} values={user.activities}/>
      {!publicPage && <>
        {participatedEvents && participatedEvents.length > 0 && <div className={styles.content}>
          <UnderlineTitle title={`${user.firstname} y participe`}/>
        </div>}
        {participatedEvents && participatedEvents.length > 0 && <div className={styles.carousel}>
          <Carousel type={dataType.Event} data={participatedEvents} />
        </div>}
        {user.media && user.media.length > 0 && <div className={styles.content}>
          <UnderlineTitle title={`Ses médias`}/>
        </div>}
        {user.media && user.media.length > 0 && <div className={styles.carousel}>
          <Carousel type={dataType.Medium} data={user.media} />
        </div>}
        {user.networks && user.networks.length > 0 && <div className={styles.content}>
          <UnderlineTitle title={`Les réseaux de ${user.firstname}`}/>
        </div>}
        {user.networks && user.networks.length > 0 && <div className={styles.carousel}>
          <Carousel type={dataType.Network} data={user.networks} />
        </div>}
      </>}
      <div className={styles.content}>
        <UnderlineTitle title={`Où est ${user.firstname} ?`}/>
        <div className={styles.mapSeparator}/>
        {!!user.address && <Map address={user.address} />}
        {user.relationship === 'A' && <div className={styles.editButtonContainer}>
          <ColoredButton text={'Modifier mon profil'} onClick={() => {
            setData(null);
            setType(null);
            navigate('/profile');
          }}/>
        </div>}
      </div>
      {user.relationship !== 'A' && <div className={styles.footer}>
        <img src={user.picture_url} className={styles.headerImage} alt={'user'} />
        <div className={styles.shadow} />
        <div className={styles.footerInfosContainer}>
          <span className={styles.surname}>{user.surname}</span>
          <h2 className={styles.headerTitle}>{user.firstname} {user.lastname}</h2>
          <span className={styles.subtitle}>{user.job}</span>
          {!!user.address && <CityText city={user.address.city} />}
          {!publicPage && !!buttonSpecs && <ColoredButton className={styles.footerButton} text={buttonSpecs.longText} icon={buttonSpecs.icon} onClick={buttonSpecs.onClick} enabled={!buttonSpecs.disabled} legend={buttonSpecs.legend}/>}
        </div>
      </div>}
      <SwapRequestModal open={swapModal} onClose={() => setSwapModal(false)} user={user}/>
    </DetailsPageModal>
  )
}

export default OthrModal;
