import styles from '../../routes/othrs/contacts/style.module.scss';
import { PropsWithChildren } from 'react';
import Line from './line';

type Props = {
  data?: any[],
}
const List = ({ children, data }: PropsWithChildren<Props>) => {
  return (
    <ul className={styles.list}>
      {children}
    </ul>

  )
}

export default List;
