import styles from './style.module.scss';
import React, { useState } from 'react';
import { dataType, Recommendation, RecommendationStatus } from '../../../types';
import moment from '../../../utils/moment';
import recommendationBlackIcon from '../../../assets/recommendationsBlackIcon.svg';
import arrowRightBlack from '../../../assets/arrowRightBlack.svg';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import WhiteButton from '../../buttons/whiteButton';
import LogoSquare from '../../utils/logoSquare';
import WhiteIconButton from '../../buttons/whiteIconButton';
import MessageModal from '../../modals/recommendationModal/messageModal';
import ResponseModal, { ResponseModalMode } from '../../modals/recommendationModal/responseModal';
import acceptIcon from '../../../assets/acceptIcon.svg';
import refuseIcon from '../../../assets/refuseIcon.svg';

export enum RecommendationMode {
  RECEIVED = 'received',
  GIVEN = 'given',
}
type Props = {
  recommendation: Recommendation;
  mode: RecommendationMode;
}
const RecommendationLine = ({ recommendation, mode }: Props) => {
  const { setData, setType } = useDetailsModalContext();
  const [ showMessage, setShowMessage ] = useState<boolean>(false);
  const [ showResponse, setShowResponse ] = useState<ResponseModalMode | null>(null);

  const showUser = () => {
    setData(mode === RecommendationMode.RECEIVED ? recommendation.user : recommendation.worker);
    setType(dataType.Othr);
  }

  const showRecommendation = () => {
    setData(recommendation);
    setType(dataType.Recommendation);
  }

  const getStatusText = () => {
    if (recommendation.status === RecommendationStatus.ACCEPTED) {
      return 'Acceptée';
    } else if (recommendation.status === RecommendationStatus.REFUSED) {
      return 'Refusée';
    } else if (recommendation.status === RecommendationStatus.PENDING) {
      return 'En attente';
    } else {
      return recommendation.contract_amount ? `${recommendation.contract_amount.toString()}€ HT` : 'Contrat signé';
    }
  }

  const getLegend = () => {
    if (recommendation.status === RecommendationStatus.ACCEPTED) {
      return 'Voir le message';
    } else if (recommendation.status === RecommendationStatus.REFUSED) {
      return 'Voir le message';
    } else if (recommendation.status === RecommendationStatus.PENDING) {
      return undefined;
    } else {
      return recommendation.contract_amount ? 'Contrat signé' : undefined;
    }
  }

  const handleClick = () => {
    if (recommendation.status === RecommendationStatus.ACCEPTED || recommendation.status === RecommendationStatus.REFUSED || recommendation.status === RecommendationStatus.CONTRACT_SIGNED) {
      setShowMessage(true);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.infosContainer} onClick={showUser}>
        <LogoSquare image={recommendation[mode === RecommendationMode.GIVEN ? 'worker' : 'user'].picture_url} className={styles.image}/>
        <div className={styles.infosText}>
          <span className={styles.title}>{recommendation.title}</span>
          <span className={styles.name}>{recommendation[mode === RecommendationMode.GIVEN ? 'worker' : 'user'].firstname} {recommendation[mode === RecommendationMode.GIVEN ? 'worker' : 'user'].lastname}</span>
          <span className={styles.updatedOn}>Mis à jour {moment(recommendation.updatedAt).fromNow()}</span>
        </div>
        <img src={arrowRightBlack} className={styles.arrowRight} />
      </div>
      <div className={styles.recosContainer}>
        <WhiteIconButton onClick={showRecommendation} icon={recommendationBlackIcon} legend={'Reco'} iconHeight={'1.1rem'}/>
        {mode === RecommendationMode.GIVEN ? <div className={styles.statusButtonContainer}>
          <WhiteButton
            {...(recommendation.status !== RecommendationStatus.PENDING && { rightAction: handleClick })}
            disabled={recommendation.status === RecommendationStatus.PENDING}
            text={getStatusText()}
            onClick={handleClick}
            legend={getLegend()}
            textColor={recommendation.status === RecommendationStatus.CONTRACT_SIGNED ? '#28CB80' : undefined}
            greenLegend={recommendation.status === RecommendationStatus.ACCEPTED}
            redLegend={recommendation.status === RecommendationStatus.REFUSED}
          />
        </div> : (recommendation.status === 'PENDING' ? <div className={styles.responseButtonsContainer}>
          <WhiteIconButton onClick={() => setShowResponse(ResponseModalMode.ACCEPT)} icon={acceptIcon} legend={'Accepter'} iconHeight={'.8rem'}/>
          <WhiteIconButton onClick={() => setShowResponse(ResponseModalMode.REFUSE)} icon={refuseIcon} legend={'Refuser'} iconHeight={'1rem'} className={styles.rightButton}/>
        </div> : (recommendation.status === 'ACCEPTED' ? <div className={styles.responseButtonsContainer}>
          <WhiteIconButton onClick={() => setShowResponse(ResponseModalMode.PROP_SIGNED)} icon={acceptIcon} legend={'Devis signé'} iconHeight={'.8rem'}/>
          <WhiteIconButton onClick={() => setShowResponse(ResponseModalMode.PROP_REFUSED)} icon={refuseIcon} legend={'Devis refusé'} iconHeight={'.9rem'} className={styles.rightButton}/>
          <WhiteIconButton onClick={() => setShowResponse(ResponseModalMode.DROPPED)} icon={refuseIcon} legend={'Sans suite'} iconHeight={'.9rem'} className={styles.rightButton}/>
        </div> : <div className={styles.statusButtonContainer}>
          <WhiteButton
            rightAction={handleClick}
            text={getStatusText()}
            onClick={handleClick}
            legend={getLegend()}
            textColor={recommendation.status === RecommendationStatus.CONTRACT_SIGNED ? '#28CB80' : undefined}
            greenLegend={recommendation.status === RecommendationStatus.CONTRACT_SIGNED}
            redLegend={recommendation.status === RecommendationStatus.REFUSED}
          />
        </div>))}
      </div>
      <MessageModal
        open={showMessage}
        onClose={() => setShowMessage(false)}
        message={recommendation.response_message!}
        user={mode === RecommendationMode.RECEIVED ? recommendation.user : recommendation.worker}
      />
      <ResponseModal open={showResponse !== null} onClose={() => setShowResponse(null)} mode={showResponse!} recommendation={recommendation}/>
    </div>
  );
}

export default RecommendationLine;
