import WhiteButton from './index';
import { copyToClipboard } from '../../../utils/misc';
import { useState } from 'react';
import copyIcon from '../../../assets/copyIcon.svg';

interface Props {
  text: string,
  value?: string,
  legendText?: string,
}
const CopyToClipboardButton = ({ text, value, legendText, ...props }: Props) => {
  const [ copied, setCopied ] = useState<boolean>(false);
  const handleClick = () => {
    copyToClipboard(value || text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  return (
    <WhiteButton iconHeight={'1rem'} icon={copyIcon} text={text} onClick={handleClick} { ...(copied && { legend: legendText || 'Copié !' })} greenLegend={true} {...props}/>
  )
}

export default CopyToClipboardButton;
