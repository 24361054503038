import styles from './style.module.scss';
import recommendationIcon from '../../assets/recommendationsIconColor.svg';
import CenteredContainer from '../../components/containers/centeredContainer';
import IconedTitle from '../../components/utils/iconedTitle';
import WhiteButton from '../../components/buttons/whiteButton';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TabBar from '../../components/utils/tabBar';
import { useEffect, useMemo, useState } from 'react';
import RecommendationLine, { RecommendationMode } from '../../components/recommendations/recommendationLine';
import OthrModal from '../../components/modals/othrModal';
import redAddIcon from '../../assets/redAddIcon.svg';
import { dataType, Recommendation, RecommendationStatus } from '../../types';
import recommendations, { fetchGivenRecommendations, fetchReceivedRecommendations } from '../../store/slices/recommendations';
import { useAppDispatch } from '../../store/hooks';
import OutlineButton from '../../components/buttons/outlineButton';
import Filter from '../../components/options/filter';
import Sort from '../../components/options/sort';
import moment from 'moment/moment';
import { useDetailsModalContext } from '../../contexts/DetailsModalContext';

const tabs = [
  'Recos reçues',
  'Recos envoyées',
];
const sortOptions = [
  { label: 'Date', value: 'updatedAt' },
  { label: 'Statut', value: 'status' },
  { label: 'Chiffre d\'affaires ', value: 'amount' },
];

const Recommendations = () => {
  const { setType, setData } = useDetailsModalContext();
  const [ searchParams ] = useSearchParams();
  const [ currentTab, setCurrentTab ] = useState<string>(tabs[0]);
  const isSent = useMemo(() => (currentTab === tabs[1]), [ currentTab ]);
  const [ givenRecommendations, setGivenRecommendations ] = useState<Recommendation[] | null>(null);
  const [ receivedRecommendations, setReceivedRecommendations ] = useState<Recommendation[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ sort, setSort ] = useState<string>('desc');
  const [ statuses, setStatuses ] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      const given = await dispatch(fetchGivenRecommendations()).unwrap();
      setGivenRecommendations(given);
      const received = await dispatch(fetchReceivedRecommendations()).unwrap();
      setReceivedRecommendations(received);
      console.log('recommendations', given, received);
    } catch (err) {
      console.error('Error fetching recommendations', err);
    }
  }

  const orderedRecommendations = useMemo(() => {
    if (!givenRecommendations || !receivedRecommendations) return null;
    let [ newGivenRecommendations, newReceivedRecommendations ] = [ [...givenRecommendations ], [...receivedRecommendations] ];
    let sortFunction: (a: Recommendation, b: Recommendation) => number = (a, b) => moment(b.updatedAt).diff(a.updatedAt);
    if (sort === 'amount') {
      sortFunction = (a, b) => (b.contract_amount || 0) - (a.contract_amount || 0);
    }
    newGivenRecommendations = newGivenRecommendations
      .filter(reco => statuses.length === 0 || statuses.includes(reco.status))
      .filter(reco => reco.worker.firstname.toLowerCase().includes(search.toLowerCase()) ||
        reco.worker.lastname.toLowerCase().includes(search.toLowerCase())
      )
      .sort(sortFunction);
    newReceivedRecommendations = newReceivedRecommendations
      .filter(reco => statuses.length === 0 || statuses.includes(reco.status))
      .filter(reco => reco.user.firstname.toLowerCase().includes(search.toLowerCase()) ||
        reco.user.lastname.toLowerCase().includes(search.toLowerCase())
      )
      .sort(sortFunction);
    return {
      givenRecommendations: newGivenRecommendations,
      receivedRecommendations: newReceivedRecommendations,
    }
  }, [ givenRecommendations, receivedRecommendations, sort, search, statuses ]);

  const totalAmount = useMemo(() => {
    if (!givenRecommendations || !receivedRecommendations) return null;
    return [ ...givenRecommendations, ...receivedRecommendations ].reduce((acc, cur) => (
      acc + (cur.contract_amount || 0)
    ), 0);
  }, [ givenRecommendations, receivedRecommendations ]);

  useEffect(() => {
    fetchData();
  }, []);

  const showRecommendation = (id: number) => {
    setData({ id });
    setType(dataType.Recommendation);
  }

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) showRecommendation(parseInt(id));
  }, [ searchParams ]);

  return (
    <CenteredContainer>
      <div className={styles.header}>
        <IconedTitle title={'Recommandations'} icon={recommendationIcon}/>
        <Link to={'/recommendations/create'} className={styles.buttonContainer}>
          <OutlineButton leftIcon={redAddIcon} text={'Envoyer une reco'} iconHeight={22}/>
        </Link>
      </div>
      <TabBar tabs={tabs} currentTab={currentTab} onTabChange={setCurrentTab} />
      <div className={styles.listHeader}>
        <div className={styles.filters}>
          <Filter
            name={'Statut'}
            options={[
              { label: 'En attente', value: RecommendationStatus.PENDING },
              { label: 'Acceptée', value: RecommendationStatus.ACCEPTED },
              { label: 'Refusée', value: RecommendationStatus.REFUSED },
              { label: 'Devis signé', value: RecommendationStatus.CONTRACT_SIGNED },
            ]}
            onChange={setStatuses}/>
          <Sort options={sortOptions} onChange={setSort}/>
        </div>
        {totalAmount !== null && <span className={styles.ca}>CA total {isSent ? 'envoyé' : 'généré'} :  <span className={styles.totalCa}>{totalAmount}€ HT</span></span>}
      </div>
      {isSent ? (
        !!orderedRecommendations?.givenRecommendations && <ul className={styles.recommendationsList}>
        {orderedRecommendations.givenRecommendations.map((recommendation) => (
          <RecommendationLine mode={RecommendationMode.GIVEN} key={recommendation.id} recommendation={recommendation} />
        ))}
      </ul>) : (
        !!orderedRecommendations?.receivedRecommendations && <ul className={styles.recommendationsList}>
          {orderedRecommendations.receivedRecommendations.map((recommendation) => (
            <RecommendationLine mode={RecommendationMode.RECEIVED} key={recommendation.id} recommendation={recommendation} />
          ))}
        </ul>
      )}
    </CenteredContainer>
  );
};

export default Recommendations;
