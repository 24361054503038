import { Component } from './index';

export class ParentNetworks extends Component {
  getAll(ignoreZone: boolean = false) {
    return this.requester.get(`/parent-networks?ignoreZone=${ignoreZone}`);
  }

  get(id: string) {
    return this.requester.get(`/parent-networks/${id}`);
  }

  getHome() {
    return this.requester.get(`/parent-networks/home`);
  }

  create(data: any) {
    return this.requester.post(`/parent-networks`, data);
  }
}
