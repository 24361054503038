import styles from './style.module.scss';
import React from 'react';
import { dataType, UserTicket, Event } from '../../../types';
import arrowRightBlack from '../../../assets/arrowRightBlack.svg';
import qrCodeIcon from '../../../assets/qrCodeIcon.svg';
import DateSquare from '../../utils/dateSquare';
import LogoSquare from '../../utils/logoSquare';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import Line from '../../list/line';
import api from '../../../services/api';
import FileDownload from 'js-file-download';

type Props = {
  event: Event,
  tickets: UserTicket[];
}
const TicketLine = ({ event, tickets }: Props) => {
  const { setData, setType } = useDetailsModalContext();
  const { setText } = useValidationModalContext();

  const { address } = event;

  const showEvent = () => {
    setData(event);
    setType(dataType.Event);
  };

  const downloadTickets = async () => {
    await Promise.all(tickets.map(async (t) => {
      const response = await api.tickets.download(t.id.toString());
      FileDownload(response.data, `billet-${event.title}-${t.name}.pdf`);
    }))
    setText('Vos billets ont bien été téléchargés');
  };

  return (
    <Line>
      <div className={styles.container}>
        <div className={styles.squaresContainer} onClick={showEvent}>
          <DateSquare date={event.start_date} className={styles.dateSquare} />
          <LogoSquare image={event.picture_url} />
        </div>
        <div className={styles.infosContainer} onClick={showEvent}>
          <span className={styles.name}>{event.title}</span>
          <span className={styles.address}>{address.number} {address.street}, {address.city}</span>
          <img src={arrowRightBlack} className={styles.arrowRight}/>
        </div>
        <div className={styles.qrCodeIconContainer}>
          <div className={styles.qrCodeContainer} onClick={downloadTickets}>
            <img src={qrCodeIcon} className={styles.qrCodeIcon}/>
            <span className={styles.ticketsNumber}>{tickets.length} Billets</span>
          </div>
        </div>
      </div>
    </Line>
  );
}

export default TicketLine;
