import { SubmitHandler, useForm } from 'react-hook-form';

import styles from './style.module.scss';
import IconedTitle from '../../../components/utils/iconedTitle';
import networkIcon from '../../../assets/networkIconColor.svg';
import { useAppDispatch } from '../../../store/hooks';
import TextInput from '../../../components/form/textInput';
import SectionTitle from '../../../components/form/sectionTitle';
import ColoredButton from '../../../components/buttons/coloredButton';
import { isValidAddress } from '../../../utils/misc';
import PlaceInput from '../../../components/form/placeInput';
import { Address, Network } from '../../../types';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import ImageInput from '../../../components/form/imageInput';
import CenteredContainer from '../../../components/containers/centeredContainer';
import { createNetwork, editNetwork, fetchNetwork } from '../../../store/slices/networks';
import WhiteButton from '../../../components/buttons/whiteButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

type Inputs = {
  name: string,
  banner_url: string,
  picture_url: string,
  logo_url: string,
  description: string,
  website: string,
  address: any,
  fees: any,
  members: string,
  requirements: any,
  worths: any,
  services: any,
  iban: string,
  contact_email: string,
  contact_phone: string,
  parent_network_id: number,
  tax_percent: number,
}

const CreateGroup = () => {
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const { setError } = useErrorModalContext();
  const { setText, setRedirect } = useValidationModalContext();
  const dispatch = useAppDispatch();
  const [ edit, setEdit ] = useState<number | null>(null);
  const [ loading, setLoading ] = useState(true);

  const { register, control, handleSubmit, formState: { errors }, reset } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log('data', data);
    try {
      const address: Address & { placeId: string } = data.address.address_components ? {
        id: 0,
        number: data.address.address_components.find((c: any) => c.types.includes('street_number'))?.long_name,
        street: data.address.address_components.find((c: any) => c.types.includes('route'))?.long_name,
        city: data.address.address_components.find((c: any) => c.types.includes('locality'))?.long_name,
        country: data.address.address_components.find((c: any) => c.types.includes('country'))?.long_name,
        zip_code: data.address.address_components.find((c: any) => c.types.includes('postal_code'))?.long_name,
        placeId: data.address.place_id,
      } : data.address;
      let newNetwork;
      if (edit) {
        const { network }: { network: Network } = await dispatch(editNetwork({
          id: edit,
          ...data,
          address,
        })).unwrap();
        newNetwork = network;
      } else {
        const { network }: { network: Network } = await dispatch(createNetwork({
          ...data,
          address,
        })).unwrap();
        newNetwork = network;
      }
      setRedirect(`/groups?id=${newNetwork.id}`);
      setText('Votre groupement a bien été enregistré !');
    } catch (err) {
      setError(`Votre groupement n\'a pas pu être enregistré: ${err}`);
    }
  }

  const _fetchNetwork = async () => {
    try {
      if (!edit) return;
      const result = await dispatch(fetchNetwork(edit.toString())).unwrap();
      setLoading(false);
      reset({
        banner_url: result.banner_url,
        picture_url: result.picture_url,
        name: result.name,
        description: result.description,
        address: result.address,
        contact_phone: result.contact_phone,
        contact_email: result.contact_email,
        logo_url: result.logo_url,
        website: result.website,
        parent_network_id: result.parent_network_id,
        members: result.members,
        tax_percent: result.tax_percent,
      });
    } catch (err) {
      console.error('Error fetching event', err);
    }
  }

  useEffect(() => {
    setLoading(true);
    _fetchNetwork();
  }, [ edit ]);

  useEffect(() => {
    if (searchParams.get('id')) {
      setEdit(parseInt(searchParams.get('id') as string));
    } else {
      setLoading(false);
    }
  }, [ searchParams ]);

  return (
    <CenteredContainer>
      <div className={styles.centeredWrapper}>
        <div className={styles.header}>
          <IconedTitle title={'Créer un groupement'} icon={networkIcon} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <ImageInput aspectRatio={2} options={{ required: true }} name={'banner_url'} placeholder={'Bannière'} control={control} errors={errors} />
          <ImageInput options={{ required: true }} name={'picture_url'} placeholder={'Photo principale'} control={control} errors={errors} />
          <SectionTitle title={'Informations du groupement'}/>
          <div className={styles.nameRow}>
            <div className={styles.logo}>
              <ImageInput options={{ required: true }} name={'logo_url'} placeholder={'Logo'} control={control} errors={errors} />
            </div>
            <div className={styles.nameFields}>
              <TextInput name={'parent_network_id'} register={register} placeholder={'Id du réseau mère'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, valueAsNumber: true }}/>
              <TextInput name={'name'} register={register} placeholder={'Nom du groupement'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins 3 caractères' }}}/>
            </div>
          </div>
          <WhiteButton text={'Créer un groupement mère'} onClick={() => navigate('/groups/createParent')} className={styles.createParent}/>
          <SectionTitle title={'Description du groupement'}/>
          <TextInput name={'description'} register={register} placeholder={'Description du groupement'} multiline={true} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins 30 caractères' }}}/>
          <SectionTitle title={'Informations'}/>
          <PlaceInput errors={errors} control={control} options={{ required: true, validate: isValidAddress }} name={'address'} placeholder={'Siège social'}/>
          <TextInput errors={errors} register={register} options={{ required: true }} name={'website'} placeholder={'Site web'}/>
          <TextInput errors={errors} register={register} options={{ required: true, valueAsNumber: true, validate: (v) => (v > 0 || 'Champ invalide') }} name={'members'} placeholder={'Nombre d\'adhérents'}/>
          <TextInput errors={errors} register={register} options={{ required: true }} name={'contact_email'} placeholder={'Email de contact'}/>
          <TextInput errors={errors} register={register} options={{ required: true }} name={'contact_phone'} placeholder={'Téléphone de contact'}/>
          <TextInput errors={errors} register={register} options={{ required: true, valueAsNumber: true }} name={'tax_percent'} placeholder={'TVA (%)'}/>
          <ColoredButton text={edit ? 'Modifier le groupement' : 'Créer le groupement'} type={'submit'} className={styles.submitButton}/>
        </form>
      </div>
    </CenteredContainer>
  );
};

export default CreateGroup;
