import styles from './style.module.scss';
import { useSearchParams } from 'react-router-dom';
import Carousel  from '../../components/home/carousel';
import eventIconColor from '../../assets/eventsIconColor.svg';
import { CityGroup, dataType, Event, NetworkType } from '../../types';
import moment from 'moment';
import EventBanner from '../../components/events/eventBanner';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchEventClasses } from '../../store/slices/events';
import { useDetailsModalContext } from '../../contexts/DetailsModalContext';
import { usePublicPage } from '../../utils/hooks';
import api from '../../services/api';
import Categorize from '../../components/options/categorize';
import Filter from '../../components/options/filter';
import Loader from '../../components/options/loader';
import Search from '../../components/options/search';
import SphereHeader from '../../components/othrs/sphereHeader';

const Events = () => {
  const publicPage = usePublicPage();
  const { setData, setType } = useDetailsModalContext();
  let [ searchParams ] = useSearchParams();
  const dispatch = useAppDispatch();

  const [ classes, setClasses ] = useState<{ name: string, events: Event[] }[] | null>(null);
  const [ banner, setBanner ] = useState<Event | null>(null);
  const classesLoading = useAppSelector(state => state.events.eventPending);
  const [ cityGroupIds, setCityGroupIds ] = useState<string[]>([]);
  const [ typeIds, setTypeIds ] = useState<string[]>([]);

  const [ cityGroups, setCityGroups ] = useState<CityGroup[] | null>(null);
  const [ types, setTypes ] = useState<NetworkType[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ classBy, setClassBy ] = useState<string>('month');


  const fetchData = async () => {
    try {
      if (publicPage) return;
      const result = await dispatch(fetchEventClasses({ cityGroupIds, typeIds, search, classBy })).unwrap();
      setClasses(result.classes);
      setBanner(result.banner);
      const { data: types } = await api.misc.getNetworkTypes();
      setTypes(types);
      const { data: cityGroups } = await api.misc.getCityGroups();
      setCityGroups(cityGroups);
    } catch (err) {
      console.error('Error fetching events home', err);
    }
  }

  const showEvent = (eventId: number) => {
    setType(dataType.Event);
    setData({ id: eventId });
  };

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      showEvent(parseInt(id));
    }
  }, [ searchParams ]);

  useEffect(() => {
    fetchData();
  }, [ cityGroupIds, typeIds, search, classBy ]);

  if (!classes && !publicPage) return null;

  return publicPage ? <div className={styles.container}/> : (
      <div className={styles.container}>
        {!!banner && <div className={styles.centeredWrapper}>
          <EventBanner event={banner} />
        </div>}
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <img src={eventIconColor} alt={'event'} className={styles.eventIcon}/>
            <h2 className={styles.title}>Events</h2>
          </div>
          <div className={styles.filtersContainer}>
            {/*<Categorize options={[{ label: 'Mois', value: 'month' }, { label: 'Localité', value: 'cityGroup' }]} onChange={setClassBy} />*/}
            {!!cityGroups && <Filter name={'Localisation'} options={cityGroups.map(cg => ({ label: cg.name, value: cg.id.toString() }))} onChange={setCityGroupIds}/>}
            {!!types && <Filter name={'Organisateur'} options={types.map(sphere => ({ label: sphere.name, value: sphere.id.toString() }))} onChange={setTypeIds}/>}
          </div>
          <div className={styles.searchContainer}>
            {classesLoading && <Loader />}
            <Search onChange={setSearch}/>
          </div>
        </div>
        {!!classes && classes.map((c) => (
          <div key={c.name} className={styles.monthContainer}>
            <SphereHeader sphere={c.name}/>
            <Carousel type={dataType.Event} data={c.events}/>
          </div>
        ))}
        {!classes || !classes.length && <div className={styles.placeholder}/>}
      </div>
  );
};

export default Events;
