import styles from './style.module.scss';

import networksOpenIconColor from '../../../assets/networksOpenIconColor.svg';
import eventsIconColor from '../../../assets/eventsIconColor.svg';
import othrsIconColor from '../../../assets/othrsIconColor.svg';
import arrowRight from '../../../assets/arrowRight.svg';
import { Link } from 'react-router-dom';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import NetworkCard from '../../cards/networkCard';
import EventCard from '../../cards/eventCard';
import OthrCard from '../../cards/othrCard';
import MediumCard from '../../cards/mediumCard';
import { dataType } from '../../../types';

const datas = {
  [dataType.Network]: {
    Card: NetworkCard,
    icon: networksOpenIconColor,
  },
  [dataType.Event]: {
    Card: EventCard,
    icon: eventsIconColor,
  },
  [dataType.Othr]: {
    Card: OthrCard,
    icon: othrsIconColor,
  },
  [dataType.Medium]: {
    Card: MediumCard,
    icon: othrsIconColor,
  },
}

interface Props {
  title?: string,
  type: Exclude<dataType, dataType.Recommendation | dataType.Podcast | dataType.Article | dataType.Video>,
  data: any[],
  path?: string,
  cta?: string,
}

const Carousel = ({ title, data, path, type, cta }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [ rightArrow, setRightArrow ] = useState(false);
  const [ leftArrow, setLeftArrow ] = useState(false);
  const [ width, setWidth ] = useState(0);
  const [ showCta, setShowCta ] = useState(false);

  useLayoutEffect(() => {
    const div = containerRef.current;
    if (!div) return;
    setWidth(div.clientWidth);
    if (div.clientWidth < div.scrollWidth) {
      setRightArrow(true);
    }
    div.addEventListener('scroll', () => {
      const isScrollableRight = (div.scrollLeft + div.clientWidth) < div.scrollWidth;
      setRightArrow(isScrollableRight);
      const isScrollableLeft = (div.scrollLeft > 0);
      setLeftArrow(isScrollableLeft);
    })
  }, [ containerRef ]);

  const scrollRight = () => {
    if (!containerRef.current) return;
    containerRef.current?.scrollTo(containerRef.current?.scrollLeft + width, 0);
  }

  const scrollLeft = () => {
    if (!containerRef.current) return;
    containerRef.current?.scrollTo((containerRef.current?.scrollLeft - width), 0);
  }

  const { Card, icon } = useMemo(() => {
    return datas[type];
  }, [ type ]);

  return (
    <div className={styles.container}>
      {!!title && <Link to={path || '/'} className={styles.titleContainer} onMouseEnter={() => setShowCta(true)} onMouseLeave={() => setShowCta(false)}>
        {!!icon && <img src={icon} alt={`${type}-icon`} className={styles.icon}/>}
        <span className={styles.title}>{title}</span>
        <img src={arrowRight} alt={`arrow-right`} className={styles.arrowRight}/>
        {showCta && <span className={styles.cta}>{cta}</span>}
      </Link>}
      <div className={styles.carouselContainer}>
        {leftArrow && <div className={styles.leftArrowContainer}>
          <img src={arrowRight} alt={`arrow-left`} className={styles.leftArrow} onClick={scrollLeft}/>
        </div>}
        {!!Card && <div className={styles.carousel} ref={containerRef}>
          {data.map((d) => (
            <div key={d.id} className={styles.cardWrapper}>
              <Card data={d}/>
            </div>
          ))}
        </div>}
        {rightArrow && <div className={styles.rightArrowContainer}>
          <img src={arrowRight} alt={`arrow-right`} className={styles.rightArrow} onClick={scrollRight}/>
        </div>}
      </div>
    </div>
  )
};

export default Carousel;
