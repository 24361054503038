import styles from './style.module.scss';
import React from 'react';
import classNames from 'classnames';

interface Props {
  text: string;
  onClick?: () => void;
  className?: string;
  leftIcon?: string;
  iconHeight?: number;
};

function OutlineButton({ text, onClick, className, leftIcon, iconHeight }: Props) {
  return (
    <span className={classNames(styles.container, className)} onClick={() => {
      if (onClick) onClick();
    }}>
      {!!leftIcon && <img src={leftIcon} alt={'buttonIcon'} className={styles.icon} style={{ height: iconHeight }}/>}
      <p className={styles.text}>{text}</p>
    </span>
  )
};

export default OutlineButton;
