import styles from './style.module.scss';
import React, { useState } from 'react';
import { dataType, SwapRequest, SwapRequestStatus } from '../../../types';
import moment from '../../../utils/moment';
import arrowRightBlack from '../../../assets/arrowRightBlack.svg';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import WhiteButton from '../../buttons/whiteButton';
import messageIcon from '../../../assets/messageIcon.svg';
import refuseIcon from '../../../assets/refuseIcon.svg';
import acceptIcon from '../../../assets/acceptIcon.svg';
import WhiteIconButton from '../../buttons/whiteIconButton';
import SwapRequestModal, { SwapRequestModalMode } from '../../modals/swapRequestModal';

export enum SwapRequestMode {
  RECEIVED = 'received',
  GIVEN = 'given',
}
type Props = {
  swapRequest: SwapRequest;
  mode: SwapRequestMode;
}
const SwapRequestLine = ({ swapRequest, mode }: Props) => {
  const { setData, setType } = useDetailsModalContext();
  const [ swapRequestModal, setSwapRequestModal ] = useState<boolean>(false);
  const [ modalMode, setModalMode ] = useState<SwapRequestModalMode>(SwapRequestModalMode.ACCEPT);

  const showMessage = () => {
    setModalMode(mode === SwapRequestMode.RECEIVED ? SwapRequestModalMode.MESSAGE_RECEIVED :  SwapRequestModalMode.MESSAGE_SENT);
    setSwapRequestModal(true);
  }

  const showCoordinates = () => {
    setModalMode(SwapRequestModalMode.COORDINATES);
    setSwapRequestModal(true);
  }

  const showAccept = () => {
    setModalMode(SwapRequestModalMode.ACCEPT);
    setSwapRequestModal(true);
  }

  const showRefuse = () => {
    setModalMode(SwapRequestModalMode.REFUSE);
    setSwapRequestModal(true);
  }

  const showUser = () => {
    setData(swapRequest[mode === SwapRequestMode.RECEIVED ? 'author' : 'requested']);
    setType(dataType.Othr);
  }

  return (
    <div className={styles.container}>
      <div className={styles.infosContainer} onClick={showUser}>
        <img src={swapRequest[mode === SwapRequestMode.RECEIVED ? 'author' : 'requested'].picture_url} className={styles.image}/>
        <div className={styles.textsContainer}>
          <span className={styles.title}>{swapRequest[mode === SwapRequestMode.RECEIVED ? 'author' : 'requested'].firstname} {swapRequest[mode === SwapRequestMode.RECEIVED ? 'author' : 'requested'].lastname}</span>
          <span className={styles.name}>{swapRequest[mode === SwapRequestMode.RECEIVED ? 'author' : 'requested'].job}</span>
          <span className={styles.updatedOn}>{mode === SwapRequestMode.RECEIVED ? 'Reçue' : 'Envoyée'} {moment(swapRequest.updatedAt).fromNow()}</span>
        </div>
        <img src={arrowRightBlack} className={styles.arrowRight} />
      </div>
      <div className={styles.swapRequestContainer}>
        <div className={styles.seeSwapRequestContainer}>
          <WhiteIconButton legend={'Demande'} icon={messageIcon} onClick={showMessage}/>
        </div>
        {mode === SwapRequestMode.GIVEN ? <div className={styles.seeCoordinatesContainer}>
          <WhiteButton text={'Coordonnées'} greenLegend={swapRequest.status === SwapRequestStatus.Accepted} legend={swapRequest.status === SwapRequestStatus.Pending ? 'En attente d\'acceptation' : 'Demande acceptée'} onClick={showCoordinates} disabled={swapRequest.status === SwapRequestStatus.Pending}/>
        </div> : <div className={styles.respondContainer}>
          <WhiteIconButton legend={'Accepter'} icon={acceptIcon} onClick={showAccept}/>
          <WhiteIconButton legend={'Refuser'} icon={refuseIcon} onClick={showRefuse} className={styles.rightButton}/>
        </div>}
      </div>
      <SwapRequestModal mode={modalMode} open={swapRequestModal} onClose={() => setSwapRequestModal(false)} swapRequest={swapRequest}/>
    </div>
  );
}

export default SwapRequestLine;
