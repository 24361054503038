import styles from './style.module.scss';
import React, { useMemo } from 'react';
import DateSquare from '../../utils/dateSquare';
import LogoSquare from '../../utils/logoSquare';
import CityText from '../../utils/cityText';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, Event } from '../../../types';
import OthrSquare from '../../utils/othrSquare';

interface Props {
  data: Event;
  onClick?: (e: React.MouseEvent) => void;
};

function EventCard({ data, onClick }: Props) {
  const { setData, setType } = useDetailsModalContext();

  const showEvent = () => {
    setType(dataType.Event);
    setData(data);
  };

  const organizerLogo = useMemo(() => {
    if (data.user) return data.user.logo_url || data.user.picture_url;
    else if (data.network) return data.network?.logo_url || data.network?.logo_url;
    else return null;
  }, [ data ]);

  const takenPlaces = useMemo(() => {
    if (!data) return 0;
    return data.user_tickets?.length || 0;
  }, [ data ]);
  console.log(takenPlaces, data.total_places * .3);

  return (
    <div className={styles.container} onClick={onClick || showEvent}>
      <img src={data.picture_url} alt={'event'} className={styles.imageBg}/>
      <div className={styles.shadow}/>
      <div className={styles.infosContainer}>
        <div className={styles.squaresContainer}>
          <DateSquare date={data.start_date}/>
          {organizerLogo ? <LogoSquare image={organizerLogo}/> : <OthrSquare />}
        </div>
        <h2 className={styles.title}>{data.title}</h2>
        <CityText city={data.address.city} size={'small'} />
        <span className={styles.participants}>Jusqu'à {data.total_places} personnes</span>
      </div>
    </div>
  )
};

export default EventCard;
