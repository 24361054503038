import styles from './style.module.scss';
import CenteredContainer from '../../components/containers/centeredContainer';
import IconedTitle from '../../components/utils/iconedTitle';
import notificationsIcon from '../../assets/notificationsIconColor.svg';
import { Notification } from '../../types';
import List from '../../components/list';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import api from '../../services/api';
import NotificationLine from '../../components/notifications/notificationLine';

const Notifications = () => {
  const [ notifications, setNotification ] = useState<Notification[] | null>(null);

  const fetchData = async () => {
    try {
      const result = await api.profile.getNotifications(true);
      setNotification(result.data);
    } catch (err) {
      console.error('Error fetching notifications', err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CenteredContainer>
      <div className={styles.header}>
        <IconedTitle title={'Notifications'} icon={notificationsIcon} />
      </div>
      {!!notifications && <List>
        {notifications.map((notification: Notification) => (
          <NotificationLine key={notification.id} notification={notification} />
        ))}
      </List>}
    </CenteredContainer>
  );
};

export default Notifications;
