import Modal from 'react-modal';

import styles from './style.module.scss';
import ColoredButton from '../../buttons/coloredButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { acceptRecommendation, refuseRecommendation } from '../../../store/slices/recommendations';
import TextInput from '../../form/textInput';
import { contactUs } from '../../../store/slices/account';
import SelectInput from '../../form/selectInput';
import UnderlineTitle from '../../utils/underlineTitle';
import closeModalIcon from '../../../assets/closeModal.svg';

interface Props {
  open: boolean,
  onClose: () => void,
  defaultObject?: string,
}
type Inputs = {
  message: string;
  object: string;
}

const ContactUsModal = ({ open, onClose, defaultObject }: Props) => {
  const { text, setText } = useValidationModalContext();
  const { setError } = useErrorModalContext();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.account.contactUsPending);

  const { register, handleSubmit, formState: { errors }, control, reset } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    },
    defaultValues: {
      object: defaultObject,
    }
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      await dispatch(contactUs({ message: data.message, object: data.object }));
      setText('Votre message a bien été envoyé');
      reset();
      onClose();
    } catch (err) {
      setError('Votre message n\'a pas pu être envoyé');
    }
  }

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={open}
      onRequestClose={onClose}
    >
      <div className={styles.container}>
        <span onClick={onClose} className={styles.closeModalContainer}>
          <img src={closeModalIcon} alt={'close modal'} className={styles.closeModal}/>
        </span>
        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <SelectInput
              options={{}}
              name={'object'}
              control={control}
              stringOptions={[
                'Signaler un problème',
                'Poser une question',
                'Supprimer mon compte',
                'Suppression des données personnelles',
                'Autre',
              ]}
              placeholder={'Objet de votre message'}
            />
            <TextInput
              placeholder={`Votre message`}
              register={register}
              options={{ required: true, minLength: { value: 10, message: 'Au moins 10 caractères' } }}
              errors={errors}
              name={'message'}
              multiline={true}
            />
            <ColoredButton loading={loading} text={'Envoyer'} type={'submit'}/>
          </form>
        </div>
      </div>
    </Modal>
  )
};

export default ContactUsModal;
