import { Component } from './index';

export class Iap extends Component {
  subscribe(orderId: string, transactionReceipt: string, userId: string) {
    return this.requester.post('/iap/subscription', {
      orderId,
      transactionReceipt,
      userId,
   });
  }
}
