import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';

export interface RecommendationsState {
  recommendationLoading: boolean;
};

const initialState: RecommendationsState = {
  recommendationLoading: false,
};

export const fetchRecommendation = createAsyncThunk('recommendations/fetchRecommendation',
  async (id: string) => {
    const data = await api.recommendations.get(id);
    return (data.data);
  },
);

export const fetchGivenRecommendations = createAsyncThunk('recommendations/fetchGivenRecommendations',
  async () => {
    const data = await api.recommendations.getGiven();
    return (data.data);
  },
);

export const fetchReceivedRecommendations = createAsyncThunk('recommendations/fetchReceivedRecommendations',
  async () => {
    const data = await api.recommendations.getReceived();
    return (data.data);
  },
);

export const acceptRecommendation = createAsyncThunk('recommendations/accept',
  async ({ recommendationId, message }: { recommendationId: string, message: string }) => {
    const data = await api.recommendations.accept(recommendationId, message);
    return (data.data);
  },
);

export const refuseRecommendation = createAsyncThunk('recommendations/refuse',
  async ({ recommendationId, message }: { recommendationId: string, message: string }) => {
    const data = await api.recommendations.refuse(recommendationId, message);
    return (data.data);
  },
);

export const signedRecommendation = createAsyncThunk('recommendations/signed',
  async ({ recommendationId, amount }: { recommendationId: string, amount: number }) => {
    const data = await api.recommendations.contractSigned(recommendationId, amount);
    return (data.data);
  },
);

interface SendRecommendationPayload {
  title: string,
  description: string,
  client_name: string,
  client_phone: string,
  client_email: string,
  expected_action: string,
  recommended_user_id: string,
}
export const sendRecommendation = createAsyncThunk('recommendations/sendRecommendation',
  async (data: SendRecommendationPayload) => {
    const result = await api.recommendations.create(data);
    return (result.data);
  },
);

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(sendRecommendation.pending, (state: RecommendationsState) => {
      state.recommendationLoading = true;
    });
    builder.addCase(sendRecommendation.fulfilled, (state: RecommendationsState) => {
      state.recommendationLoading = false;
    });
    builder.addCase(sendRecommendation.rejected, (state: RecommendationsState) => {
      state.recommendationLoading = false;
    });
    builder.addCase(acceptRecommendation.pending, (state: RecommendationsState) => {
      state.recommendationLoading = true;
    });
    builder.addCase(acceptRecommendation.fulfilled, (state: RecommendationsState) => {
      state.recommendationLoading = false;
    });
    builder.addCase(acceptRecommendation.rejected, (state: RecommendationsState) => {
      state.recommendationLoading = false;
    });
    builder.addCase(refuseRecommendation.pending, (state: RecommendationsState) => {
      state.recommendationLoading = true;
    });
    builder.addCase(refuseRecommendation.fulfilled, (state: RecommendationsState) => {
      state.recommendationLoading = false;
    });
    builder.addCase(refuseRecommendation.rejected, (state: RecommendationsState) => {
      state.recommendationLoading = false;
    });
  },
});

export const {
} = recommendationsSlice.actions;

export default recommendationsSlice.reducer;
