import styles from './style.module.scss';
import Carousel  from '../../components/home/carousel';
import magazineIcon from '../../assets/magazineIcon.svg';
import { dataType, Event, Medium, Sphere } from '../../types';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useSearchParams } from 'react-router-dom';
import { useDetailsModalContext } from '../../contexts/DetailsModalContext';
import { usePublicPage } from '../../utils/hooks';
import { fetchMediaClasses } from '../../store/slices/media';
import { parseMedium } from '../../utils/misc';
import MediumBanner from '../../components/media/mediumBanner';
import SphereHeader from '../../components/othrs/sphereHeader';
import Categorize from '../../components/options/categorize';
import Filter from '../../components/options/filter';
import Loader from '../../components/options/loader';
import Search from '../../components/options/search';
import Sort from '../../components/options/sort';
import api from '../../services/api';

const mediumTypesOptions = [
  { label: 'Articles', value: 'article' },
  { label: 'Podcasts', value: 'podcast' },
  { label: 'Videos', value: 'video' },
];

const sortOptions = [
  { label: 'Le plus récent', value: 'createdAt' },
  { label: 'Les plus pertinents', value: 'likes' },
  { label: 'Le plus d\'audience', value: 'views' },
];

const Media = () => {
  const publicPage = usePublicPage();
  let [ searchParams ] = useSearchParams();
  const { setData, setType } = useDetailsModalContext();
  const dispatch = useAppDispatch();

  const [ classes, setClasses ] = useState<{ name: string, media: Medium[] }[] | null>(null);
  const [ banner, setBanner ] = useState<Medium[] | null>(null);
  const classesLoading = useAppSelector(state => state.media.classesLoading);
  const [ mediumTypes, setMediumTypes ] = useState<string[]>([]);
  const [ sphereIds, setSphereIds ] = useState<string[]>([]);

  const [ spheres, setSpheres ] = useState<Sphere[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ classBy, setClassBy ] = useState<string>('mediumType');
  const [ sort, setSort ] = useState<string>('createdAt');

  const fetchData = async () => {
    try {
      if (publicPage) return;
      const data = await dispatch(fetchMediaClasses({ sphereIds, mediumTypes, search, classBy, sort })).unwrap();
      setClasses(data.classes);
      setBanner(data.banner.map(parseMedium));
      const { data: spheres } = await api.misc.getSpheres();
      setSpheres(spheres);
    } catch (err) {
      console.error('Error fetching users home', err);
    }
  };

  const showMedium = (mediumId: number) => {
    setType(dataType.Medium);
    setData({ id: mediumId });
  };

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      showMedium(parseInt(id));
    }
  }, [ searchParams ]);

  useEffect(() => {
    fetchData();
  }, [ sphereIds, mediumTypes, search, classBy, sort ]);

  if (!classes && !publicPage) return null;
  return publicPage ? <div className={styles.container}/> : (
    <div className={styles.container}>
      {!!banner && banner.length > 0 && <div className={styles.centeredWrapper}>
        <MediumBanner media={banner}/>
      </div>}
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <img src={magazineIcon} alt={'magazine'} className={styles.eventIcon}/>
          <h2 className={styles.title}>Magazine</h2>
        </div>
        <div className={styles.filtersContainer}>
          <Categorize options={[{ label: 'Type', value: 'mediumType' }, { label: 'Tops', value: 'tops' }]} onChange={setClassBy} />
          {!!spheres && <Filter name={'Sphère'} options={spheres.map(cg => ({ label: cg.name, value: cg.id.toString() }))} onChange={setSphereIds}/>}
          <Filter name={'Type'} options={mediumTypesOptions} onChange={setMediumTypes}/>
          <Sort options={sortOptions} onChange={setSort} />
        </div>
        <div className={styles.searchContainer}>
          {classesLoading && <Loader />}
          <Search onChange={setSearch}/>
        </div>
      </div>
      {!!classes && classes.map((c) => (
        <div key={c.name} className={styles.monthContainer}>
          <SphereHeader sphere={c.name}/>
          <Carousel type={dataType.Medium} data={c.media.map(parseMedium)}/>
        </div>
      ))}
      {!classes || !classes.length && <div className={styles.placeholder}/>}
    </div>
  );
};

export default Media;
