import { Component } from './index';

export class Misc extends Component {
  getSpheres() {
    return this.requester.get('/misc/spheres');
  }

  getZones() {
    return this.requester.get('/misc/zones');
  }

  getCityGroups() {
    return this.requester.get('/misc/city-groups');
  }

  getOpenZones() {
    return this.requester.get('/misc/zones?open=true');
  }

  getNetworkTypes() {
    return this.requester.get('/misc/types');
  }

  getHome() {
    console.log('requester', this.requester.defaults.headers.common['authorization'])
    return this.requester.get('/misc/home');
  }

  report(data: any) {
    return this.requester.post('/misc/report', data);
  }
}
