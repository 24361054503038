import { Component } from './index';

export interface MediaClassesOptions {
  mediumTypes?: string[],
  sphereIds?: string[],
  search?: string,
  classBy?: string,
  sort?: string,
}

export class Media extends Component {
  getMedia(type: string) {
    return this.requester.get(`/media?type=${type}`);
  }

  getHome() {
    return this.requester.get(`/media/home`);
  }

  getClasses(options: MediaClassesOptions) {
    const params = new URLSearchParams();
    if (options.sphereIds?.length) {
      params.append('sphereIds', options.sphereIds.join(','));
    }
    if (options.mediumTypes?.length) {
      params.append('mediumTypes', options.mediumTypes.join(','));
    }
    if (options.search?.length) {
      params.append('search', options.search);
    }
    if (options.sort) {
      params.append('sort', options.sort);
    }
    params.append('classBy', options.classBy || 'mediumType');
    return this.requester.get(`/media/classes?${params.toString()}`);
  }

  getMedium(id: string, publicPage: boolean) {
    if (publicPage) {
      return this.requester.get(`/media/${id}/public`);
    }
    return this.requester.get(`/media/${id}`);
  }

  createMedium(data: any) {
    return this.requester.post(`/media`, data);
  }

  updateMedium(id: string, data: any) {
    return this.requester.patch(`/media/${id}`, data);
  }

  deleteMedium(id: string) {
    return this.requester.delete(`/media/${id}`);
  }

  likeMedium(id: string) {
    return this.requester.post(`/media/${id}/like`);
  }

  getMyMedia() {
    return this.requester.get(`/media?me=true`);
  }

  getMyArticles() {
    return this.requester.get(`/media?me=true&type=article`);
  }

  getMyPodcasts() {
    return this.requester.get(`/media?me=true&type=podcast`);
  }

  getMyVideos() {
    return this.requester.get(`/media?me=true&type=video`);
  }

  startUpload(contentType: string) {
    return this.requester.post(`/media/start-upload`, {
      contentType,
    });
  }

  partUpload(fileName: string, partNumber: number, uploadId: string) {
    return this.requester.post(`/media/part-upload`, {
      fileName,
      partNumber,
      uploadId,
    });
  }

  completeUpload(fileName: string, parts: { ETag: string, PartNumber: number }[], uploadId: string) {
    return this.requester.post(`/media/complete-upload`, {
      fileName,
      parts,
      uploadId,
    });
  }
}
