import styles from './style.module.scss';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import playIcon from '../../../assets/playIcon.svg';
import pauseIcon from '../../../assets/pauseIcon.svg';
import backTrack from '../../../assets/backTrack.svg';
import forwardTrack from '../../../assets/forwardTrack.svg';
import volumeIcon from '../../../assets/volumeIcon.svg';
import { usePodcastContext } from '../../../contexts/PodcastContext';

interface Props {
  url: string,
  name: string,
  id: number,
};

interface ControlsProps {
  isPlaying: boolean,
  addTime: (value: number) => void,
  togglePlay: () => void,
  speed: number,
  upgradeSpeed: () => void,
  audioRef: MutableRefObject<HTMLAudioElement | null>,
}
const Controls = ({ isPlaying, addTime, togglePlay, speed, upgradeSpeed, audioRef }: ControlsProps) => {
  if (!audioRef.current) return null;
  return (
    <div className={styles.controls}>
      <div className={styles.speed} onClick={upgradeSpeed}>
        <span>{speed.toFixed(speed === 1 ? 1 : 2)}</span>
        <span className={styles.speedLegend}>Vitesse</span>
      </div>
      <div className={styles.centerControls}>
        <div className={styles.backTrackContainer} onClick={() => addTime(-15)}>
          <img src={backTrack} className={styles.backTrack}/>
          <span className={styles.backTrackText}>15</span>
        </div>
        <div className={styles.playButton} onClick={togglePlay}>
          <img src={isPlaying ? pauseIcon : playIcon} className={isPlaying ? styles.pauseIcon : styles.playIcon}/>
        </div>
        <div className={styles.backTrackContainer} onClick={() => addTime(15)}>
          <img src={forwardTrack} className={styles.backTrack}/>
          <span className={styles.backTrackText}>15</span>
        </div>
      </div>
      <div className={styles.volumeContainer}>
        <img src={volumeIcon} className={styles.volumeIcon}/>
        <input type={'range'} min={'0'} max={'100'} value={audioRef.current!.volume * 100} onChange={(e) => audioRef.current!.volume = parseInt(e.target.value) / 100}/>
      </div>
    </div>
  )
}

const AudioPlayer = ({ url, name, id }: Props) => {
  const { ref: audioRef, setUrl, setOnEnd, setOnLoadedMetadata, setName, setId } = usePodcastContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const progressBarRef = useRef<HTMLInputElement | null>(null);
  const [ duration, setDuration ] = useState(0);
  const playAnimationRef = useRef<number>();
  const [ timeProgress, setTimeProgress ] = useState(0)
  const [ speed, setSpeed ] = useState(1);

  const upgradeSpeed = () => {
    const newSpeed = speed >= 1.5 ? .5 : speed + .25;
    setSpeed(newSpeed);
    audioRef.current!.playbackRate = newSpeed;
  };

  const addTime = (value: number) => {
    if (!audioRef.current) return;
    audioRef.current!.currentTime += value;
  };

  const handleEnd = () => {
    if (!audioRef.current) return;
    audioRef.current!.currentTime = 0;
    setTimeProgress(0);
    setIsPlaying(false);
    setUrl(null);
    setId(null);
    setName('');
    if (progressBarRef.current) {
      progressBarRef.current!.value = '0';
      progressBarRef.current!.style.setProperty(
        '--range-progress',
        `0%`
      );
    }
  };

  const repeat = useCallback(() => {
    if (!audioRef.current || !progressBarRef.current || !playAnimationRef.current) return;
    const currentTime = audioRef.current!.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current!.value = currentTime.toString();
    progressBarRef.current!.style.setProperty(
      '--range-progress',
      `${(parseInt(progressBarRef.current!.value) / duration) * 100}%`,
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef.current, duration, progressBarRef.current]);

  const onLoadedMetadata = () => {
    if (!audioRef.current) return;
    if (progressBarRef.current) {
      progressBarRef.current!.style.setProperty(
        '--range-progress',
        `0%`
      );
      progressBarRef.current!.max = Math.round(audioRef.current!.duration).toString();
    }
    setDuration(Math.round(audioRef.current!.duration));
  };

  const togglePlay = () => {
    if (!audioRef.current) return;
    if (audioRef.current!.paused) {
      audioRef.current!.play();
    } else {
      audioRef.current!.pause();
    }
  }

  useEffect(() => {
    if (!audioRef.current) return;
    setIsPlaying(!(audioRef.current!.paused));
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [ audioRef.current?.paused ]);

  const handleProgressBarChange = () => {
    if (!audioRef.current) return;
    audioRef.current!.currentTime = parseInt(progressBarRef.current!.value);
  }

  const ProgressBar = () => {
    return (
      <div className="progress">
        <input
          max={duration.toString()}
          ref={progressBarRef}
          type="range"
          defaultValue={'0'}
          onChange={handleProgressBarChange}
        />
        <div className={styles.times}>
          <span className={styles.time}>{Math.round(timeProgress / 60).toLocaleString('en-US', {minimumIntegerDigits: 2, maximumFractionDigits: 0, useGrouping:false})}:{(timeProgress % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, maximumFractionDigits: 0, useGrouping:false})}</span>
          <span className={styles.time}>{Math.round(duration / 60).toLocaleString('en-US', {minimumIntegerDigits: 2, maximumFractionDigits: 0, useGrouping:false})}:{(duration % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, maximumFractionDigits: 0, useGrouping:false})}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setUrl(url);
    setName(name);
    setId(id);
    setOnEnd(() => handleEnd);
    setOnLoadedMetadata(() => onLoadedMetadata);
  }, [ url ]);

  useEffect(() => {
    if (!audioRef.current) return;
    setTimeProgress(audioRef.current!.currentTime);
    setDuration(audioRef.current!.duration);
    setSpeed(audioRef.current!.playbackRate);
  }, [ url, audioRef.current ]);

  return (
    <div className={styles.container}>
      <ProgressBar />
      <Controls audioRef={audioRef} isPlaying={isPlaying} addTime={addTime} togglePlay={togglePlay} speed={speed} upgradeSpeed={upgradeSpeed} />
    </div>
  )
}

export default AudioPlayer;
