import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';

export const usePublicPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.account.user);
  const publicPage = location.pathname.includes('/public')
  console.log('publicPage', publicPage);
  if (publicPage && user) {
    console.log('redirect to', location.pathname.replace('/public', '') + location.search)
    navigate(location.pathname.replace('/public', '') + location.search);
  }
  return publicPage;

}
