import styles from './style.module.scss';

import LogoSquare from '../../utils/logoSquare';
import { dataType, Network } from '../../../types';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import React from 'react';
import ColoredButton from '../../buttons/coloredButton';

const NetworkBanner = ({ network }: { network: Network }) => {
  const { setType, setData } = useDetailsModalContext();

  const showNetwork = () => {
    setData(network);
    setType(dataType.Network);
  };

  return (
    <div className={styles.container}>
      <img src={network.banner_url || network.picture_url} className={styles.image} alt={'bg-network'} />
      <div className={styles.shadow} />
      <div className={styles.infosContainer}>
        <LogoSquare className={styles.logo} image={network.logo_url}/>
        <h2 className={styles.title}>{network.name}</h2>
        <p className={styles.description}>{network.description}</p>
        <div className={styles.buttonContainer}>
          <ColoredButton text={'Découvrir'} onClick={showNetwork} />
        </div>
      </div>
    </div>
  )
};

export default NetworkBanner;
