import Modal from 'react-modal';

import styles from './style.module.scss';
import closeModalIcon from '../../../../assets/closeModal.svg';
import LogoSquare from '../../../utils/logoSquare';
import { Network } from '../../../../types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useValidationModalContext } from '../../../../contexts/ValidationModalContext';
import TextInput from '../../../form/textInput';
import classNames from 'classnames';
import ColoredButton from '../../../buttons/coloredButton';
import { useEffect, useMemo } from 'react';
import ValidationModal from '../../validationModal';
import { useErrorModalContext } from '../../../../contexts/ErrorModalContext';
import { useAppDispatch } from '../../../../store/hooks';
import { sendNetworkAdhesionRequest, sendNetworkMessage } from '../../../../store/slices/networks';

type Inputs = {
  message: string;
}

export enum NetworkJoinModalMode {
  ADHESION,
  INTERN,
}
interface Props {
  mode: NetworkJoinModalMode | null,
  onClose: () => void,
  network: Network,
}
const NetworkJoinModal = ({ mode, onClose, network }: Props) => {
  const { text, setText } = useValidationModalContext();
  const { setError } = useErrorModalContext();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      if (mode === NetworkJoinModalMode.ADHESION) {
        await dispatch(sendNetworkAdhesionRequest({
          networkId: network.id.toString(),
          message: data.message,
          type: 'EXTERN',
        })).unwrap();
      } else {
        await dispatch(sendNetworkAdhesionRequest({
          networkId: network.id.toString(),
          message: data.message,
          type: 'INTERN',
        })).unwrap();
      }
      setText('Votre demande a bien été envoyée');
    } catch (err) {
      setError('Votre demande n\'a pas pu être envoyée');
    }
  }

  const closeModal = () => onClose();

  useEffect(() => {
    // close modal after validation modal goes away
    if (!text) closeModal();
  }, [ text ]);

  const dataFromMode = useMemo(() => (
    mode === NetworkJoinModalMode.INTERN ? {
      title: 'Je fais partie de ce groupement',
      buttonText: 'Je fais partie de ce groupement',
      footer: `Vos cordonnées seront transmises à ${network.name} lors de cette demande afin de prendre contact avec vous`,
    } : {
      title: 'Demande d\'adhésion',
      buttonText: 'Envoyer ma demande d\'adhésion',
      footer: `Vos cordonnées seront transmises à ${network.name} lors de cette demande afin de prendre contact avec vous`,
    }
  ), [ mode ]);

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={mode !== null}
      onRequestClose={closeModal}
    >
      <div className={styles.container}>
        <span onClick={closeModal} className={styles.closeModalContainer}>
          <img src={closeModalIcon} alt={'close modal'} className={styles.closeModal}/>
        </span>
        <div className={styles.content}>
          <div className={styles.header}>
            <LogoSquare image={network.logo_url} className={styles.headerLogo}/>
            <div className={styles.headerInfos}>
              <span className={styles.headerGroup}>Groupe</span>
              <h2 className={styles.headerTitle}>{network.name}</h2>
            </div>
          </div>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <span className={styles.formTitle}>{dataFromMode.title}</span>
            <TextInput errors={errors} register={register} placeholder={mode === NetworkJoinModalMode.ADHESION ? `Explique à ${network.name} pourquoi tu souhaites les rejoindre` : `Ajoute une note pour ${network.name}`} name={'message'} options={{ minLength: { value: 10, message: 'Au moins 10 caractères' } }} multiline={true} />
            <span className={styles.formFooter}>{dataFromMode.footer}</span>
            <ColoredButton text={dataFromMode.buttonText} onClick={console.log} type={'submit'}/>
          </form>
        </div>
      </div>
      <ValidationModal/>
    </Modal>
  )
};

export default NetworkJoinModal;
