import moment from 'moment';
import 'moment/locale/fr';

// moment.updateLocale('fr', {
//   relativeTime: {
//     past: 'depuis %s',
//   },
// });
moment().locale('fr');

export default moment;
