import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import { MediaClassesOptions } from '../../services/api/components/media';
import { createEvent, editEvent, EventsState } from './events';

export interface MediaState {
  mediaPending: boolean,
  classesLoading: boolean,
};

const initialState: MediaState = {
  mediaPending: false,
  classesLoading: false,
};

export const fetchMedium = createAsyncThunk('media/fetchMedium',
  async (id: string) => {
    const data = await api.media.getMedium(id, false);
    return (data.data);
  },
);

export const fetchMyMedia = createAsyncThunk('media/fetchMyMedia',
  async () => {
    const data = await api.media.getMyMedia();
    return (data.data);
  },
);

export const fetchMediaClasses = createAsyncThunk('media/fetchMediaClasses',
  async (options: MediaClassesOptions) => {
    const data = await api.media.getClasses(options);
    return data.data;
  },
);

export const createMedium = createAsyncThunk('media/create',
  async (data: any) => {
    const result = await api.media.createMedium(data);
    return (result.data);
  },
);

export const editMedium = createAsyncThunk('media/edit',
  async (data: any) => {
    const result = await api.media.updateMedium(data.id, data);
    return (result.data);
  },
);

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMediaClasses.pending, (state) => {
      state.classesLoading = true;
    });
    builder.addCase(fetchMediaClasses.fulfilled, (state) => {
      state.classesLoading = false;
    });
    builder.addCase(fetchMediaClasses.rejected, (state) => {
      state.classesLoading = false;
    });
    builder.addCase(createMedium.pending, (state: MediaState) => {
      state.mediaPending = true;
    });
    builder.addCase(createMedium.fulfilled, (state: MediaState) => {
      state.mediaPending = false;
    });
    builder.addCase(createMedium.rejected, (state: MediaState) => {
      state.mediaPending = false;
    });
    builder.addCase(editMedium.pending, (state: MediaState) => {
      state.mediaPending = true;
    });
    builder.addCase(editMedium.fulfilled, (state: MediaState) => {
      state.mediaPending = false;
    });
    builder.addCase(editMedium.rejected, (state: MediaState) => {
      state.mediaPending = false;
    });
  }
});

export const {
} = mediaSlice.actions;

export default mediaSlice.reducer;
