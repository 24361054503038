import styles from './style.module.scss';
import React from 'react';

type Props = {
  title: string;
}
const UnderlineTitle = ({ title }: Props) => {
  return (
    <div>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.underline}/>
    </div>
  );
}

export default UnderlineTitle;
