import React, { useEffect, useMemo, useState } from 'react';

import DetailsPageModal from '../detailsPageModal';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, Recommendation } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchRecommendation } from '../../../store/slices/recommendations';
import LogoSquare from '../../utils/logoSquare';
import styles from './style.module.scss';
import InformationContainer from '../../utils/informationContainer';
import CopyToClipboardButton from '../../buttons/whiteButton/copyToClipboardButton';
import LoadingModal from '../loadingModal';

function RecommendationModal() {
  const { data, type } = useDetailsModalContext();
  const dispatch = useAppDispatch();
  const [ recommendation, setRecommendation ] = useState<Recommendation | null>(null);
  const me = useAppSelector(state => state.account.user);
  const [ loading, setLoading ] = useState(true);

  const fetchData = async () => {
    if (!data || type !== dataType.Recommendation) return;
    try {
      const result = await dispatch(fetchRecommendation(data.id.toString())).unwrap();
      console.log('recommendation', result);
      setRecommendation(result);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching recommendation', err);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [ data?.id, type ]);

  const shownUser = useMemo(() => {
    if (!recommendation) return null;
    return recommendation?.user.id === me!.id ? recommendation?.worker : recommendation.user;
  }, [ recommendation ]);

  if (!data || type !== dataType.Recommendation) return null;
  if (loading) return <LoadingModal/>;
  if (!recommendation || !shownUser) return null;

  return (
    <DetailsPageModal>
      <div className={styles.header}>
        <LogoSquare image={shownUser.picture_url} />
        <div className={styles.headerInfos}>
          <span className={styles.headerName}>{shownUser.firstname} {shownUser.lastname}</span>
          <span className={styles.headerJob}>{shownUser.job}</span>
        </div>
      </div>
      <div className={styles.content}>
        <InformationContainer title={'Vous a recommandé pour'} content={recommendation.title}/>
        <InformationContainer title={'Auprès de'} content={recommendation.client_name}/>
        <InformationContainer title={'Action attendue'} content={recommendation.expected_action}/>
        <InformationContainer title={'Description de la recommandation'} content={recommendation.description}/>
      </div>
      {(!!recommendation.client_phone || !!recommendation.client_email) && <div className={styles.contact}>
        <h3 className={styles.contactHeader}>Contact client</h3>
        <div className={styles.contactButtons}>
          {!!recommendation.client_phone && <CopyToClipboardButton text={recommendation.client_phone} />}
          {!!recommendation.client_phone && !!recommendation.client_email && <div className={styles.spacer}/>}
          {!!recommendation.client_email && <CopyToClipboardButton text={recommendation.client_email} />}
        </div>
      </div>}
    </DetailsPageModal>
  )
}

export default RecommendationModal;
