import Modal from 'react-modal';

import styles from './style.module.scss';
import { SwapRequest } from '../../../types';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import ColoredButton from '../../buttons/coloredButton';
import WhiteButton from '../../buttons/whiteButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { acceptSwapRequest, fetchSwapRequests, refuseSwapRequest } from '../../../store/slices/users';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import InformationContainer from '../../utils/informationContainer';
import UserHeader from '../../utils/userHeader';
import CopyToClipboardButton from '../../buttons/whiteButton/copyToClipboardButton';
import React from 'react';

const getActionText = (mode: SwapRequestModalMode, userName: string) => (
  mode === SwapRequestModalMode.ACCEPT ?
    `En acceptant cette demande vous consentez à transmettre vos coordonnées à ${userName} afin qu’il ou elle prenne contact avec vous` :
    `Après ce refus, ${userName} ne pourra plus vous faire de demande pendant une période de 30 jours`
);

export enum SwapRequestModalMode {
  MESSAGE_SENT,
  MESSAGE_RECEIVED,
  COORDINATES,
  ACCEPT,
  REFUSE,
}

interface Props {
  open: boolean,
  onClose: () => void,
  swapRequest: SwapRequest,
  mode: SwapRequestModalMode,
}

const SwapRequestModal = ({ onClose, swapRequest, open, mode }: Props) => {
  const { setError } = useErrorModalContext();
  const { setText } = useValidationModalContext();
  const loading = useAppSelector((state) => state.users.swapRequestUpdating);
  const dispatch = useAppDispatch();

  const closeModal = () => onClose();

  const _acceptSwapRequest = async () => {
    try {
      await dispatch(acceptSwapRequest(swapRequest.id.toString())).unwrap();
      setText('La demande de Swap a bien été acceptée');
    } catch (err: any) {
      console.error('Error accepting swap request', err);
      setError(err.message);
    } finally {
      dispatch(fetchSwapRequests());
      closeModal();
    }
  }

  const _refuseSwapRequest = async () => {
    try {
      await dispatch(refuseSwapRequest(swapRequest.id.toString())).unwrap();
      setText('La demande de Swap a bien été refusée');
    } catch (err: any) {
      console.error('Error refusing swap request', err);
      setError(err.message);
    } finally {
      dispatch(fetchSwapRequests());
      closeModal();
    }
  }

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={open}
      onRequestClose={closeModal}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          {(mode === SwapRequestModalMode.MESSAGE_RECEIVED ||
              mode === SwapRequestModalMode.MESSAGE_SENT ||
              mode === SwapRequestModalMode.COORDINATES) &&
            <UserHeader user={mode === SwapRequestModalMode.MESSAGE_RECEIVED ? swapRequest.author : swapRequest.requested}/>
          }
          {(mode === SwapRequestModalMode.MESSAGE_RECEIVED ||
            mode === SwapRequestModalMode.MESSAGE_SENT) &&
            <InformationContainer title={'Message'} content={swapRequest.description}/>
          }
          {mode === SwapRequestModalMode.COORDINATES &&
            <div className={styles.coordinatesContainer}>
              <h2 className={styles.coordinatesTitle}>Votre demande de Swap a été acceptée !</h2>
              <p className={styles.coordinatesText}>Vous pouvez dès à présent le ou la contacter afin d'organiser une rencontre</p>
              <h3 className={styles.contactHeader}>Ses coordonnées</h3>
              <div className={styles.contactButtons}>
                {!!(swapRequest.requested?.phone) && <CopyToClipboardButton text={swapRequest.requested.phone} />}
                <div className={styles.spacer}/>
                {!!(swapRequest.requested?.email_contact) && <CopyToClipboardButton text={swapRequest.requested.email_contact} />}
              </div>
            </div>
          }
          {(mode === SwapRequestModalMode.REFUSE || mode === SwapRequestModalMode.ACCEPT) && (
            <div className={styles.actionContainer}>
              <p className={styles.actionText}>{getActionText(mode, swapRequest.author.firstname)}</p>
              <div className={styles.buttonContainer}>
                {mode === SwapRequestModalMode.ACCEPT ?
                  <ColoredButton loading={loading} text={'Accepter la demande'} onClick={_acceptSwapRequest}/> :
                  <WhiteButton loading={loading} text={'Refuser la demande'} onClick={_refuseSwapRequest}/>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
};

export default SwapRequestModal;
