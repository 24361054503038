import styles from './style.module.scss';
import { Value, Service, Argument, Anecdote } from '../../../../types';
import UnderlineTitle from '../../../utils/underlineTitle';
import Attribute from '../../../utils/attribute';

type NetworkAttributesProps = {
  values?: Value[],
  services?: Service[],
  _arguments?: Argument[],
  anecdotes?: Anecdote[],
};

const NetworkAttributes = ({ values, services, anecdotes, _arguments }: NetworkAttributesProps) => {
  if (!values && !services) return null;
  if ((!values || values.length === 0) && (!services || services.length === 0)) return null;
  return (
    <div className={styles.container}>
      {!!values && values.length > 0 && <UnderlineTitle title={'Ses valeurs'} />}
      {!!values && values.length > 0 && <div>
        {values.map((value) => <Attribute key={value.id} name={value.name} description={value.description} />)}
      </div>}
      {!!services && services.length > 0 && <UnderlineTitle title={'Ses services'} />}
      {!!services && services.length > 0 && <div>
        {services.map((service) => <Attribute key={service.id} name={service.name} description={service.description} />)}
      </div>}
      {!!anecdotes && anecdotes.length > 0 && <UnderlineTitle title={'Ses anecdotes'} />}
      {!!anecdotes && anecdotes.length > 0 && <div>
        {anecdotes.map((anecdote) => <Attribute key={anecdote.id} name={anecdote.name} description={anecdote.description} />)}
      </div>}
      {!!_arguments && _arguments.length > 0 && <UnderlineTitle title={'Ses arguments'} />}
      {!!_arguments && _arguments.length > 0 && <div>
        {_arguments.map((_argument) => <Attribute key={_argument.id} name={_argument.name} description={_argument.description} />)}
      </div>}
    </div>
  )
}

export default NetworkAttributes;
