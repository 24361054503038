import { Component } from './index';

export class Tickets extends Component {
  download(id: string) {
    return this.requester.get(`/tickets/${id}/download`, { responseType: 'blob' });
  }

  send(id: string, email: string) {
    return this.requester.post(`/tickets/${id}/send`, { email });
  }

  register(id: string, owners: object) {
    return this.requester.post(`/tickets/${id}/register`, { owners });
  }
}
