import { useEffect, useRef } from 'react';
import Modal from 'react-modal';

import styles from './style.module.scss';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import closeModalIcon from '../../../assets/closeModal.svg';

const ErrorModal = () => {
  const { error, setError } = useErrorModalContext();
  const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

  const closeModal = () => {
    setError(null);
    clearTimeout(timeoutRef.current as NodeJS.Timeout);
  }

  useEffect(() => {
    timeoutRef.current = setTimeout(closeModal, 3000);
  }, [ error ])

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={error !== null}
      shouldCloseOnOverlayClick={false}
      // onRequestClose={closeModal}
    >
      <img src={closeModalIcon} alt={'close modal'} onClick={closeModal} className={styles.closeModalContainer}/>
      <span className={styles.error}>Erreur</span>
      <span className={styles.text}>{error}</span>
    </Modal>
  )
};

export default ErrorModal;
