import styles from './style.module.scss';
import React from 'react';

type Props = {
  title: string;
}
const SectionTitle = ({ title }: Props) => {
  return (
    <h3 className={styles.title}>{title}</h3>
  );
}

export default SectionTitle;
