import bg from '../../assets/grey-background.png';
import image from '../../assets/FOND_OTHRS.jpg';
import styles from './style.module.scss';
import { PropsWithChildren } from 'react';

const Background = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      <img src={image} className={styles.background} alt={'background'} />
      {children}
    </div>
  );
};

export default Background;
