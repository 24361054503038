import ColoredButton from '../../../components/buttons/coloredButton';
import { googleLogin, login } from '../../../store/slices/auth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './style.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import TextInput from '../../../components/form/textInput';
import { isValidEmail } from '../../../utils/misc';
import fullIcon from '../../../assets/fullIcon.svg';
import googleLogo from '../../../assets/googleLogo.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import OutlineButton from '../../../components/buttons/outlineButton';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import { useEffect } from 'react';
import { adminUrl } from '../../../utils/config';

type Inputs = {
  email: string;
  password: string;
}

const Login = () => {
  const [ searchParams ] = useSearchParams();
  const { setText } = useValidationModalContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.auth.loginPending);
  const { setError } = useErrorModalContext();

  useEffect(() => {
    if (searchParams.get('subscribed') === 'true') {
      setText('Votre inscription a bien été prise en compte !\nVous pouvez maintenant vous connecter');
    } else if (searchParams.get('subscribed') === 'false') {
      setError('Votre inscription n\'a pas pu être enregistrée avec succès. Veuillez contacter nos équipes');
    }
  }, [ searchParams ]);

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });

  const onGoogleLoginSuccess = async (tokenResponse: TokenResponse) => {
    const result = await dispatch(googleLogin({
      accessToken: tokenResponse.access_token,
    })).unwrap();
    if (!result.isSub) {
      navigate(`/signup?userId=${result.id}&token=${result.token}`);
    } else if (result.token) {
      navigate('/', { replace: true });
    }
    window.location.reload();
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const { email, password } = data;
    try {
      const result = await dispatch(login({ email, password })).unwrap();
      console.log('result', result);
      if (result.extern) {
        const { auth_code, parentNetworkId } = result;
        window.location.replace(`${adminUrl}?auth_code=${auth_code}&network=${parentNetworkId}`);
        return;
      } else if(!result.isSub) {
        navigate(`/signup?userId=${result.id}&token=${result.token}`, { replace: true });
      } else if (result.token) {
        navigate('/', { replace: true });
      }
      window.location.reload();
    } catch (err: any) {
      console.error('Error login', err);
      setError(err.message);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.icons}>
        <img src={fullIcon} alt={'textIcon'} className={styles.textIcon}/>
      </div>
      <h2 className={styles.title}>Connecte-toi !</h2>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TextInput errors={errors} placeholder={'Email'} register={register} name={'email'} options={{ required: { value: true, message: 'Au moins trois caractères' }, validate: isValidEmail }} />
        <TextInput errors={errors} type={'password'} placeholder={'Mot de passe'} register={register} name={'password'} options={{ required: { value: true, message: 'Au moins six caractères' }}} />
        <Link to={'/forgot-password'} className={styles.forgotPassword}>Mot de passe oublié ?</Link>
        <ColoredButton loading={loading} text={'Connexion'} className={styles.button} type={'submit'}/>
        <OutlineButton leftIcon={googleLogo} text={'Se connecter avec Google'} className={styles.button} onClick={() => handleGoogleLogin()}/>
      </form>
      <Link to={'/signup'} className={styles.createAccount}>Créer un compte</Link>
    </div>
  );
};

export default Login;
