import ColoredButton from '../../../components/buttons/coloredButton';
import { forgotPassword } from '../../../store/slices/auth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './style.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import TextInput from '../../../components/form/textInput';
import { isValidEmail } from '../../../utils/misc';
import fullIcon from '../../../assets/fullIcon.svg';
import { Link } from 'react-router-dom';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';

type Inputs = {
  email: string;
}

const ForgotPassword = () => {
  const { setText, setRedirect } = useValidationModalContext();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.auth.forgotPasswordPending);
  const { setError } = useErrorModalContext();

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const { email } = data;
    try {
      await dispatch(forgotPassword({ email })).unwrap();
      setRedirect('/login');
      setText('Un email contenant un lien de réinitialisation de mot de passe a été envoyé à l\'adresse mail renseignée');
    } catch (err: any) {
      console.error('Error forgotPassword', err);
      setError(err.message || 'Email incorrect');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.icons}>
        <img src={fullIcon} alt={'textIcon'} className={styles.textIcon}/>
      </div>
      <h2 className={styles.title}>Réinitialisation de mot de passe</h2>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TextInput errors={errors} placeholder={'Email'} register={register} name={'email'} options={{ required: true, validate: isValidEmail }} />
        <ColoredButton loading={loading} text={'Envoyer'} className={styles.button} type={'submit'}/>
      </form>
      <Link to={'/login'} className={styles.createAccount}>Connexion</Link>
    </div>
  );
};

export default ForgotPassword;
