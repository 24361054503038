import styles from './style.module.scss';
import { FieldErrors, useFieldArray } from 'react-hook-form';
import TextInput from '../../../../components/form/textInput';
import OutlineButton from '../../../../components/buttons/outlineButton';
import { useEffect } from 'react';

const Tickets = ({ control, register, errors }: { control: any, register: Function, errors?: FieldErrors | undefined; }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tickets',
  });

  return (
    <div className={styles.ticketsContainer}>
      {fields.map((field, index) => (
        <div key={field.id} className={styles.ticketForm}>
          <div className={styles.formRow}>
            <TextInput errors={errors} register={register} options={{ required: true }} name={`tickets.${index}.title`} placeholder={`Billet #${index + 1}`}/>
            <div className={styles.rowSpacer}/>
            <TextInput errors={errors} register={register} options={{ required: true, valueAsNumber: true, validate: (v) => v > 0  }} name={`tickets.${index}.price`} placeholder={'Prix TTC'}/>
          </div>
          <TextInput errors={errors} register={register} options={{ required: true }} name={`tickets.${index}.description`} placeholder={'Description du billet'}/>
          <span className={styles.delete} onClick={() => remove(index)}>Supprimer</span>
        </div>
        ))
      }
      <OutlineButton text={'Ajouter un billet'} onClick={() => append({ price: 0 })}/>
    </div>
  );
}
export default Tickets;
