import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

interface ErrorModalContextValue {
  error: string | null,
  setError: (error: string | null) => void,
}

const ErrorModalContext = createContext<ErrorModalContextValue>({
  error: null,
  setError: () => {},
});

const ErrorModalContextProvider = ({ children }: PropsWithChildren) => {
  const [ error, setError ] = useState<string | null>(null);

  const value = useMemo(() => ({
    error,
    setError,
  }), [ error ]);
  return (
    <ErrorModalContext.Provider value={value}>
      {children}
    </ErrorModalContext.Provider>
  );
};

const useErrorModalContext = () => {
  return useContext(ErrorModalContext);
}

export {
  ErrorModalContextProvider,
  useErrorModalContext,
}
