import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

interface ValidationModalContextValue {
  text: string | null,
  setText: (text: string | null) => void,
  redirect: string | null,
  setRedirect: (redirect: string | null) => void,
}


const ValidationModalContext = createContext<ValidationModalContextValue>({
  text: null,
  setText: () => {},
  redirect: null,
  setRedirect: () => {},
});

const ValidationModalContextProvider = ({ children }: PropsWithChildren) => {
  const [ text, setText ] = useState<string | null>(null);
  const [ redirect, setRedirect ] = useState<string | null>(null);

  const value = useMemo(() => ({
    text,
    setText,
    redirect,
    setRedirect,
  }), [ text, redirect ]);
  return (
    <ValidationModalContext.Provider value={value}>
      {children}
    </ValidationModalContext.Provider>
  );
};

const useValidationModalContext = () => {
  return useContext(ValidationModalContext);
}

export {
  ValidationModalContextProvider,
  useValidationModalContext,
}
