import React, { useEffect, useMemo } from 'react';
import './App.css';
import Router from './router';
import { DetailsModalContextProvider } from './contexts/DetailsModalContext';
import { ValidationModalContextProvider } from './contexts/ValidationModalContext';
import { store } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorModalContextProvider } from './contexts/ErrorModalContext';
import { GoogleApiWrapper } from 'google-maps-react';
import config from './utils/config';
import { PodcastContextProvider } from './contexts/PodcastContext';

function App() {
  let persistor = useMemo(() => persistStore(store), [ store ]);

  return (
    <div className="App">
      <GoogleOAuthProvider clientId="362304675270-dliovn3b6l2a5s8otvafcormsgtnedsq.apps.googleusercontent.com">
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <DetailsModalContextProvider>
              <ValidationModalContextProvider>
                <ErrorModalContextProvider>
                  <PodcastContextProvider>
                    <Router />
                  </PodcastContextProvider>
                </ErrorModalContextProvider>
              </ValidationModalContextProvider>
            </DetailsModalContextProvider>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </div>
  );
}
export default App;
// export default GoogleApiWrapper({
//   apiKey: config.googleApiKey,
//   libraries: ['places']
// })(App);
