import { Component } from './index';

export interface EventClassesOptions {
  cityGroupIds?: string[],
  typeIds?: string[],
  search?: string,
  classBy?: string,
}

export class Events extends Component {
  getAll() {
    return this.requester.get('/events');
  }

  getMine() {
    return this.requester.get(`/events/me`);
  }

  getNetworkEvents(networkId: string) {
    return this.requester.get(`/events/network/${networkId}`);
  }

  get(id: string, publicPage: boolean) {
    if (publicPage) {
      return this.requester.get(`/events/${id}/public`);
    }
    return this.requester.get(`/events/${id}`);
  }

  getClasses(options: EventClassesOptions) {
    const params = new URLSearchParams();
    if (options.cityGroupIds?.length) {
      params.append('cityGroupIds', options.cityGroupIds.join(','));
    }
    if (options.typeIds?.length) {
      params.append('typeIds', options.typeIds.join(','));
    }
    if (options.search?.length) {
      params.append('search', options.search);
    }
    params.append('classBy', options.classBy || 'sphere');
    return this.requester.get(`/events/classes?${params.toString()}`);
  }

  create(data: any) {
    return this.requester.post('/events', data);
  }

  edit(id: string, data: any) {
    return this.requester.patch(`/events/${id}`, data);
  }

  delete(id: string) {
    return this.requester.delete(`/events/${id}`);
  }

  closeInscription(id: string) {
    return this.requester.put(`/events/${id}/close-inscription`);
  }
}
