import styles from './style.module.scss';
import classNames from 'classnames';

interface Props {
  title: string;
  content: string;
};

function InformationContainer({ title, content }: Props) {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <span className={classNames(styles.content)}>{content}</span>
    </div>
  )
};

export default InformationContainer;
