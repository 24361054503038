import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import api from '../../services/api';

export interface AuthState {
  jwt: string | null,
  loginPending: boolean,
  forgotPasswordPending: boolean,
  resetPasswordPending: boolean,
};

const initialState: AuthState = {
  jwt: null,
  loginPending: false,
  forgotPasswordPending: false,
  resetPasswordPending: false,
};

interface LoginPayload {
  email: string;
  password: string;
}
interface LoginResult {
  token: string;
  id: string;
  isSub: boolean;
  extern: boolean;
  auth_code: string;
  parentNetworkId: string;
}
interface GoogleLoginPayload {
  credential?: string;
  accessToken?: string;
}
interface ForgotPasswordPayload {
  email: string;
}
interface ResetPasswordPayload {
  token: string;
  password: string;
}

export const login = createAsyncThunk('auth/login',
  async ({ email, password }: LoginPayload, { rejectWithValue }) => {
    const result = await api.auth.signin(email, password);
    if (result.data) {
      return (result.data as LoginResult);
    } else {
      return rejectWithValue(result.error);
    }
  },
);

export const googleLogin = createAsyncThunk('auth/googleLogin',
  async ({ credential, accessToken }: GoogleLoginPayload, { dispatch }) => {
    const data = await api.auth.googleSignin({ credential, accessToken });
    return (data.data as LoginResult);
  },
);

export const forgotPassword = createAsyncThunk('auth/forgotPassword',
  async ({ email }: ForgotPasswordPayload, { rejectWithValue }) => {
    const result = await api.auth.forgotPassword(email);
    return (result.data);
  },
);

export const resetPassword = createAsyncThunk('auth/resetPassword',
  async ({ token, password }: ResetPasswordPayload, { rejectWithValue }) => {
    const result = await api.auth.resetPasswordToken(token, password);
    return (result.data);
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJwt: (state: AuthState, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    logout: (state: AuthState) => {
      state.jwt = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state: AuthState, action) => {
      state.loginPending = true;
    });
    builder.addCase(login.fulfilled, (state: AuthState, action) => {
      state.loginPending = false;
      state.jwt = action.payload.token;
    });
    builder.addCase(login.rejected, (state: AuthState, action) => {
      state.loginPending = false;
    });
    builder.addCase(googleLogin.pending, (state: AuthState, action) => {
      state.loginPending = true;
    });
    builder.addCase(googleLogin.fulfilled, (state: AuthState, action) => {
      state.loginPending = false;
      state.jwt = action.payload.token;
    });
    builder.addCase(googleLogin.rejected, (state: AuthState, action) => {
      state.loginPending = false;
    });
    builder.addCase(forgotPassword.pending, (state: AuthState, action) => {
      state.forgotPasswordPending = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state: AuthState, action) => {
      state.forgotPasswordPending = false;
      state.jwt = action.payload.token;
    });
    builder.addCase(forgotPassword.rejected, (state: AuthState, action) => {
      state.forgotPasswordPending = false;
    });
    builder.addCase(resetPassword.pending, (state: AuthState, action) => {
      state.resetPasswordPending = true;
    });
    builder.addCase(resetPassword.fulfilled, (state: AuthState, action) => {
      state.resetPasswordPending = false;
      state.jwt = action.payload.token;
    });
    builder.addCase(resetPassword.rejected, (state: AuthState, action) => {
      state.resetPasswordPending = false;
    });
  },
})

export const {
  setJwt,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
