import styles from './style.module.scss';
import React from 'react';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, Network } from '../../../types';
import LogoSquare from '../../utils/logoSquare';
import CityText from '../../utils/cityText';
import NetworkStat from './networkStat';

interface Props {
  data: Network;
  onClick?: (e: React.MouseEvent) => void;
};

function NetworkCard({ data, onClick }: Props) {
  const { setData, setType } = useDetailsModalContext();

  const showNetwork = () => {
    setType(dataType.Network);
    setData(data);
  };

  return (
    <div className={styles.container} onClick={onClick || showNetwork}>
      <img src={data.picture_url} alt={'network'} className={styles.imageBg} />
      <div className={styles.shadow}/>
      <div className={styles.infosContainer}>
        <LogoSquare image={data.logo_url}/>
        <h3 className={styles.name}>{data.name}</h3>
        {(!!data.address?.city) && <CityText city={data.address.city} size={'small'}/>}
        <div className={styles.statsContainer}>
          <NetworkStat name={'Membres'} value={data.members}/>
          {data.usersCount && <NetworkStat name={'Othrs'} value={data.usersCount}/>}
          {data.eventsCount && <NetworkStat value={data.eventsCount} name={'Events'}/>}
          {data.mediaCount && <NetworkStat name={'Médias'} value={data.mediaCount}/>}
        </div>
      </div>
    </div>
  )
};

export default NetworkCard;
