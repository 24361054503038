import styles from './style.module.scss';
import UnderlineTitle from '../../../utils/underlineTitle';
import LogoSquare from '../../../utils/logoSquare';

interface Props {
  logo: string,
  name: string,
  email: string,
  number: string,
  description: string,
}
const Organizer = ({ logo, name, email, number, description }: Props) => {
  return (
    <div className={styles.container}>
      <UnderlineTitle title={'Organisateur'} />
      <div className={styles.infosContainer}>
        <LogoSquare image={logo} className={styles.square}/>
        <div className={styles.textContainer}>
          <span className={styles.name}>{name}</span>
          <span className={styles.infos}>{number}</span>
          <span className={styles.infos}>{email}</span>
        </div>
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  )
}

export default Organizer;
