import styles from './style.module.scss';
import React, { useMemo } from 'react';
import { dataType, Medium, MediumType } from '../../../types';
import arrowRightBlack from '../../../assets/arrowRightBlack.svg';
import editIcon from '../../../assets/editIconBlack.svg';
import shareIcon from '../../../assets/shareIcon.svg';
import DateSquare from '../../utils/dateSquare';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import Line from '../../list/line';
import LogoSquare from '../../utils/logoSquare';
import WhiteIconButton from '../../buttons/whiteIconButton';
import { useNavigate } from 'react-router-dom';
import articleIconBlack from '../../../assets/articleIconBlack.svg';
import podcastIconBlack from '../../../assets/podcastIconBlack.svg';
import videoIconBlack from '../../../assets/videoIconBlack.svg';

type Props = {
  medium: Medium;
}
const MediumLine = ({ medium }: Props) => {
  const { setData, setType } = useDetailsModalContext();
  const navigate = useNavigate();

  const showMedium = () => {
    setData(medium);
    setType(dataType.Medium);
  }

  const editMedium = () => {
    navigate(`/media/edit?id=${medium.id}`);
  }

  const mediumType = useMemo(() => {
    switch (medium.type) {
      case MediumType.article:
        return ({
          icon: articleIconBlack,
          name: 'Article',
        })
      case MediumType.podcast:
        return ({
          icon: podcastIconBlack,
          name: 'Podcast',
        })
      case MediumType.video:
        return ({
          icon: videoIconBlack,
          name: 'Vidéo',
        })
    }
    return ({
      icon: articleIconBlack,
      name: 'Article',
    });
  }, [ medium ]);

  return (
    <Line>
      <div className={styles.container}>
        <div className={styles.squaresContainer} onClick={showMedium}>
          <LogoSquare image={medium.picture_url} />
        </div>
        <div className={styles.infosContainer} onClick={showMedium}>
          <div className={styles.eventInfos}>
            <span className={styles.type}><img src={mediumType.icon} className={styles.icon}/>{mediumType.name}</span>
            <span className={styles.name}>{medium.title}</span>
          </div>
          <img src={arrowRightBlack} className={styles.arrowRight}/>
        </div>
        <div className={styles.statsContainer}>
          <div className={styles.statColumn}>
            <span className={styles.statsTitle}>Audience</span>
            <span className={styles.statsNumber}>{medium.views}</span>
          </div>
          <div className={styles.statColumn}>
            <span className={styles.statsTitle}>Pertinence</span>
            <span className={styles.statsNumber}>{medium.likes}</span>
          </div>
        </div>
        <div className={styles.editIconContainer}>
          <WhiteIconButton icon={editIcon} legend={'Modifier'} iconHeight={'.8rem'} onClick={editMedium}/>
          <div className={styles.buttonSpacer}/>
          <WhiteIconButton icon={shareIcon} legend={'Partager'} iconHeight={'.9rem'} onClick={editMedium}/>
        </div>
      </div>
    </Line>
  );
}

export default MediumLine;
