import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';

export interface MiscState {
};

const initialState: MiscState = {
};

export const getHome = createAsyncThunk('misc/home',
  async () => {
    const data = await api.misc.getHome();
    console.log('data', data.data);
    return (data.data);
  },
);

export const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
  },
})

export const {
} = miscSlice.actions;

export default miscSlice.reducer;
