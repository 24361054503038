import moment from 'moment';
import { SubmitHandler, useForm } from 'react-hook-form';

import styles from './style.module.scss';
import IconedTitle from '../../../components/utils/iconedTitle';
import editEventIcon from '../../../assets/editEvent.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import TextInput from '../../../components/form/textInput';
import SectionTitle from '../../../components/form/sectionTitle';
import DateInput from '../../../components/form/dateInput';
import ColoredButton from '../../../components/buttons/coloredButton';
import SelectInput from '../../../components/form/selectInput';
import { hours, isValidAddress, isValidEmail, isValidPhone } from '../../../utils/misc';
import PlaceInput from '../../../components/form/placeInput';
import { Address, Ticket, Event, User } from '../../../types';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import { createEvent, fetchEvent, editEvent } from '../../../store/slices/events';
import ImageInput from '../../../components/form/imageInput';
import Tickets from './ticketsForm';
import CenteredContainer from '../../../components/containers/centeredContainer';
import { useEffect, useMemo, useState } from 'react';
import CreateAs from '../../../components/utils/createAs';
import { useSearchParams } from 'react-router-dom';
import LoadingModal from '../../../components/modals/loadingModal';

type Inputs = {
  banner_url: string;
  picture_url: string;
  title: string;
  description: string;
  start_date: Date;
  end_date: Date;
  start_hour: string;
  end_hour: string;
  address: any;
  tickets: Ticket[];
  total_places: number;
  contact_name: string;
  contact_number: string;
  contact_email: string;
}

const CreateEvent = () => {
  const [ searchParams ] = useSearchParams();
  const { setError } = useErrorModalContext();
  const { setText, setRedirect } = useValidationModalContext();
  const user: User = useAppSelector(state => state.account.user) as User;
  const eventPending = useAppSelector(state => state.events.eventPending);

  const dispatch = useAppDispatch();
  const [ loading, setLoading ] = useState(true);
  const [ createAs, setCreateAs ] = useState<string | null>(null);
  const [ edit, setEdit ] = useState<number | null>(null);

  const { register, control, handleSubmit, formState: { errors }, reset } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    },
    defaultValues: {
      contact_name: `${user.firstname} ${user.lastname}`,
      contact_email: user.email_contact || user.email,
      contact_number: user.phone,
      tickets: [{}],
    }
  });
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      const address: Address & { placeId: string } = data.address.address_components ? {
        id: 0,
        number: data.address.address_components.find((c: any) => c.types.includes('street_number'))?.long_name,
        street: data.address.address_components.find((c: any) => c.types.includes('route'))?.long_name,
        city: data.address.address_components.find((c: any) => c.types.includes('locality'))?.long_name,
        country: data.address.address_components.find((c: any) => c.types.includes('country'))?.long_name,
        zip_code: data.address.address_components.find((c: any) => c.types.includes('postal_code'))?.long_name,
        placeId: data.address.place_id,
      } : data.address;
      const start_date = moment(data.start_date);
      start_date.hours(parseInt(data.start_hour.split(':')[0]));
      start_date.minutes(parseInt(data.start_hour.split(':')[1]));
      const end_date = moment(data.start_date);
      end_date.hours(parseInt(data.end_hour.split(':')[0]));
      end_date.minutes(parseInt(data.end_hour.split(':')[1]));
      let newEvent;
      if (edit) {
        const { event }: { event: Event } = await dispatch(editEvent({
          ...data,
          id: edit,
          start_date,
          end_date,
          address,
        })).unwrap();
        newEvent = event;
      } else {
        const { event }: { event: Event } = await dispatch(createEvent({
          ...data,
          createAs,
          start_date,
          end_date,
          address,
        })).unwrap();
        newEvent = event;
      }
      setRedirect(`/events?id=${newEvent.id}`);
      setText('Votre évènement a bien été enregistré !');
    } catch (err) {
      setError(`Votre évènement n\'a pas pu être enregistré: ${err}`);
    }
  };

  const createAsOptions = useMemo<string[] | null>(() => {
    const options = [];
    if (!user) return null;
    if (user.administratedCityGroups?.length) {
      options.push(...user.administratedCityGroups.map(cg => `city_group-${cg.id}`));
    }
    if (user.admin_zone_id) {
      options.push(`zone-${user.admin_zone_id}`);
    }
    if (user.administratedNetworks && user.administratedNetworks.length > 0) {
      user.administratedNetworks.forEach(network => {
        options.push(`network-${network.id}`);
      })
    }
    if (options.length === 1) setCreateAs(options[0]);
    return options;
  }, [ user ]);

  const _fetchEvent = async () => {
    try {
      if (!edit) return;
      const result = await dispatch(fetchEvent(edit.toString())).unwrap();
      setLoading(false);
      reset({
        banner_url: result.banner_url,
        picture_url: result.picture_url,
        title: result.title,
        description: result.description,
        start_date: moment(result.start_date).toDate(),
        end_date: moment(result.end_date).toDate(),
        start_hour: moment(result.start_date).format('HH:mm'),
        end_hour: moment(result.end_date).format('HH:mm'),
        address: result.address,
        tickets: result.tickets,
        total_places: result.total_places,
        contact_name: result.contact_name,
        contact_number: result.contact_number,
        contact_email: result.contact_email,
      });
    } catch (err) {
      console.error('Error fetching event', err);
    }
  }

  useEffect(() => {
    setLoading(true);
    _fetchEvent();
  }, [ edit ]);

  useEffect(() => {
    if (searchParams.get('id')) {
      setEdit(parseInt(searchParams.get('id') as string));
    } else {
      setLoading(false);
    }
  }, [ searchParams ]);

  if (loading) return <LoadingModal/>;
  return (
    <CenteredContainer>
      {(createAs || edit) ? <div className={styles.centeredWrapper}>
        <div className={styles.header}>
          <IconedTitle title={edit ? 'Modifier mon Event' : 'Créer un Event'} icon={editEventIcon} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <ImageInput aspectRatio={2} options={{ required: true }} name={'banner_url'} placeholder={'Bannière'} control={control} errors={errors} />
          <ImageInput options={{ required: true }} name={'picture_url'} placeholder={'Photo principale'} control={control} errors={errors} />
          <SectionTitle title={'Nom de l\'Event'}/>
          <TextInput name={'title'} register={register} placeholder={'Nom de l\'Event'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins 3 caractères' }}}/>
          <SectionTitle title={'Description de l\'Event'}/>
          <TextInput name={'description'} register={register} placeholder={'Description de l\'Event'} multiline={true} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins 30 caractères' }}}/>
          <SectionTitle title={'Date et horaires'}/>
          <DateInput control={control} options={{ required: true }} name={'start_date'} placeholder={'Date de l\'Event'}/>
          <div className={styles.formRow}>
            <SelectInput stringOptions={hours} control={control} options={{ required: true }} name={'start_hour'} placeholder={'Heure de début'}/>
            <div className={styles.rowSpacer}/>
            <SelectInput stringOptions={hours} control={control} options={{ required: true }} name={'end_hour'} placeholder={'Heure de fin'}/>
          </div>
          <SectionTitle title={'Lieu et places disponibles'}/>
          <PlaceInput errors={errors} control={control} options={{ required: true, validate: isValidAddress }} name={'address'} placeholder={'Adresse exacte de l\'évènement'}/>
          <TextInput errors={errors} register={register} options={{ required: true, valueAsNumber: true, validate: (v) => (v > 0 || 'Champ invalide') }} name={'total_places'} placeholder={'Places disponibles'}/>
          <SectionTitle title={'Billets'}/>
          <Tickets control={control} register={register} />
          <SectionTitle title={'Organisateur'}/>
          <TextInput name={'contact_name'} register={register} placeholder={'Nom de l\'organisateur'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins 3 caractères' }}}/>
          <div className={styles.formRow}>
            <TextInput name={'contact_number'} register={register} placeholder={'Téléphone de l\'organisateur'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, validate: isValidPhone }}/>
            <div className={styles.rowSpacer}/>
            <TextInput name={'contact_email'} register={register} placeholder={'Email de l\'organisateur'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, validate: isValidEmail }}/>
          </div>
          <ColoredButton loading={eventPending} text={edit ? 'Modifier' : 'Créer mon évènement'} type={'submit'} className={styles.submitButton}/>
        </form>
      </div> : <div className={styles.createAsContainer}>
        {!!createAsOptions && !!user && <CreateAs options={createAsOptions} user={user} setCreateAs={setCreateAs}/>}
      </div>}
    </CenteredContainer>
  );
};

export default CreateEvent;
