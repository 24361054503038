import { Component } from './index';

export class Profile extends Component {
  update(params: any) {
    return this.requester.patch('/profile', params);
  }

  get() {
    return this.requester.get(`/profile`);
  }

  getEssential() {
    return this.requester.get(`/profile/essential`);
  }

  getTickets() {
    return this.requester.get('/profile/tickets');
  }

  delete(id: string) {
    return this.requester.delete(`/profile/${id}`);
  }

  contactUs(object: string, request: string) {
    return this.requester.post(`/profile/contact-us`, {
      object,
      request,
    });
  }

  setToken(token: string) {
    return this.requester.post(`/profile/token`, {
      token,
    });
  }

  setCard(uuid: string) {
    return this.requester.post('/profile/nfc-card', {
      uuid,
    });
  }

  editEmail(newEmail: string) {
    return this.requester.patch('/profile/email', {
      newEmail,
    });
  }

  editPassword(newPassword: string) {
    return this.requester.patch('/profile/password', {
      newPassword,
    });
  }

  getNotifications(clear: boolean) {
    return this.requester.get(`/profile/notifications?clear=${clear}`);
  }

  getEditableGroups(type: string) {
    return this.requester.get(`/profile/editable-groups?type=${type}`);
  }
}
