import Modal from 'react-modal';

import styles from './style.module.scss';
import Spinner from 'react-spinners/BeatLoader';
import React from 'react';

const LoadingModal = () => {
  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={true}
    >
      <Spinner
        size={7}
        color={'#5d5d5d'}
      />
    </Modal>
  )
};

export default LoadingModal;
