import { SubmitHandler, useForm } from 'react-hook-form';

import styles from './style.module.scss';
import IconedTitle from '../../../components/utils/iconedTitle';
import profileIcon from '../../../assets/icon.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import TextInput from '../../../components/form/textInput';
import SectionTitle from '../../../components/form/sectionTitle';
import ColoredButton from '../../../components/buttons/coloredButton';
import SelectInput from '../../../components/form/selectInput';
import { isValidEmail, isValidPhone } from '../../../utils/misc';
import PlaceInput from '../../../components/form/placeInput';
import { Address, Service, Argument, Anecdote, Sphere, Activity } from '../../../types';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import ImageInput from '../../../components/form/imageInput';
import CenteredContainer from '../../../components/containers/centeredContainer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingModal from '../../../components/modals/loadingModal';
import { fetchProfile, updateProfile } from '../../../store/slices/account';
import api from '../../../services/api';
import WhiteButton from '../../../components/buttons/whiteButton';
import ConfirmModal, { ConfirmModalProps } from '../../../components/modals/confirmModal';
import TitleDescription from './titleDescription';

type Inputs = {
  picture_url: string,
  firstname: string,
  lastname: string,
  email_contact: string,
  phone: string,
  surname: string,
  job: string,
  address: any,
  logo_url: string,
  video_url: string,
  sphere: string,
  description: string,
  company: string,
  website: string,
  activities: Activity[],
  services: Service[],
  arguments: Argument[],
  anecdotes: Anecdote[],
}

const Profile = () => {
  const navigate = useNavigate();
  const { setError } = useErrorModalContext();
  const { setText, setRedirect } = useValidationModalContext();
  const accountPending = useAppSelector(state => state.account.updateProfilePending);

  const dispatch = useAppDispatch();
  const [ title, setTitle ] = useState('Créer mon profil');
  const [ loading, setLoading ] = useState(true);
  const [ spheres, setSpheres ] = useState<Sphere[] | null>(null);
  const [ confirmModal, setConfirmModal ] = useState<ConfirmModalProps>({ open: false });

  const { register, control, handleSubmit, formState: { errors }, reset } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    },
  });
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log('data', data);
    try {
      const address: Address & { placeId: string } = data.address.address_components ? {
        id: 0,
        number: data.address.address_components.find((c: any) => c.types.includes('street_number'))?.long_name,
        street: data.address.address_components.find((c: any) => c.types.includes('route'))?.long_name,
        city: data.address.address_components.find((c: any) => c.types.includes('locality'))?.long_name,
        country: data.address.address_components.find((c: any) => c.types.includes('country'))?.long_name,
        zip_code: data.address.address_components.find((c: any) => c.types.includes('postal_code'))?.long_name,
        placeId: data.address.place_id,
      } : data.address;
      const sphere = spheres!.find(s => s.name === data.sphere)!.id;
      await dispatch(updateProfile({
        ...data,
        address,
        sphere,
      })).unwrap();
      setText('Ton profil a bien été enregistré !');
      setRedirect('/');
    } catch (err) {
      setError(`Ton profil n\'a pas pu être enregistré: ${err}`);
    }
  };

  const confirmCancel = () => {
    setConfirmModal({
      open: true,
      message: 'Souhaites-tu vraiment annuler tes modifications ? Tous les changements effectués seront perdus',
      button: 'Annuler mes changements',
      onConfirm: () => navigate(-1),
      onClose: () => setConfirmModal({ open: false }),
    })
  }

  const _fetchProfile = async () => {
    try {
      const { data } = await api.misc.getSpheres();
      setSpheres(data);
      const result = await dispatch(fetchProfile()).unwrap();
      setLoading(false);
      if (result.picture_url) {
        setTitle('Modifier mon profil');
      }
      reset({
        picture_url: result.picture_url,
        firstname: result.firstname,
        lastname: result.lastname,
        email_contact: result.email_contact,
        phone: result.phone,
        surname: result.surname,
        job: result.job,
        address: result.address,
        logo_url: result.logo_url,
        video_url: result.video_url,
        sphere: result.sphere?.name,
        description: result.description,
        company: result.company,
        website: result.website,
        activities: result.activities,
        services: result.services,
        arguments: result.arguments,
        anecdotes: result.anecdotes,
      });
      console.log('profile', result);
    } catch (err) {
      console.error('Error fetching profile', err);
    }
  }

  useEffect(() => {
    setLoading(true);
    _fetchProfile();
  }, []);

  if (loading) return <LoadingModal/>;
  return (
    <CenteredContainer>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.header}>
          <IconedTitle title={title} icon={profileIcon} />
          {title === 'Créer mon profil' && <span className={styles.alert}>Vous devez d'abord renseigner votre profil avant de pouvoir accéder à Othr</span>}
        </div>
        <ImageInput options={{ required: true }} name={'picture_url'} placeholder={'Photo de profil'} control={control} errors={errors} />
        <SectionTitle title={'Présentation'}/>
        <div className={styles.formRow}>
          <TextInput name={'firstname'} register={register} placeholder={'Prénom'} errors={errors} options={{ required: true }}/>
          <div className={styles.rowSpacer}/>
          <TextInput name={'lastname'} register={register} placeholder={'Nom'} errors={errors} options={{ required: true }}/>
        </div>
        <div className={styles.formRow}>
          <TextInput name={'job'} register={register} placeholder={'Métier'} errors={errors} options={{ required: true, minLength: { value: 3, message: 'Au moins 3 caractères' }}}/>
          <div className={styles.rowSpacer}/>
          <SelectInput stringOptions={spheres!.map(s => s.name)} name={'sphere'} control={control} placeholder={'Sphère'} errors={errors} options={{ required: true }}/>
        </div>
        <TextInput name={'surname'} register={register} placeholder={'Surnom'} errors={errors} options={{ required: false }}/>
        <TextInput name={'description'} register={register} placeholder={'Description'} multiline={true} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 30, message: 'Au moins 30 caractères' }}}/>
        <SectionTitle title={'Informations de contact'}/>
        <div className={styles.formRow}>
          <TextInput name={'phone'} register={register} placeholder={'Téléphone'} errors={errors} options={{ required: true, validate: isValidPhone }}/>
          <div className={styles.rowSpacer}/>
          <TextInput name={'email_contact'} register={register} placeholder={'Adresse e-mail'} errors={errors} options={{ required: true, validate: isValidEmail }}/>
        </div>
        <SectionTitle title={'Ton entreprise'}/>
        <div className={styles.companyRow}>
          <div className={styles.logo}>
            <ImageInput options={{}} placeholder={'Logo'} name={'logo_url'} control={control} errors={errors} />
          </div>
          <div className={styles.companyFields}>
            <TextInput name={'company'} register={register} placeholder={'Nom de l\'entreprise'} errors={errors} options={{}}/>
            <TextInput name={'website'} register={register} placeholder={'Site web'} errors={errors} options={{ required: false, validate: (value: string) => !value || value?.startsWith('https') || 'Doit commencer par: https://...' }}/>
          </div>
        </div>
        <PlaceInput options={{ required: true }} errors={errors} name={'address'} control={control} placeholder={'Siège social'} />
        <SectionTitle title={'Dis-nous en plus'}/>
        <TextInput register={register} options={{ validate: (value: string) => !value || value?.includes('youtu') || 'L\'url doit renvoyer vers une vidéo YouTube' }} name={'video_url'} placeholder={'Lien vidéo YouTube'} errors={errors} />
        <SectionTitle title={'Tes services'}/>
        <TitleDescription name={'services'} control={control} register={register} ctaText={'un service'}/>
        <SectionTitle title={'Tes activités'}/>
        <TitleDescription name={'activities'} control={control} register={register} ctaText={'une activité'}/>
        <SectionTitle title={'Tes arguments'}/>
        <TitleDescription name={'arguments'} control={control} register={register} ctaText={'un argument'}/>
        <SectionTitle title={'Tes anecdotes'}/>
        <TitleDescription name={'anecdotes'} control={control} register={register} ctaText={'une anecdote'}/>
        <ColoredButton loading={accountPending} text={'Sauvegarder'} type={'submit'} className={styles.submitButton}/>
        {title !== 'Créer mon profil' && <WhiteButton type={'button'} text={'Annuler les changements'} className={styles.cancelButton} onClick={confirmCancel}/>}
      </form>
      <ConfirmModal {...confirmModal}/>
    </CenteredContainer>
  );
};

export default Profile;
