import styles from './style.module.scss';
import { FieldErrors, useFieldArray } from 'react-hook-form';
import TextInput from '../../../../components/form/textInput';
import OutlineButton from '../../../../components/buttons/outlineButton';

const TitleDescription = ({ control, register, errors, name, ctaText }: { ctaText: string, name: string, control: any, register: Function, errors?: FieldErrors | undefined; }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <div className={styles.ticketsContainer}>
      {fields.map((field, index) => (
        <div key={field.id} className={styles.ticketForm}>
          <TextInput errors={errors} register={register} options={{ required: true }} name={`${name}.${index}.name`} placeholder={`Titre`}/>
          <TextInput errors={errors} register={register} options={{ required: true }} name={`${name}.${index}.description`} placeholder={'Description'}/>
          <span className={styles.delete} onClick={() => remove(index)}>Supprimer</span>
        </div>
        ))
      }
      <OutlineButton text={`Ajouter ${ctaText}`} onClick={() => append({})}/>
    </div>
  );
}
export default TitleDescription;
