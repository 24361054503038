import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { dataType, Network, User, Event, Recommendation, Medium } from '../types';

interface DetailsModalContextValue {
  data: Data | null,
  setData: (data: Data | null) => void,
  type: dataType | null,
  setType: (type: dataType | null) => void,
}

type Data = Network | Event | User | Recommendation | Medium | { id: number } | null;

const DetailsModalContext = createContext<DetailsModalContextValue>({
  data: null,
  setData: () => {},
  type: null,
  setType: () => {},
});

const DetailsModalContextProvider = ({ children }: PropsWithChildren) => {
  const [ data, setData ] = useState<Data>(null);
  const [ type, setType ] = useState<dataType | null>(null);

  const value = useMemo(() => ({
    data,
    setData,
    type,
    setType,
  }), [ data, type ]);
  return (
    <DetailsModalContext.Provider value={value}>
      {children}
    </DetailsModalContext.Provider>
  );
};

const useDetailsModalContext = () => {
  return useContext(DetailsModalContext);
}

export {
  DetailsModalContextProvider,
  useDetailsModalContext,
}
