import styles from './style.module.scss';
import { RegisterOptions, FieldErrors, Controller } from 'react-hook-form';
import ErrorMessage from '../errorMessage';
import { ChangeEvent, useRef, useState } from 'react';
import addIcon from '../../../assets/plusIconOutline.svg';
import classNames from 'classnames';
import { resizeFile } from '../../../utils/misc';
import editIconBlack from '../../../assets/editIconBlack.svg';

type Props = {
  options: RegisterOptions;
  name: string;
  placeholder?: string;
  className?: string;
  errors?: FieldErrors | undefined;
  control: any;
  type?: string;
  aspectRatio?: number;
}

const PlaceInput = ({ control, options, name, className, errors, aspectRatio = 1, placeholder="", ...rest }: Props & React.HTMLAttributes<HTMLInputElement>) => {
  const [ image, setImage ] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>, onChange: Function, onBlur: Function) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      setImage(URL.createObjectURL(input.files[0]));
      const base64 = await resizeFile(input.files[0], aspectRatio);
        onChange(base64);
        onBlur();
      // Use without resizeFile
      // const reader = new FileReader();
      // reader.readAsDataURL(input.files[0]);
      // reader.onload = () => {
      //   console.log('result', reader.result);
      //   onChange(reader.result);
      //   onBlur();
      // }
    }
  };

  const showFileDialog = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        rules={options}
        render={({ field }) => (
          <div className={classNames(styles.imagePicker, { [styles.noBorder]: !!image || field.value })} onClick={showFileDialog} style={{ aspectRatio }}>
            {(!!image || field.value) ? (
              <>
                <img src={image || field.value} alt={'selectedImage'} className={styles.image} style={{ aspectRatio }}/>
                <div className={styles.editIconContainer}>
                  <img src={editIconBlack} className={styles.editIcon}/>
                </div>
              </>
            ) : (
              <div className={styles.content}>
                <img src={addIcon} className={styles.addIcon} alt={'addImage'}/>
                <span className={styles.placeholder}>{placeholder}</span>
              </div>
            )}
            <input
              style={{ visibility: 'hidden', position: 'absolute' }}
              type={'file'}
              className={styles.input}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleImageChange(e, field.onChange, field.onBlur);
              }}
              accept={'image/*'}
              ref={fileInputRef}
            />
          </div>
        )}
      />
      {options.required && <div className={styles.required} />}
      <ErrorMessage errors={errors} name={name}/>
    </div>
  );
};

export default PlaceInput;
