import styles from './style.module.scss';
import React from 'react';

type Props = {
  name: string;
  description: string;
}
const Attribute = ({ name, description }: Props) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.name}>{name}</h3>
      <p className={styles.description}>{description}</p>
    </div>
  );
}

export default Attribute;
