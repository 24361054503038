import styles from './style.module.scss';
import CenteredContainer from '../../components/containers/centeredContainer';
import IconedTitle from '../../components/utils/iconedTitle';
import settingsIcon from '../../assets/settingsWhiteIcon.svg';
import { useErrorModalContext } from '../../contexts/ErrorModalContext';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';
import SectionTitle from '../../components/form/sectionTitle';
import api from '../../services/api';
import { appUrl } from '../../utils/config';
import WhiteButton from '../../components/buttons/whiteButton';
import OutlineButton from '../../components/buttons/outlineButton';
import { useState } from 'react';
import ContactUsModal from '../../components/modals/contactUsModal';

const Settings = () => {
  const { setError } = useErrorModalContext();
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ contactUs, setContactUs ] = useState<boolean>(false);

  const navigateToSubscription = async () => {
    try {
      setLoading(true);
      const result = await api.payments.manageBilling(`${appUrl}`);
      window.open(result.data, '_blank', 'noreferrer');
    } catch (err) {
      setError('Impossible d\'accéder à la page demandée');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <IconedTitle title={'Paramètres'} icon={settingsIcon}/>
        </div>
        <EmailForm />
        <PasswordForm />
        <SectionTitle title={'Mon compte'}/>
        <WhiteButton loading={loading} onClick={navigateToSubscription} text={'Gérer ma souscription'}/>
        <OutlineButton text={'Supprimer mon compte'} onClick={() => setContactUs(true)} className={styles.deleteAccountButton}/>
      </div>
      <ContactUsModal open={contactUs} onClose={() => setContactUs(false)} defaultObject={'Supprimer mon compte'}/>
    </div>
  );
};

export default Settings;
