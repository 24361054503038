import ColoredButton from '../../../components/buttons/coloredButton';
import { forgotPassword, resetPassword } from '../../../store/slices/auth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './style.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import TextInput from '../../../components/form/textInput';
import { isValidEmail } from '../../../utils/misc';
import fullIcon from '../../../assets/fullIcon.svg';
import { Link, useSearchParams } from 'react-router-dom';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import { useEffect, useState } from 'react';

type Inputs = {
  password: string;
}

const ForgotPassword = () => {
  const { setText, setRedirect } = useValidationModalContext();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.auth.resetPasswordPending);
  const { setError } = useErrorModalContext();
  const [ searchParams ] = useSearchParams();
  const [ token, setToken ] = useState<string | null>(null);

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const { password } = data;
    try {
      if (!token) {
        setError('Lien expiré. Veuillez redemander un lien de réinitialisation de mot de passe');
        return;
      }
      await dispatch(resetPassword({ token, password })).unwrap();
      setText('Votre mot de passe a bien été réinitialisé. Vous pouvez maintenant vous connecter');
      setRedirect('/login');
    } catch (err: any) {
      console.error('Error forgotPassword', err);
      setError(err.message || 'Lien expiré. Veuillez redemander un lien de réinitialisation de mot de passe');
    }
  };

  useEffect(() => {
    setToken(searchParams.get('token'));
  }, [ searchParams ]);

  return (
    <div className={styles.container}>
      <div className={styles.icons}>
        <img src={fullIcon} alt={'textIcon'} className={styles.textIcon}/>
      </div>
      <h2 className={styles.title}>Réinitialiser mon mot de passe</h2>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TextInput errors={errors} type={'password'} placeholder={'Mot de passe'} register={register} name={'password'} options={{ required: { value: true, message: 'Au moins six caractères' }}} />
        <ColoredButton loading={loading} text={'Envoyer'} className={styles.button} type={'submit'}/>
      </form>
      <Link to={'/login'} className={styles.createAccount}>Connexion</Link>
    </div>
  );
};

export default ForgotPassword;
