import { SubmitHandler, useForm } from 'react-hook-form';
import { useErrorModalContext } from '../../contexts/ErrorModalContext';
import styles from './style.module.scss';
import SectionTitle from '../../components/form/sectionTitle';
import TextInput from '../../components/form/textInput';
import { isValidEmail } from '../../utils/misc';
import ColoredButton from '../../components/buttons/coloredButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { editEmail } from '../../store/slices/account';
import { useValidationModalContext } from '../../contexts/ValidationModalContext';

interface EmailInput {
  email: string,
}

const EmailForm = () => {
  const editEmailPending = useAppSelector(state => state.account.editEmailPending);
  const { setError } = useErrorModalContext();
  const { setText } = useValidationModalContext();
  const dispatch = useAppDispatch();

  const { register, handleSubmit, formState: { errors }, reset } = useForm<EmailInput>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });
  const onSubmitEmail: SubmitHandler<EmailInput> = async (data: EmailInput) => {
    console.log('data', data);
    try {
      await dispatch(editEmail(data.email)).unwrap();
      reset();
      setText('Votre email a bien été modifié');
    } catch (err) {
      setError(`Votre email n\'a pas pu être modifié`);
    }
  }
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmitEmail)}>
      <SectionTitle title={'Modifier mon adresse mail'}/>
      <TextInput name={'email'} register={register} placeholder={'Nouvelle adresse mail'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins 3 caractères' }, validate: isValidEmail }}/>
      <ColoredButton loading={editEmailPending} text={'Modifier mon adresse mail'} type={'submit'}/>
    </form>
  );
};

export default EmailForm;
