import Modal from 'react-modal';

import styles from './style.module.scss';
import LogoSquare from '../../../utils/logoSquare';
import { User } from '../../../../types';
import InformationContainer from '../../../utils/informationContainer';
import UserHeader from '../../../utils/userHeader';

interface Props {
  open: boolean,
  onClose: () => void,
  message: string,
  user: User,
}

const MessageModal = ({ onClose, user, open, message }: Props) => {

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={open}
      onRequestClose={onClose}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <UserHeader user={user}/>
          <InformationContainer title={'Message'} content={message}/>
        </div>
      </div>
    </Modal>
  )
};

export default MessageModal;
