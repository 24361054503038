import styles from './style.module.scss';
import React from 'react';
import moment from '../../../utils/moment';
import { capitalize } from '../../../utils/misc';

type Props = {
  sphere: string;
}
const SphereHeader = ({ sphere }: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.number}>{capitalize(sphere)}</span>
    </div>
  );
}

export default SphereHeader;
