import styles from './style.module.scss';
import React, { useEffect, useMemo, useState } from 'react';

import DetailsPageModal from '../detailsPageModal';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, Event } from '../../../types';
import DateSquare from '../../utils/dateSquare';
import LogoSquare from '../../utils/logoSquare';
import WhiteButton from '../../buttons/whiteButton';
import Map from '../../map';
import Tickets from './tickets';
import Organizer from './organizer';
import UnderlineTitle from '../../utils/underlineTitle';
import Carousel from '../../home/carousel';
import { useAppDispatch } from '../../../store/hooks';
import { fetchEvent, fetchEventPublic } from '../../../store/slices/events';
import OthrSquare from '../../utils/othrSquare';
import moment from 'moment';
import shareIcon from '../../../assets/shareIcon.svg';
import othrLogo from '../../../assets/logo.png';
import CopyToClipboardButton from '../../buttons/whiteButton/copyToClipboardButton';
import { apiUrl } from '../../../utils/config';
import { usePublicPage } from '../../../utils/hooks';
import LoadingModal from '../loadingModal';
import ColoredButton from '../../buttons/coloredButton';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import ConfirmModal from '../confirmModal';

function EventModal() {
  const { setData, setType } = useDetailsModalContext();
  const publicPage = usePublicPage();
  const { data, type } = useDetailsModalContext();
  const { setError } = useErrorModalContext();
  const { setText, setRedirect } = useValidationModalContext();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ event, setEvent ] = useState<Event | null>(null);
  const [ loading, setLoading ] = useState(true);
  const [ cancelling, setCancelling ] = useState(false);
  const [ confirmCancel, setConfirmCancel ] = useState(false);

  const fetchData = async () => {
    if (!data || type !== dataType.Event) return;
    try {
      const result = publicPage ?
        await dispatch(fetchEventPublic(data.id.toString())).unwrap() :
        await dispatch(fetchEvent(data.id.toString())).unwrap()
      ;
      setEvent(result);
      setLoading(false);
      console.log('event', result);
    } catch (err) {
      console.error('Error fetching event', err);
    }
  }

  const editEvent = () => {
    setData(null);
    setType(null);
    navigate(`/events/edit?id=${event!.id}`);
  }

  const deleteEvent = async () => {
    if (!event) return;
    setCancelling(true);
    try {
      await api.events.delete(event!.id.toString());
    } catch (e) {
      console.error('Error cancelling event', e);
      setError('Erreur lors de l\'annulation de votre évènement. Veuillez nous contacter.');
    } finally {
      setCancelling(false);
      setData(null);
      setType(null);
      setRedirect('/');
      setText('Votre Event a bien été annulé ! Les participants seront automatiquement avertis et remboursés');
    }

  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [ data?.id, type ]);

  const organizerLogo = useMemo(() => {
    if (!event) return null;
    if (event.user) return event.user.logo_url || event.user.picture_url;
    else if (event.network) return event.network?.logo_url || event.network?.logo_url;
    else return null;
  }, [ event ]);

  const takenPlaces = useMemo(() => {
    if (!event) return 0;
    return event.user_tickets?.length || 0;
  }, [ event ]);

  const organizer = useMemo(() => {
    if (!event) return null;
    if (event.user) {
      return {
        name: event.contact_name,
        logo: event.user.logo_url || event.user.picture_url,
        description: event.user.description,
        email: event.contact_email,
        number: event.contact_number,
      }
    } else if (event.network) {
      return {
        name: event.contact_name,
        logo: event.network.logo_url,
        description: event.network.description,
        email: event.contact_email,
        number: event.contact_number,
      }
    } else {
      return {
        name: event.contact_name,
        logo: othrLogo,
        description: '',
        email: event.contact_email,
        number: event.contact_number,
      }
    }
  }, [ event ]);

  if (!data || type !== dataType.Event) return null;
  if (loading) return <LoadingModal/>
  if (!event) return null;

  return (
    <DetailsPageModal>
      <div className={styles.header}>
        <img src={event.picture_url} className={styles.headerImage} alt={'header event'} />
        <div className={styles.shadow} />
        <div className={styles.headerInfosContainer}>
          <div className={styles.squaresContainer}>
            <DateSquare date={event.start_date} className={styles.square}/>
            {organizerLogo ? <LogoSquare image={organizerLogo} className={styles.square}/> : <OthrSquare className={styles.square}/>}
          </div>
          <h2 className={styles.headerTitle}>{event.title}</h2>
          <span className={styles.subtitle}>À {event.address.city} de {moment(event.start_date).format('HH[h]mm')} à {moment(event.end_date).format('HH[h]mm')}</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.firstPart}>
          <div className={styles.stats}>
            <p className={styles.pax}>Jusqu'à {event.total_places} personnes</p>
            {(takenPlaces >= (event.total_places / 2)) && <p className={styles.remaining}>{event.total_places - takenPlaces} places restantes</p>}
          </div>
          <div className={styles.shareButtonContainer}>
            <CopyToClipboardButton text={'Copier le lien'} value={`${apiUrl}/application/public/events?id=${event.id}`} />
          </div>
        </div>
        <UnderlineTitle title={'Détails de l\'évènement'}/>
        <p className={styles.description}>{event.description}</p>
        <Map address={event.address} />
      </div>
      {event.total_places > takenPlaces && <Tickets tickets={event.tickets} event={event}/>}
      <div className={styles.content}>
        {!!organizer && <Organizer {...organizer} />}
        {/*<UnderlineTitle title={'Ses prochains Events'}/>*/}
      </div>
      {/*<div className={styles.carousel}>*/}
      {/*  <Carousel type={dataType.Event} />*/}
      {/*</div>*/}

      {event.canEdit && <div className={styles.editButtonContainer}>
        <ColoredButton text={'Modifier mon Event'} onClick={editEvent}/>
        <div className={styles.spacer}/>
        <WhiteButton loading={cancelling} text={'Annuler mon Event'} onClick={() => setConfirmCancel(true)}/>
      </div>}
      <ConfirmModal open={confirmCancel} onConfirm={deleteEvent} message={'Êtes-vous sûr(e) de vouloir annuler votre Event ? Les participants seront avertis et remboursés sous 3 jours'} button={'Annuler mon Event'} onClose={() => setConfirmCancel(false)}/>
    </DetailsPageModal>
  )
}

export default EventModal;
