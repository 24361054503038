import { SubmitHandler, useForm } from 'react-hook-form';

import styles from './style.module.scss';
import IconedTitle from '../../../components/utils/iconedTitle';
import mediaIcon from '../../../assets/mediaIconColor.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import TextInput from '../../../components/form/textInput';
import ColoredButton from '../../../components/buttons/coloredButton';
import { Encart, Medium, MediumType, User } from '../../../types';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import { createMedium, editMedium, fetchMedium } from '../../../store/slices/media';
import ImageInput from '../../../components/form/imageInput';
import Encarts from './encartsForm';
import CenteredContainer from '../../../components/containers/centeredContainer';
import { useEffect, useMemo, useState } from 'react';
import CreateAs from '../../../components/utils/createAs';
import { useSearchParams } from 'react-router-dom';
import LoadingModal from '../../../components/modals/loadingModal';
import TabBar from '../../../components/utils/tabBar';
import { parseMedium } from '../../../utils/misc';
import FileInput from '../../../components/form/fileInput';
import api from '../../../services/api';

type Inputs = {
  title: string,
  introduction: string,
  encarts: Encart[],
  picture_url: string,
  audio_url: string | Blob,
  video_url: string | Blob,
}

const types = {
  [MediumType.article]: {
    key: 'article',
    label: 'Article',
    cta: 'mon article',
  },
  [MediumType.podcast]: {
    key: 'podcast',
    label: 'Podcast',
    cta: 'mon podcast',
  },
  [MediumType.video]: {
    key: 'video',
    label: 'Vidéo',
    cta: 'ma vidéo',
  },
};

const CreateMedium = () => {
  const [ searchParams ] = useSearchParams();
  const { setError } = useErrorModalContext();
  const { setText, setRedirect } = useValidationModalContext();
  const user: User = useAppSelector(state => state.account.user) as User;
  const mediaPending = useAppSelector(state => state.media.mediaPending);

  const dispatch = useAppDispatch();
  const [ loading, setLoading ] = useState(true);
  const [ createAs, setCreateAs ] = useState<string | null>(null);
  const [ edit, setEdit ] = useState<number | null>(null);
  const [ type, setType ] = useState<MediumType>(MediumType.article);

  const { register, control, handleSubmit, formState: { errors }, reset } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    },
  });
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log('data', data);
    try {
      const items = [
        { type: 'title', content: data.title },
        { type: 'introduction', content: data.introduction },

      ];
      if (data.encarts) {
        items.push(...data.encarts.map((encart: Encart) => ([
          { type: 'subtitle', content: encart.subtitle },
          { type: 'paragraph', content: encart.paragraph },
          { type: 'picture', content: encart.picture_url },
        ])).flat());
      }
      if (type === 'video' && data.video_url && typeof(data.video_url) !== 'string') {
        data.video_url = await uploadMedium(data.video_url);
      } else if (type === 'podcast' && data.audio_url && typeof(data.audio_url) !== 'string') {
        data.audio_url = await uploadMedium(data.audio_url);
      }
      console.log('items', items);
      let newMedium;
      if (edit) {
        const { medium }: { medium: Medium } = await dispatch(editMedium({
          ...data,
          items,
          id: edit,
        })).unwrap();
        newMedium = medium;
      } else {
        const { medium }: { medium: Medium } = await dispatch(createMedium({
          ...data,
          type: types[type].key,
          items,
          createAs,
        })).unwrap();
        newMedium = medium;
      }
      setRedirect(`/media?id=${newMedium.id}`);
      setText('Votre media a bien été publié !');
    } catch (err) {
      setError(`Votre media n\'a pas pu être publié: ${err}`);
    }
  };

  const createAsOptions = useMemo<string[] | null>(() => {
    const options = ['me'];
    if (!user) return null;
    if (user.administratedNetworks && user.administratedNetworks.length > 0) {
      user.administratedNetworks.forEach(network => {
        options.push(`network-${network.id}`);
      })
    }
    if (options.length === 1) setCreateAs(options[0]);
    return options;
  }, [ user ]);

  const _fetchMedium = async () => {
    try {
      if (!edit) return;
      const result = parseMedium(await dispatch(fetchMedium(edit.toString())).unwrap());
      setType(result.type);
      setCreateAs('me');
      setLoading(false);
      console.log('medium', result);
      const encarts = [];
      if (result.items) {
        const subtitles = result.items.filter(i => i.type === 'subtitle');
        const paragraphs = result.items.filter(i => i.type === 'paragraph');
        const pictures = result.items.filter(i => i.type === 'picture');
        const customItemsLength = Math.min(subtitles.length, paragraphs.length, pictures.length);
        for (let i = 0; i < customItemsLength; i++) {
          encarts.push({
            subtitle: subtitles[i].content,
            paragraph: paragraphs[i].content,
            picture_url: pictures[i].content,
          });
        }
      }
      reset({
        picture_url: result.picture_url,
        title: result.title,
        introduction: result.introduction,
        encarts,
      });
    } catch (err) {
      console.error('Error fetching medium', err);
    }
  }

  const uploadMedium = async (blob: Blob) => {
    const mimeType = type === 'video' ? 'video/mp4' : 'audio/mp3';
    const { data: { fileName, uploadId } } = await api.media.startUpload(mimeType);
    const fileSize = blob.size;
    const CHUNK_SIZE = 8000000;
    const chunksCount = Math.floor(fileSize / CHUNK_SIZE) + 1;
    let start;
    let end;
    const chunks = [];
    for (let index = 1; index < chunksCount + 1; index++) {
      start = (index - 1) * CHUNK_SIZE;
      end = index * CHUNK_SIZE;
      chunks.push({
        index,
        blob: index < chunksCount ? blob.slice(start, end) : blob.slice(start),
      });
    }

    const responses = await Promise.all(chunks.map(async (chunk) => {
      const { data: { presignedUrl } } = await api.media.partUpload(fileName, chunk.index, uploadId);
      try {
        const result = await fetch(presignedUrl, {
          method: 'PUT',
          body: chunk.blob,
          headers: { 'Content-Type': mimeType, 'Access-Control-Expose-Headers': 'Etag' },
        });
        console.log(`Part number ${chunk.index} uploaded`);
        return result;
      } catch (error) {
        console.log('Error uploading parts', error);
      }
    }));
    await new Promise((r) => setTimeout(r, 600));
    const parts = responses.map((r, i) => ({
      ETag: r!.headers.get('etag')!,
      PartNumber: i + 1,
    }));
    const { data: { url } } = await api.media.completeUpload(fileName, parts, uploadId);
    return url;
  };

  const changeTab = (tab: string) => {
    for (const key in types) {
      if (types[key as keyof typeof types].label === tab) {
        setType(types[key as keyof typeof types].key as MediumType);
      }
    }
  }

  useEffect(() => {
    setLoading(true);
    _fetchMedium();
  }, [ edit ]);

  useEffect(() => {
    if (searchParams.get('id')) {
      setEdit(parseInt(searchParams.get('id') as string));
    } else {
      setLoading(false);
    }
    if (searchParams.get('type') !== null) {
      setType(searchParams.get('type')!.toLowerCase() as MediumType)
    }
  }, [ searchParams ]);

  if (loading) return <LoadingModal/>;
  return (
    <CenteredContainer>
      {createAs ? <div className={styles.centeredWrapper}>
        <div className={styles.header}>
          <IconedTitle title={`${edit ? 'Modifier' : 'Publier'} ${types[type].cta}`} icon={mediaIcon} />
        </div>
        {!edit && <TabBar tabs={[ types[MediumType.article].label, types[MediumType.podcast].label, types[MediumType.video].label]} currentTab={types[type as MediumType]?.label || 'Article'} onTabChange={changeTab}/>}
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <ImageInput options={{ required: true }} name={'picture_url'} placeholder={'Image principale'} control={control} errors={errors} />
          <TextInput name={'title'} register={register} placeholder={'Titre'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins 3 caractères' }}}/>
          <TextInput name={'introduction'} register={register} placeholder={'Introduction'} multiline={true} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 30, message: 'Au moins 30 caractères' }}}/>
          {type === MediumType.article && <Encarts control={control} register={register} />}
          {type === MediumType.podcast && !edit && <FileInput options={{ required: true }} name={'audio_url'} control={control} mimeType={'audio/mp3'} placeholder={'Fichier audio au format MP3'}/>}
          {type === MediumType.video && !edit && <FileInput options={{ required: true }} name={'video_url'} control={control} mimeType={'video/mp4'} placeholder={'Fichier vidéo au format MP4'}/>}
          <ColoredButton loading={mediaPending} text={edit ? 'Modifier' : 'Publier mon media'} type={'submit'} className={styles.submitButton}/>
        </form>
      </div> : <div className={styles.createAsContainer}>
        {!!createAsOptions && !!user && <CreateAs options={createAsOptions} user={user} setCreateAs={setCreateAs}/>}
      </div>}
    </CenteredContainer>
  );
};

export default CreateMedium;
