import styles from './style.module.scss';
import React from 'react';
import moment from '../../../utils/moment';
import classNames from 'classnames';

interface Props {
  date: Date;
  className?: string;
};

function DateSquare({ date, className }: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <p className={styles.date}>{moment(date).format('DD')}</p>
      <p className={styles.month}>{moment(date).format('MMM').toUpperCase()}</p>
    </div>
  )
};

export default DateSquare;
