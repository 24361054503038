import styles from './style.module.scss';
import React from 'react';
import classNames from 'classnames';
import othrLogo from '../../../assets/logo.png';

interface Props {
  className?: string;
};

function OthrSquare({ className }: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <img src={othrLogo} alt={'bg-orange'} className={styles.background}/>
    </div>
  )
};

export default OthrSquare;
