import styles from './style.module.scss';
import React, { useEffect, useState } from 'react';

import DetailsPageModal from '../detailsPageModal';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, Network } from '../../../types';
import LogoSquare from '../../utils/logoSquare';
import Stat from './stat';
import ColoredButton from '../../buttons/coloredButton';
import WhiteButton from '../../buttons/whiteButton';
import Map from '../../map';
import UnderlineTitle from '../../utils/underlineTitle';
import NetworkAttributes from './networkAttributes';
import { useAppDispatch } from '../../../store/hooks';
import { fetchNetwork } from '../../../store/slices/networks';
import OutlineButton from '../../buttons/outlineButton';
import NetworkJoinModal, { NetworkJoinModalMode } from './networkJoinModal';
import LoadingModal from '../loadingModal';
import Carousel from '../../home/carousel';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

function NetworkModal() {
  const { data, type, setData, setType } = useDetailsModalContext();
  const dispatch = useAppDispatch();
  const [ network, setNetwork ] = useState<Network | null>(null);
  const [ modalMode, setModalMode ] = useState<NetworkJoinModalMode | null>(null);
  const [ loading, setLoading ] = useState(true);
  const navigate = useNavigate();

  const _fetchNetwork = async () => {
    if (!data || type !== dataType.Network) return;
    try {
      const result = await dispatch(fetchNetwork(data.id.toString())).unwrap();
      setNetwork(result);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching network', err);
    }
  }

  useEffect(() => {
    setLoading(true);
    _fetchNetwork();
  }, [ data?.id, type ]);

  if (!data || type !== dataType.Network) return null;
  if (loading) return <LoadingModal/>
  if (!network) return null;

  return (
    <DetailsPageModal>
      <div className={styles.header}>
        <img src={network.picture_url} className={styles.headerImage} alt={'header network'} />
        <div className={styles.shadow} />
        <div className={styles.headerInfosContainer}>
          <div className={styles.squaresContainer}>
            <LogoSquare image={network.logo_url} className={styles.square}/>
          </div>
          <h2 className={styles.headerTitle}>{network.name}</h2>
          <span className={styles.subtitle}>{network.type.name}</span>
        </div>
      </div>
      <div className={styles.stats}>
        <Stat number={network.members} text={'Membres'} align={'flex-start'}/>
        <div className={styles.separator}/>
        <Stat number={network.users?.length || 0} text={'Othrs'} align={'center'}/>
        <div className={styles.separator}/>
        <Stat number={network.events?.length || 0} text={'Events'} align={'flex-end'}/>
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>Qui est {network.name} ?</h2>
        <p className={styles.description}>{network.description}</p>
        <div className={styles.buttonsContainer}>
          <ColoredButton className={styles.buttonContainer} text={'Adhérer au groupement'} onClick={() => setModalMode(NetworkJoinModalMode.ADHESION)} />
          <div className={styles.buttonsSeparator}/>
          <WhiteButton className={styles.buttonContainer} text={'Je fais partie de ce groupement'} onClick={() => setModalMode(NetworkJoinModalMode.INTERN)} />
        </div>
      </div>
      <NetworkAttributes worths={network.worths} services={network.services}/>
      <div className={styles.content}>
        <UnderlineTitle title={'Siège du réseau'}/>
        <div className={styles.mapSeparator}/>
        <Map address={network.address} />
      </div>
      <div className={styles.coordinatesContainer}>
        <div className={styles.coordinates}>
          <LogoSquare image={network.logo_url}/>
          <div className={styles.coordinatesInfos}>
            <span className={styles.coordinatesName}>{network.name}</span>
            <span className={styles.coordinatesInfo}>{network.contact_email}</span>
            <span className={styles.coordinatesInfo}>{network.contact_phone}</span>
            {!!network.website &&
              <span
                className={classNames(styles.coordinatesInfo, styles.website)}
                onClick={() => window.open(network.website, '_blank')}
              >{network.website}</span>
            }
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <ColoredButton className={styles.buttonContainer} text={'Adhérer au groupement'} onClick={() => setModalMode(NetworkJoinModalMode.ADHESION)} />
          <div className={styles.buttonsSeparator}/>
          <WhiteButton className={styles.buttonContainer} text={'Je fais partie de ce groupement'} onClick={() => setModalMode(NetworkJoinModalMode.INTERN)} />
        </div>
      </div>
      {network.events && network.events.length > 0 && <div className={styles.content}>
        <UnderlineTitle title={`Les events de ${network.name}`}/>
      </div>}
      {network.events && network.events.length > 0 && <div className={styles.carousel}>
        <Carousel type={dataType.Event} data={network.events} />
      </div>}
      <div className={styles.footer}>
        <img src={network.picture_url} className={styles.headerImage} alt={'header network'} />
        <div className={styles.shadow} />
        <div className={styles.headerInfosContainer}>
          <div className={styles.squaresContainer}>
            <LogoSquare image={network.logo_url} className={styles.square}/>
          </div>
          <h2 className={styles.headerTitle}>{network.name}</h2>
          <span className={styles.subtitle}>{network.type.name}</span>
          <div className={styles.footerButtons}>
            <ColoredButton className={styles.footerButton} text={'Adhérer au groupement'} onClick={() => setModalMode(NetworkJoinModalMode.ADHESION)}/>
            <OutlineButton className={styles.footerButton} text={'Je fais partie de ce groupement'} onClick={() => setModalMode(NetworkJoinModalMode.INTERN)}/>
            {network.canEdit && <OutlineButton className={styles.footerButton} text={'Modifier mon groupement'} onClick={() => {
              setType(null);
              setData(null);
              navigate(`/groups/edit?id=${network.id}`);
            }}/>}
          </div>
        </div>
      </div>
      <NetworkJoinModal mode={modalMode} onClose={() => setModalMode(null)} network={network} />
    </DetailsPageModal>
  )
}

export default NetworkModal;
