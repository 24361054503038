import styles from './style.module.scss';
import React from 'react';

type Props = {
  type: string;
}
const TypeHeader = ({ type }: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.number}>{type}</span>
    </div>
  );
}

export default TypeHeader;
