import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import { NetworkClassesOptions } from '../../services/api/components/networks';

export interface NetworksState {
  invitePending: boolean,
  classesLoading: boolean,
};

const initialState: NetworksState = {
  invitePending: false,
  classesLoading: false,
};

export const fetchNetwork = createAsyncThunk('networks/fetchNetwork',
  async (id: string) => {
    const data = await api.networks.get(id);
    return (data.data);
  },
);

export const fetchNetworkClasses = createAsyncThunk('networks/fetchNetworkClasses',
  async (options: NetworkClassesOptions) => {
    const data = await api.networks.getClasses(options);
    return (data.data);
  },
);

export const sendNetworkMessage = createAsyncThunk('networks/sendNetworkMessage',
  async ({ networkId, title, message }: { networkId: string, title: string, message: string }) => {
    const data = await api.networks.contact(networkId, title, message);
    return (data.data);
  },
);

export const sendNetworkAdhesionRequest = createAsyncThunk('networks/sendNetworkAdhesionRequest',
  async ({ networkId, message, type }: { networkId: string, message: string, type: string }) => {
    const data = await api.users.networkAdhesion(message, networkId, type);
    return (data.data);
  },
);

interface InviteNetworkPayload {
  name: string,
  email: string,
  message: string,
}
export const inviteNetwork = createAsyncThunk('networks/inviteNetwork',
  async ({ name, email, message }: InviteNetworkPayload) => {
    const data = await api.networks.invite(name, email, message);
    return (data.data);
  },
);

export const createNetwork = createAsyncThunk('network/create',
  async (data: any) => {
    const result = await api.networks.create(data);
    return (result.data);
  },
);

export const editNetwork = createAsyncThunk('network/edit',
  async (data: any) => {
    const result = await api.networks.edit(data.id, data);
    return (result.data);
  },
);

export const createParentNetwork = createAsyncThunk('network/createParent',
  async (data: any) => {
    const result = await api.parent_networks.create(data);
    return (result.data);
  },
);


export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(inviteNetwork.pending, (state: NetworksState) => {
      state.invitePending = true;
    });
    builder.addCase(inviteNetwork.fulfilled, (state: NetworksState) => {
      state.invitePending = false;
    });
    builder.addCase(inviteNetwork.rejected, (state: NetworksState) => {
      state.invitePending = false;
    });
    builder.addCase(fetchNetworkClasses.pending, (state: NetworksState) => {
      state.classesLoading = true;
    })
    builder.addCase(fetchNetworkClasses.fulfilled, (state: NetworksState) => {
      state.classesLoading = false;
    })
    builder.addCase(fetchNetworkClasses.rejected, (state: NetworksState) => {
      state.classesLoading = false;
    })
  },
})

export const {
} = networksSlice.actions;

export default networksSlice.reducer;
