import Modal from 'react-modal';

import styles from './style.module.scss';
import { PropsWithChildren } from 'react';
import closeModalIcon from '../../../assets/closeModal.svg';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { usePublicPage } from '../../../utils/hooks';

const DetailsPageModal = ({ children }: PropsWithChildren) => {
  const publicPage = usePublicPage();
  const { data, setData } = useDetailsModalContext();

  const closeModal = () => setData(null);

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={data !== null}
      onRequestClose={publicPage ? () => {} : closeModal}
    >
      <div className={styles.container} onClick={() => closeModal()}>
        {!publicPage && <span onClick={closeModal} className={styles.closeModalContainer}>
          <img src={closeModalIcon} alt={'close modal'} className={styles.closeModal}/>
        </span>}
        <div className={styles.content} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </Modal>
  )
};

export default DetailsPageModal;
