import { useEffect, useMemo, useRef, useState } from 'react';

import styles from './style.module.scss';

import { dataType, Medium, MediumType, User } from '../../../types';
import ColoredButton from '../../buttons/coloredButton';
import othrTVIconWhite from '../../../assets/othrTVWhiteIcon.svg';
import podcastIconWhite from '../../../assets/podcastWhiteIcon.svg';
import magazineIconWhite from '../../../assets/magazineWhiteIcon.svg';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import bgOthrsBanner from '../../../assets/bg-othrs-banner.jpg';

const MediumBanner = ({ media }: { media: Medium[] }) => {
  const { setType, setData } = useDetailsModalContext();
  const [ mediaOrder, setMediaOrder ] = useState<Medium[]>(media);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [ scrolling, setScrolling ] = useState(true);

  const showMedium = () => {
    setData(medium);
    setType(dataType.Medium);
  };

  const shift = (n: number) => {
    const newMedia = [ ...mediaOrder ];
    const begin = newMedia.splice(0, n);
    setMediaOrder([ ...newMedia, ...begin ]);
  }

  useEffect(() => {
    if (scrolling) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      intervalRef.current = setInterval(() => {
        shift(1);
      }, 2000) as NodeJS.Timeout;
    }
  }, [ shift, scrolling ]);

  const medium = useMemo(() => (
    mediaOrder[0]
  ), [ mediaOrder ]);

  const mediumType = useMemo(() => {
    switch (medium.type) {
      case MediumType.article:
        return ({
          cta: 'Lire',
          icon: magazineIconWhite,
          name: 'Article',
        })
      case MediumType.podcast:
        return ({
          cta: 'Écouter',
          icon: podcastIconWhite,
          name: 'Podcast',
        })
      case MediumType.video:
        return ({
          cta: 'Regarder',
          icon: othrTVIconWhite,
          name: 'TV',
        })
    }
    return ({
      cta: 'Découvrir',
      icon: magazineIconWhite,
      name: 'Article',
    });
  }, [ medium ]);

  return (
    <div className={styles.container}>
      <img src={bgOthrsBanner} className={styles.image} alt={'bg-othr'} />
      <div className={styles.shadow} />
      <div className={styles.infosContainer}>
        <span className={styles.sphere}>{medium.sphere.name}</span>
        <h2 className={styles.name}>{medium.title}</h2>
        <div className={styles.buttonContainer}>
          <ColoredButton text={mediumType.cta} onClick={showMedium} />
        </div>
      </div>
      <div className={styles.cardsContainer}>
        <div className={styles.card1} onClick={showMedium}>
          <img src={mediaOrder[0].picture_url} className={styles.user} alt={'medium to discover'}/>
        </div>
        <div className={styles.card2} onClick={() => {
          setScrolling(false);
          clearInterval(intervalRef.current as NodeJS.Timeout);
          shift(1);
        }}>
          <img src={mediaOrder[1].picture_url} className={styles.user} alt={'medium to discover'}/>
        </div>
        <div className={styles.card3} onClick={() => {
          setScrolling(false);
          clearInterval(intervalRef.current as NodeJS.Timeout);
          shift(2);
        }}>
          <img src={mediaOrder[2].picture_url} className={styles.user} alt={'medium to discover'}/>
        </div>
      </div>
    </div>
  )
};

export default MediumBanner;
