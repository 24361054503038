export enum dataType {
  Network = 'network',
  Event = 'event',
  Othr = 'user',
  Recommendation = 'recommendation',
  Medium = 'medium',
  Article = 'article',
  Podcast = 'podcast',
  Video = 'video',
}

export interface Ticket {
  id: number,
  title: string,
  description: string,
  price: number,
}

export interface Value {
  id: number,
  name: string,
  description: string,
}

export interface Worth {
  id: number,
  name: string,
  description: string,
}

export interface Service {
  id: number,
  name: string,
  description: string,
}

export interface Argument {
  id: number,
  name: string,
  description: string,
}

export interface Anecdote {
  id: number,
  name: string,
  description: string,
}

export interface Activity {
  id: number,
  name: string,
  description: string,
}

export interface Sphere {
  id: number,
  name: string,
}

export interface User {
  id: number,
  firstname: string,
  lastname: string,
  email: string,
  email_contact: string,
  phone: string,
  surname: string,
  metOn?: Date,
  givenRecommendations?: Recommendation[],
  receivedRecommendations?: Recommendation[],
  nbRecos?: number,
  job: string,
  address: Address,
  logo_url: string,
  picture_url: string,
  video_url?: string,
  sphere_id: number,
  sphere?: Sphere,
  city_group_id: number,
  aRelationships?: User[],
  bRelationships?: User[],
  nbSwap?: number,
  media?: Medium[],
  description: string,
  company: string,
  website: string,
  activities?: Activity[],
  services?: Service[],
  arguments?: Argument[],
  anecdotes?: Anecdote[],
  events?: Event[],
  networks?: Network[],
  relationship?: string,
  administratedNetworks?: Network[],
  administratedParentNetworks?: ParentNetwork[],
  ownedParentNetworks?: ParentNetwork[],
  admin_zone_id?: number | null,
  administratedCityGroups?: CityGroup[],
  ticketsCount: number,
  commonRelationships?: User[],
  user_tickets?: UserTicket[],
  swapRequest?: SwapRequest,
}

export enum RecommendationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
}
export interface Recommendation {
  id: number,
  user_author_id: number,
  user: User,
  recommended_user_id: number,
  worker: User,
  status: RecommendationStatus,
  updatedAt: Date,
  title: string,
  client_name: string,
  client_phone: string,
  client_email: string,
  expected_action: string,
  description: string,
  contract_amount?: number,
  response_message?: string,
}

export interface NetworkType {
  id: number,
  name: string,
  createdAt: Date,
  updatedAt: Date,
}

export interface ParentNetwork {
  id: number,
  description: string,
  picture_url: string,
  logo_url: string,
  type: NetworkType,
  type_id: number,
}

export interface Network {
  id: number,
  name: string,
  values?: Value[],
  worths?: Worth[],
  services?: Service[],
  banner_url?: string,
  picture_url: string,
  logo_url: string,
  type: NetworkType,
  users?: User[],
  usersCount?: number,
  members: number,
  events?: Event[],
  eventsCount?: number,
  address: Address,
  description: string,
  nbUsers?: number,
  contact_phone: string,
  contact_email: string,
  website: string,
  media?: Medium[],
  mediaCount?: number,
  parent_network_id: number,
  canEdit?: boolean,
  tax_percent: number,
}

export interface Event {
  id: number;
  title: string,
  address: Address,
  total_places: number,
  banner_url?: string,
  picture_url: string,
  start_date: Date,
  end_date: Date,
  user?: User,
  network?: Network,
  user_tickets?: UserTicket[],
  description: string,
  tickets: Ticket[],
  contact_email: string,
  contact_name: string,
  contact_number: string,
  canEdit?: boolean,
}

export interface Address {
  id: number,
  number: string,
  street: string,
  city: string,
  zip_code: string,
  country: string,
  precisions?: string,
}

export interface UserTicket {
  id: number,
  event: Event,
  name: string,
  job: string,
}

export enum MediumType {
  'article' = 'article',
  'podcast' = 'podcast',
  'video' = 'video',
}
export interface Item {
  type: string,
  index: number,
  content: string,
}
export interface Medium {
  id: number,
  title: string,
  introduction: string,
  type: MediumType,
  likes: number,
  views: number,
  picture_url: string,
  user?: User,
  network?: Network,
  sphere: Sphere,
  createdAt: Date,
  updatedAt: Date,
  items?: Item[],
  video_url?: string,
  audio_url?: string,
  liked?: boolean,
}
export interface Encart {
  subtitle: string,
  paragraph: string,
  picture_url: string,
}
export interface Article extends Medium {
  encarts: Encart[],
}

export enum SwapRequestStatus {
  Pending = 'pending',
  Accepted = 'accepted',
}
export interface SwapRequest {
  id: number,
  author: User,
  user_a_id: number,
  requested: User,
  user_b_id: number,
  status: SwapRequestStatus,
  description: string,
  createdAt: Date,
  updatedAt: Date,
}

export interface CityGroup {
  id: number,
  name: string,
  population: number,
  code: string,
  zipcodes: string,
  zone_id: number,
}

export interface Zone {
  id: number,
  name: string,
  code: string,
}

export interface Notification {
  id: number,
  user_id: number,
  othr_id: number,
  othr?: User,
  event_id: number,
  event?: Event,
  network_id: number,
  network?: Network,
  medium_id: number,
  medium?: Medium,
  recommendation_id: number,
  recommendation?: Recommendation,
  swap_request_id: number,
  swapRequest?: SwapRequest,
  title: string,
  body: string,
  link: string,
  seen: boolean,
  createdAt: Date,
  updatedAt: Date,

}
