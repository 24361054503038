import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../services/api';
import { TicketBooking } from '../../components/modals/eventModal/tickets';
import { EventClassesOptions } from '../../services/api/components/events';

export interface EventsState {
  eventPending: boolean,
};

const initialState: EventsState = {
  eventPending: false,
};

export const fetchEvent = createAsyncThunk('events/fetchEvent',
  async (id: string) => {
    const data = await api.events.get(id, false);
    return (data.data);
  },
);
export const fetchEventPublic = createAsyncThunk('events/fetchEventPublic',
  async (id: string) => {
    const data = await api.events.get(id, true);
    return (data.data);
  },
);

export const fetchEventClasses = createAsyncThunk('events/fetchEventClasses',
  async (options: EventClassesOptions) => {
    const data = await api.events.getClasses(options);
    return (data.data);
  },
);

export const fetchMyEvents = createAsyncThunk('events/fetchMyEvents',
  async () => {
    const data = await api.events.getMine();
    return (data.data);
  },
);

export const fetchMyTickets = createAsyncThunk('events/fetchMyTickets',
  async () => {
    const data = await api.profile.getTickets();
    return (data.data);
  },
);

export const createEvent = createAsyncThunk('events/create',
  async (data: any) => {
    const result = await api.events.create(data);
    return (result.data);
  },
);

export const editEvent = createAsyncThunk('events/edit',
  async (data: any) => {
    const result = await api.events.edit(data.id, data);
    return (result.data);
  },
);

export const registerTicket = createAsyncThunk('events/registerTicket',
  async (data: { ticketId: string, owners: TicketBooking[] }) => {
    const result = await api.tickets.register(data.ticketId, data.owners);
    return (result.data);
  },
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(createEvent.pending, (state: EventsState) => {
      state.eventPending = true;
    });
    builder.addCase(createEvent.fulfilled, (state: EventsState) => {
      state.eventPending = false;
    });
    builder.addCase(createEvent.rejected, (state: EventsState) => {
      state.eventPending = false;
    });
    builder.addCase(editEvent.pending, (state: EventsState) => {
      state.eventPending = true;
    });
    builder.addCase(editEvent.fulfilled, (state: EventsState) => {
      state.eventPending = false;
    });
    builder.addCase(editEvent.rejected, (state: EventsState) => {
      state.eventPending = false;
    });
    builder.addCase(fetchEventClasses.pending, (state: EventsState) => {
      state.eventPending = true;
    });
    builder.addCase(fetchEventClasses.fulfilled, (state: EventsState) => {
      state.eventPending = false;
    });
    builder.addCase(fetchEventClasses.rejected, (state: EventsState) => {
      state.eventPending = false;
    });
  }
})

export const {
} = eventsSlice.actions;

export default eventsSlice.reducer;
