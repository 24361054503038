import styles from './style.module.scss';
import React from 'react';
import classNames from 'classnames';
import Spinner from 'react-spinners/BeatLoader';
import messageIcon from '../../../assets/messageIcon.svg';

interface Props {
  text: string;
  onClick?: (e: React.MouseEvent) => void;
  icon?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  legend?: string;
  disabled?: boolean;
  greenLegend?: boolean;
  redLegend?: boolean;
  loading?: boolean;
  iconHeight?: string;
  rightAction?: () => void;
  textColor?: string;
};

function WhiteButton({ text, onClick, icon, iconHeight, className, type, legend, disabled = false, greenLegend = false, redLegend = false, loading = false, rightAction, textColor }: Props) {
  return (
    <button
      disabled={disabled}
      className={classNames(styles.container, className, { [styles.disabled]: disabled })}
      onClick={(e: React.MouseEvent) => {
        if (disabled) return;
        if (onClick) onClick(e);
      }}
      type={type}
    >
      {loading ? (
        <Spinner
          size={7}
          color={'#5d5d5d'}
        />
      ) : (
        <>
          {icon && <img src={icon} alt={'left'} className={styles.icon} style={{ height: iconHeight }}/>}
          <span className={styles.text} style={{ color: textColor }}>{text}</span>
        </>
      )}
      {!!rightAction && <div className={styles.rightAction} onClick={rightAction}>
        <img src={messageIcon} className={styles.rightActionIcon}/>
      </div>}
      {!!legend && <span className={classNames(styles.legend, { [styles.greenLegend]: greenLegend, [styles.redLegend]: redLegend })}>{legend}</span>}
    </button>
  )
};

export default WhiteButton;
