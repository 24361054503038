import styles from './style.module.scss';

import othrIllustration from '../../../assets/mocked/othrIllustration.jpg';
import WhiteButton from '../../buttons/whiteButton';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, User } from '../../../types';
import bgOthrsBanner from '../../../assets/bg-othrs-banner.jpg';
import ColoredButton from '../../buttons/coloredButton';
import { useEffect, useMemo, useRef, useState } from 'react';

const OthrBanner = ({ users }: { users: User[] }) => {
  const { setType, setData } = useDetailsModalContext();
  const [ usersOrder, setUsersOrder ] = useState<User[]>(users);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [ scrolling, setScrolling ] = useState(true);

  const showUser = () => {
    setData(usersOrder[0]);
    setType(dataType.Othr);
  }

  const shift = (n: number) => {
    const newUsers = [ ...usersOrder ];
    const begin = newUsers.splice(0, n);
    setUsersOrder([ ...newUsers, ...begin ]);
  }

  useEffect(() => {
    if (scrolling) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      intervalRef.current = setInterval(() => {
        shift(1);
      }, 4000) as NodeJS.Timeout;
    }
  }, [ shift, scrolling ]);

  const user = useMemo(() => (
    usersOrder[0]
  ), [ usersOrder ]);

  return (
    <div className={styles.container}>
      <img src={bgOthrsBanner} className={styles.image} alt={'bg-othr'} />
      <div className={styles.shadow} />
      <div className={styles.infosContainer}>
        <span className={styles.surname}>{user.surname}</span>
        <h2 className={styles.name}>{user.firstname.toUpperCase()}</h2>
        <h2 className={styles.name}>{user.lastname.toUpperCase()}</h2>
        <span className={styles.job}>{user.job}</span>
        <div className={styles.buttonContainer}>
          <ColoredButton text={'Rencontrer'} onClick={showUser} />
        </div>
      </div>
      <div className={styles.cardsContainer}>
        <div className={styles.card1} onClick={showUser}>
          <img src={usersOrder[0].picture_url} className={styles.user} alt={'user to discover'}/>
        </div>
        <div className={styles.card2} onClick={() => {
          setScrolling(false);
          clearInterval(intervalRef.current as NodeJS.Timeout);
          shift(1);
        }}>
          <img src={usersOrder[1].picture_url} className={styles.user} alt={'user to discover'}/>
        </div>
        <div className={styles.card3} onClick={() => {
          setScrolling(false);
          clearInterval(intervalRef.current as NodeJS.Timeout);
          shift(2);
        }}>
          <img src={usersOrder[2].picture_url} className={styles.user} alt={'user to discover'}/>
        </div>
      </div>
    </div>
  )
};

export default OthrBanner;
