import styles from './style.module.scss';
import React, { useMemo } from 'react';
import magazineIconWhite from '../../../assets/magazineWhiteIcon.svg';
import podcastIconWhite from '../../../assets/podcastWhiteIcon.svg';
import othrTVIconWhite from '../../../assets/othrTVWhiteIcon.svg';
import { useDetailsModalContext } from '../../../contexts/DetailsModalContext';
import { dataType, Medium, MediumType } from '../../../types';
import LogoSquare from '../../utils/logoSquare';
import OthrSquare from '../../utils/othrSquare';

interface Props {
  data: Medium;
  onClick?: (e: React.MouseEvent) => void;
};

function OthrCard({ data, onClick }: Props) {
  const { setData, setType } = useDetailsModalContext();

  const showMedium = () => {
    setType(dataType.Medium);
    setData(data);
  };

  const mediumType = useMemo(() => {
    switch (data.type) {
      case MediumType.article:
        return ({
          name: 'Magazine',
          icon: magazineIconWhite,
        })
      case MediumType.podcast:
        return ({
          name: 'Podcast',
          icon: podcastIconWhite,
        })
      case MediumType.video:
        return ({
          name: 'TV',
          icon: othrTVIconWhite,
        })
    }
    return ({
      name: 'Magazine',
      icon: magazineIconWhite,
    });
  }, [ data ]);

  return (
    <div className={styles.container} onClick={onClick || showMedium}>
      <img src={data.picture_url} alt={'othr'} className={styles.imageBg}/>
      <div className={styles.shadow}/>
      <div className={styles.header}>
        <img src={mediumType.icon} className={styles.headerIcon} alt={'header'}/>
        <span className={styles.headerText}>{mediumType.name}</span>
      </div>
      <div className={styles.infosContainer}>
        <span className={styles.sphere}>{data.sphere.name}</span>
        <h2 className={styles.title}>{data.title}</h2>
        {/*<span className={styles.introduction}>{data.introduction}</span>*/}
        <div className={styles.author}>
          {(data.user || data.network) ?
            <LogoSquare className={styles.square} image={data.user?.picture_url || data.network!.logo_url} /> :
            <OthrSquare className={styles.square} />
          }
          {(!!data.user || !!data.network) && <div className={styles.authorText}>
            <span className={styles.authorName}>{data.user ? data.user.firstname + ' ' + data.user.lastname : data.network!.name}</span>
            <span className={styles.authorJob}>{data.user ? data.user.job : data.network!.type.name}</span>
          </div>}
        </div>
        {/*<div className={styles.statsContainer}>*/}
        {/*  <Stats value={data.likes} text={'Likes'} proportion={0.4}/>*/}
        {/*  <Stats value={data.views} text={'Audiences'} proportion={0.8}/>*/}
        {/*</div>*/}
      </div>
    </div>
  )
};

export default OthrCard;
