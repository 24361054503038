import Modal from 'react-modal';

import styles from './style.module.scss';
import validationIcon from '../../../assets/validationIcon.svg';

import { useNavigate } from 'react-router-dom';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import { useEffect, useRef } from 'react';

const ValidationModal = () => {
  const { text, setText, redirect, setRedirect } = useValidationModalContext();
  const navigate = useNavigate();
  const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

  const closeModal = () => {
    if (redirect) navigate(redirect, { replace: true });
    setText(null);
    setRedirect(null);
    clearTimeout(timeoutRef.current as NodeJS.Timeout);
  }

  useEffect(() => {
    timeoutRef.current = setTimeout(closeModal, 3000);
  }, [ text ])

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={text !== null}
      onRequestClose={closeModal}
    >
      <img src={validationIcon} alt={'validation'} className={styles.validationIcon}/>
      <span className={styles.text}>{text}</span>
    </Modal>
  )
};

export default ValidationModal;
