import styles from './style.module.scss';
import { Worth, Service } from '../../../../types';
import UnderlineTitle from '../../../utils/underlineTitle';
import Attribute from '../../../utils/attribute';

type NetworkAttributesProps = {
  worths?: Worth[],
  services?: Service[],
};

const NetworkAttributes = ({ worths, services }: NetworkAttributesProps) => {
  if (!worths && !services) return null;
  if ((!worths || worths.length === 0) && (!services || services.length === 0)) return null;
  return (
    <div className={styles.container}>
      {!!worths && worths.length > 0 && <UnderlineTitle title={'Les valeurs'} />}
      {!!worths && worths.length > 0 && <div>
        {worths.map((value) => <Attribute key={value.id} name={value.name} description={value.description} />)}
      </div>}
      {!!services && services.length > 0 && <UnderlineTitle title={'Les services'} />}
      {!!services && services.length > 0 && <div>
        {services.map((service) => <Attribute key={service.id} name={service.name} description={service.description} />)}
      </div>}
    </div>
  )
}

export default NetworkAttributes;
