import styles from './style.module.scss';
import Carousel  from '../../components/home/carousel';
import othrsIconColor from '../../assets/othrsIconColor.svg';
import { CityGroup, dataType, Sphere, User } from '../../types';
import OthrBanner from '../../components/othrs/othrBanner';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchUserClasses } from '../../store/slices/users';
import SphereHeader from '../../components/othrs/sphereHeader';
import { useSearchParams } from 'react-router-dom';
import { useDetailsModalContext } from '../../contexts/DetailsModalContext';
import { usePublicPage } from '../../utils/hooks';
import searchIcon from '../../assets/searchIcon.svg';
import Filter from '../../components/options/filter';
import Categorize from '../../components/options/categorize';
import Search from '../../components/options/search';
import Loader from '../../components/options/loader';
import Api from '../../services/api';
import Sort from '../../components/options/sort';

const sortOptions = [
  { label: 'Les derniers inscrits', value: 'createdAt' },
  { label: 'Nombre de recos', value: 'recommendations' },
  { label: 'Nombre de contacts', value: 'contacts' },
  { label: 'Nombre de médias', value: 'media' },
];

const Othrs = () => {
  const publicPage = usePublicPage();
  let [ searchParams ] = useSearchParams();
  const { setData, setType } = useDetailsModalContext();

  const [ classes, setClasses ] = useState<{ name: string, users: User[] }[] | null>(null);
  const [ banner, setBanner ] = useState<User[] | null>(null);
  const dispatch = useAppDispatch();
  const classesLoading = useAppSelector(state => state.users.classesLoading);
  const [ cityGroupIds, setCityGroupIds ] = useState<string[]>([]);
  const [ sphereIds, setSphereIds ] = useState<string[]>([]);

  const [ cityGroups, setCityGroups ] = useState<CityGroup[] | null>(null);
  const [ spheres, setSpheres ] = useState<Sphere[] | null>(null);
  const [ search, setSearch ] = useState<string>('');
  const [ classBy, setClassBy ] = useState<string>('sphere');
  const [ sort, setSort ] = useState<string>('createdAt');

  const fetchData = async () => {
    try {
      if (publicPage) return;
      const data = await dispatch(fetchUserClasses({ cityGroupIds, sphereIds, classBy, search, sort })).unwrap();
      setClasses(data.classes);
      setBanner(data.banner);
      const { data: cityGroups } = await Api.misc.getCityGroups();
      setCityGroups(cityGroups);
      const { data: spheres } = await Api.misc.getSpheres();
      setSpheres(spheres);
    } catch (err) {
      console.error('Error fetching users home', err);
    }
  };

  const showOthr = (othrId: number) => {
    console.log('showing Othr, public', othrId, publicPage);
    setType(dataType.Othr);
    setData({ id: othrId });
  };

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      showOthr(parseInt(id));
    }
  }, [ searchParams ]);

  useEffect(() => {
    fetchData();
  }, [ cityGroupIds, sphereIds, search, classBy, sort ]);

  if (!classes && !publicPage) return null;
  return publicPage ? <div className={styles.container}/> : (
    <div className={styles.container}>
      {!!banner && banner.length > 2 && <div className={styles.centeredWrapper}>
        <OthrBanner users={banner} />
      </div>}
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <img src={othrsIconColor} alt={'othr'} className={styles.eventIcon}/>
          <h2 className={styles.title}>Je peux les rencontrer</h2>
        </div>
        <div className={styles.filtersContainer}>
          <Categorize options={[{ label: 'Sphère', value: 'sphere' }, { label: 'Tops', value: 'tops' }]} onChange={setClassBy} />
          {!!cityGroups && <Filter name={'Localisation'} options={cityGroups.map(cg => ({ label: cg.name, value: cg.id.toString() }))} onChange={setCityGroupIds}/>}
          {!!spheres && <Filter name={'Sphère'} options={spheres.map(sphere => ({ label: sphere.name, value: sphere.id.toString() }))} onChange={setSphereIds}/>}
          <Sort options={sortOptions} onChange={setSort} />
        </div>
        <div className={styles.searchContainer}>
          {classesLoading && <Loader />}
          <Search onChange={setSearch}/>
        </div>
      </div>
      {!!classes && classes.map((c) => (
        <div key={c.name} className={styles.monthContainer}>
          <SphereHeader sphere={c.name}/>
          <Carousel type={dataType.Othr} data={c.users}/>
        </div>
      ))}
      {!classes || !classes.length && <div className={styles.placeholder}/>}
    </div>
  );
};

export default Othrs;
