import styles from './style.module.scss';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { googleApiKey } from '../../utils/config';
import markerIcon from '../../assets/markerIcon.svg';
import locationIconBlack from '../../assets/locationIconBlack.svg';
import { Address } from '../../types';
import { useEffect, useState } from 'react';
import { geocodeAddress } from '../../utils/misc';

type Props = {
  address: Address;
};

function Map({ address }: Props) {
  const [ center, setCenter ] = useState(null);

  const getCenter = async () => {
    if (!address) return;
    const result = await geocodeAddress(address);
    setCenter(result);
  }

  useEffect(() => {
    getCenter();
  }, [ address ]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey,
  })

  return (
    <div className={styles.container}>
      {isLoaded && !!center && <GoogleMap
        center={center}
        mapContainerClassName={styles.mapContainer}
        zoom={14}
        options={{
          controlSize: 0,
          disableDefaultUI: true,
          clickableIcons: false,
          styles: [
            { featureType: "poi", stylers: [{ visibility: "off", }], },
            { elementType: 'labels.icon', featureType: "road", stylers: [{ visibility: "off", }], },
          ]
        }}
      >
        <Marker position={center} icon={markerIcon} />
      </GoogleMap>}
      <div className={styles.infosContainer}>
        <img src={locationIconBlack} alt={'location'} className={styles.locationIcon}/>
        <div className={styles.text}>
          <span className={styles.address}>{address.number} {address.street}</span>
          <span className={styles.address}>{address.zip_code} {address.city}</span>
        </div>
      </div>
    </div>
  )
};

export default Map;
