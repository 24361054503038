import Modal from 'react-modal';

import styles from './style.module.scss';
import closeModalIcon from '../../../../assets/closeModal.svg';
import LogoSquare from '../../../utils/logoSquare';
import { User } from '../../../../types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useValidationModalContext } from '../../../../contexts/ValidationModalContext';
import TextInput from '../../../form/textInput';
import classNames from 'classnames';
import ColoredButton from '../../../buttons/coloredButton';
import { useEffect } from 'react';
import ValidationModal from '../../validationModal';
import swapIcon from '../../../../assets/swapIconWhite.svg';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useErrorModalContext } from '../../../../contexts/ErrorModalContext';
import { sendSwapRequest } from '../../../../store/slices/users';

type Inputs = {
  message: string;
}

interface Props {
  open: boolean,
  onClose: () => void,
  user: User,
}
const SwapRequestModal = ({ onClose, user, open }: Props) => {
  const dispatch = useAppDispatch();
  const { text, setText } = useValidationModalContext();
  const { setError } = useErrorModalContext();
  const loading = useAppSelector((state) => state.users.swapRequestUpdating);

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      await dispatch(sendSwapRequest({ description: data.message, requestedUserId: user.id.toString() })).unwrap();
      setText('Votre demande de Swap a bien été envoyée');
      onClose();
    } catch (err) {
      console.error('Error sending swap request', err);
      setError('Votre demande de swap n\'a pas pu être envoyée');
    }
  }

  const closeModal = () => onClose();

  return (
    <Modal
      overlayClassName={styles.overlay}
      className={styles.modal}
      isOpen={open}
      onRequestClose={closeModal}
    >
      <div className={styles.container}>
        <span onClick={closeModal} className={styles.closeModalContainer}>
          <img src={closeModalIcon} alt={'close modal'} className={styles.closeModal}/>
        </span>
        <div className={styles.content}>
          <div className={styles.header}>
            <LogoSquare image={user.picture_url} className={styles.headerLogo}/>
            <div className={styles.headerInfos}>
              <h2 className={styles.headerTitle}>{user.firstname} {user.lastname}</h2>
              <span className={styles.headerGroup}>{user.job}</span>
            </div>
          </div>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <span className={styles.formTitle}>Demande de Swap (rencontre)</span>
            <TextInput errors={errors} register={register} placeholder={`Explique à ${user.firstname} pourquoi tu souhaites le ou la rencontrer`} name={'message'} options={{ required: true, minLength: { value: 30, message: 'Au moins 30 caractères' } }} multiline={true} />
            <ColoredButton loading={loading} icon={swapIcon} className={styles.button} text={'Envoyer ma demande de Swap'} onClick={console.log} type={'submit'}/>
          </form>
        </div>
      </div>
    </Modal>
  )
};

export default SwapRequestModal;
