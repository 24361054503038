import { SubmitHandler, useForm } from 'react-hook-form';

import styles from './style.module.scss';
import IconedTitle from '../../../components/utils/iconedTitle';
import networkIcon from '../../../assets/networkIconColor.svg';
import { useAppDispatch } from '../../../store/hooks';
import TextInput from '../../../components/form/textInput';
import SectionTitle from '../../../components/form/sectionTitle';
import ColoredButton from '../../../components/buttons/coloredButton';
import { NetworkType, ParentNetwork } from '../../../types';
import { useErrorModalContext } from '../../../contexts/ErrorModalContext';
import { useValidationModalContext } from '../../../contexts/ValidationModalContext';
import ImageInput from '../../../components/form/imageInput';
import CenteredContainer from '../../../components/containers/centeredContainer';
import { createParentNetwork } from '../../../store/slices/networks';
import SelectInput from '../../../components/form/selectInput';
import { useEffect, useState } from 'react';
import api from '../../../services/api';

type Inputs = {
  description: string,
  picture_url: string,
  logo_url: string,
  type: string,
}

const CreateParentGroup = () => {
  const { setError } = useErrorModalContext();
  const { setText, setRedirect } = useValidationModalContext();
  const [ types, setTypes ] = useState<NetworkType[] | null>(null);
  const dispatch = useAppDispatch();
  const { register, control, handleSubmit, formState: { errors }, getValues } = useForm<Inputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resetOptions: {
      keepErrors: true,
      keepDirty: true,
    }
  });
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log('data', data);
    try {
      const { parentNetwork }: { parentNetwork: ParentNetwork } = await dispatch(createParentNetwork({
        ...data,
        type: types?.find(t => t.name === data.type)?.id,
      })).unwrap();
      setRedirect(`/groups/create`);
      setText(`Votre groupement mère a bien été enregistré ! ID : ${parentNetwork.id}`);
    } catch (err) {
      setError(`Votre groupement mère n\'a pas pu être enregistré: ${err}`);
    }
  };

  const fetchTypes = async () => {
    const { data } = await api.misc.getNetworkTypes();
    setTypes(data);
  }

  useEffect(() => {
    fetchTypes();
  }, []);

  return (
    <CenteredContainer>
      <div className={styles.centeredWrapper}>
        <div className={styles.header}>
          <IconedTitle title={'Créer un groupement mère'} icon={networkIcon} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <ImageInput options={{ required: true }} name={'picture_url'} placeholder={'Photo principale'} control={control} errors={errors} />
          <SectionTitle title={'Nom du groupement mère'}/>
          <div className={styles.nameRow}>
            <div className={styles.logo}>
              <ImageInput options={{ required: true }} name={'logo_url'} placeholder={'Logo'} control={control} errors={errors} />
            </div>
            <div className={styles.nameFields}>
              <TextInput name={'description'} register={register} placeholder={'Nom du groupement mère'} errors={errors} options={{ required: { value: true, message: 'Champ requis' }, minLength: { value: 3, message: 'Au moins 3 caractères' }}}/>
              {!!types && <SelectInput stringOptions={types.map(t => t.name)} name={'type'} control={control} placeholder={'Type de groupement'} options={{ required: true }} errors={errors}/>}
            </div>
          </div>
          <ColoredButton text={'Créer le groupement mère'} type={'submit'} className={styles.submitButton}/>
        </form>
      </div>
    </CenteredContainer>
  );
};

export default CreateParentGroup;
