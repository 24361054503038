import styles from './style.module.scss';
import { PropsWithChildren } from 'react';

const UnscrollableCenteredContainer = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
};

export default UnscrollableCenteredContainer;
